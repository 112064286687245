import React, { Component, Fragment } from "react";
import { decodeToken } from "react-jwt";
import BaseListComponent, { BaseListComponentState, IFilterData } from "../../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import authService, { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import rolesService from "../../../services/auth/rolesService";
import appraisalInstitutionService, { filterInstitution } from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanRequestService from "../../../services/hybridAppraisal/loanRequestService";
import AppraisalWorkflow from "./appraisalWorkflow";
import NewApplication from "./appraisalDetailPages/newApplication";
import DataValidationView from "./appraisalDetailPages/dataValidationView";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import OfferLetterScreen from "./appraisalDetailPages/offerLetterScreen";
import DocumentCollection from "./appraisalDetailPages/postApprovalDcoumentScreen";
import PreDisbursement from "./appraisalDetailPages/preDisbursementScreen";
import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import { toast } from "react-toastify";
import creditAnalysisCheck from "./appraisalDetailPages/creditAnalysisCheck";
import institutionsService from "../../../services/auth/institutionsService";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import FormInputComponent from "../../../components/formInputComponent";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import bankOneWebAPIService from "../../../services/bankOne/bankOneWebAPIService";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import usersService from "../../../services/auth/usersService";
import { generateGUID } from "../../../services/utility/textFormatService";
import { EconomicSector } from "../../../enums/economicSector";
import nameEnquiryService from "../../../services/handshake/nameEnquiryService";
import commercialBankService from "../../../services/sweep/commercialBankService";


interface NewLoanApplicationFields {
    bvn: string;
    lastName: string;
    firstName: string;
    middleName: string;
    phoneNumber: string;
    gender: string;
    dateOfBirth: string;
    address: string;
    nextOfKinPhoneNumber: string;
    nextOfKinName: string;
    loanApplicationId: string;
    dateInitiated: string;
    branch: string;
    loanAmount: string;
    loanProduct: string;
    loanPurpose: string;
    loanSector: string;
    relationshipManager: string;
    branchManager: string;
    accountNumber: string;
    accountOfficer: string;
    loanProduct2: string;
    preferredRepaymentBankCBNCode: string
    preferredRepaymentAccount: string
    email: string
    action: string
}

interface NewLoanApplicationProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
}

interface NewLoanApplicationState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    item: any;
    toUpdate: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    userRoles: any;
    systemProcess: any;
    showTable: boolean;
    showAppraisalProcess: boolean;
    institution: any;
    ownUpdate: boolean;
    createAccount: boolean;
    bvn: string;
    canLoad: boolean;
    firstName: string;
    middleName: string;
    lastName: string;
    relationshipManagers: any;
    fetchingRequiredItems: boolean;
    canLoadNewAcc: boolean;
    accounts: any;
    accountOfficers: any;
    accountProducts: any;
    loanProducts: any;
    newAccount: any;
    bankCode: string;
    accountNumber: string;
    accountName: string;
    commercialBanks: any;
    AccountCreationPayload: any;
    phoneNumber: string;
    loadAccounts: boolean;
    currentUser: any;
    customerId: string;
}

class NewLoanApplicationList extends BaseListComponent<NewLoanApplicationState> { }

class NewLoanApplicationForm extends BaseFormComponent<NewLoanApplicationFields> { }

class NewLoanApplication extends Component<
    NewLoanApplicationProps,
    IBaseFormState & NewLoanApplicationState
> {
    _isMounted = false;
    constructor(props: NewLoanApplicationProps) {
        super(props);
        this.state = {
            data: createFilterData([]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [],
            item: {},
            showDetailsModal: false,
            showTable: true,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            toUpdate: true,
            userRoles: [],
            systemProcess: [],
            showAppraisalProcess: false,
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
            institution: {},
            validated: false,
            submitting: false,
            errors: {},
            ownUpdate: false,
            createAccount: false,
            bvn: "",
            canLoad: false,
            firstName: "",
            middleName: "",
            lastName: "",
            relationshipManagers: [],
            fetchingRequiredItems: false,
            canLoadNewAcc: false,
            accounts: [],
            accountOfficers: [],
            loanProducts: [],
            newAccount: {},
            bankCode: "",
            accountNumber: "",
            accountName: "",
            commercialBanks: [],
            AccountCreationPayload: {},
            accountProducts: [],
            phoneNumber: "",
            loadAccounts: false,
            currentUser: {},
            customerId: ""
        };
    }

    handleOnExport = async (type: string) => { };

    fetchAccountName = async (value, channel) => {
        console.log("Channel: ", channel + " value: ", value)
        let { bankCode, accountNumber } = this.state;
        if (channel === "bankName") {
            bankCode = value
        }

        else if (channel === "accountNumber") {
            accountNumber = value
        }

        this.setState({ bankCode, accountNumber }, async () => {
            if (this.state.bankCode != "" && this.state.accountNumber != "") {
                var accountName = await nameEnquiryService.nameEnquiry(this.state.accountNumber, this.state.bankCode)
                this.setState({ accountName: accountName.data })
            }
        })

    }

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    handleBVNSubmit = async (item: any) => {
        if (item.bvn === "" || item.bvn === null) {
            toast.error("BVN Must be provided", {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            return false;
        }


        const institution = this.state.institution;
        var authToken = institution.externalInstitutionCode

        const response = await bankOneWebAPIService.retrieveCustomerByBVN(authToken, item.bvn);
        console.log(response)
        if (response.status >= 200 && response.status <= 300) {
            var result = response.data

            console.log(result)

            if (result.IsSuccessful === false) {
                var accountOfficers = (await bankOneWebAPIService.retrieveAccountOfficersByInstitution(authToken)).data;
                var Products = (await bankOneWebAPIService.retrieveProductsByInstitution(authToken)).data;
                this.setState({ createAccount: true, bvn: item.bvn, canLoad: true, accountOfficers: accountOfficers, accountProducts: Products })
            }
            else {
                var accounts = result?.Message?.Accounts.filter(x => x.AccountType === "SavingsOrCurrent");
                if (accounts.length > 0) {
                    this.setState({ createAccount: false, bvn: item.bvn, canLoad: false, accounts: accounts, loadAccounts: true, customerId: accounts[0].CustomerID })
                }
                else {
                    var accountOfficers = (await bankOneWebAPIService.retrieveAccountOfficersByInstitution(authToken)).data;
                    var Products = (await bankOneWebAPIService.retrieveProductsByInstitution(authToken)).data;
                    this.setState({ createAccount: true, bvn: item.bvn, canLoad: true, accountOfficers: accountOfficers, accountProducts: Products })
                }
            }
        }
        else {
            return false;
        }
    }

    handleAccountPick = async (action: any) => {
        console.log(action)
        const institution = this.state.institution;
        var authToken = institution.externalInstitutionCode
        if (action.action === "New") {
            var accountOfficers = (await bankOneWebAPIService.retrieveAccountOfficersByInstitution(authToken)).data;
            var Products = (await bankOneWebAPIService.retrieveProductsByInstitution(authToken)).data;
            this.setState({ createAccount: true, canLoad: true, accountOfficers: accountOfficers, accountProducts: Products, loadAccounts: false })
        }
        else {
            this.setState({ createAccount: false, canLoad: true, loadAccounts: false })
        }
    }

    handleAccountOpening = async (item: any) => {

        const institution = this.state.institution;
        var authToken = institution.externalInstitutionCode
        const payload: any = {};
        try {
            // console.log(item)
            var otherNames = item.firstName + " " + item.middleName;
            payload.TransactionTrackingRef = "LAS" + generateGUID();
            payload.CustomerID = ""
            payload.AccountReferenceNumber = ""
            payload.AccountOpeningTrackingRef = "LAS" + generateGUID();
            payload.ProductCode = item.loanProduct2
            payload.LastName = item.lastName
            payload.OtherNames = otherNames
            payload.AccountName = item.lastName + " " + otherNames
            payload.BVN = item.bvn
            payload.FullName = item.lastName + " " + otherNames
            payload.PhoneNo = item.phoneNumber
            payload.Gender = item.gender
            payload.PlaceOfBirth = ""
            payload.DateOfBirth = item.dateOfBirth
            payload.Address = item.address
            payload.NationalIdentityNo = ""
            payload.NextOfKinPhoneNo = item.nextOfKinPhoneNumber
            payload.NextOfKinName = item.nextOfKinName
            payload.ReferralPhoneNo = ""
            payload.ReferralName = ""
            payload.HasSufficientInfoOnAccountInfo = true
            payload.AccountInformationSource = 0
            payload.OtherAccountInformationSource = ""
            payload.AccountOfficerCode = item.accountOfficer
            payload.AccountNumber = ""
            payload.Email = item.email
            payload.CustomerImage = ""
            payload.IdentificationImage = ""
            payload.IdentificationImageType = 0
            payload.CustomerSignature = ""
            payload.NotificationPreference = 0
            payload.TransactionPermission = 0
            payload.AccountTier = 3

            const response = await bankOneWebAPIService.createBankOneAccount(payload, authToken);

            if (response.status >= 200 && response.status <= 300) {
                if (response.data.isSuccessful) {
                    toast.info(`Bank Account Created successfully!`, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });

                    this.setState({
                        createAccount: false,
                        canLoad: true,
                        newAccount: response.data.message,
                        AccountCreationPayload: item,
                        firstName: item.firstName,
                        middleName: item.middleName,
                        lastName: item.lastName,
                        phoneNumber: item.phoneNumber,
                        customerId: response.data.message.customerID
                    })

                    const newPayload: any = {};
                    newPayload.accountNumber = response.data.message.bankoneAccountNumber
                    newPayload.institutionCode = authToken
                    newPayload.description = "Place PND"
                    newPayload.referenceCode = generateGUID()

                    const pnd = await bankOneWebAPIService.placePND(newPayload);
                }
                else {
                    toast.error(response.data.message, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }

            }
            else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        }
        finally {
            return true;
        }
    }

    handleLoanInitiation = async (item: any) => {


        const institution = this.state.institution;
        var authToken = institution.externalInstitutionCode
        const payload: any = {};
        try {
            // console.log(item)
            // console.log(this.state.currentUser)
            payload.customerId = this.state.customerId
            payload.customerFirstName = item.firstName;
            payload.customerLastName = item.lastName;
            payload.customerEmail = item.email;
            payload.dateOfBirth = "";
            payload.maritalStatus = "";
            payload.gender = "";
            payload.bvn = item.bvn;
            payload.institutionCode = institution.code;
            payload.requestIdentifier = item.loanApplicationId;
            payload.phoneNumber = item.phoneNumber;
            payload.address = "";
            payload.loanAmount = Number(item.loanAmount);
            payload.totalExpectedRepayment = 0;
            payload.linkedAccountNo = item.accountNumber === "" ? this.state.newAccount.bankoneAccountNumber : item.accountNumber;
            payload.tenureInDays = 0;
            payload.interestRate = 0;
            payload.obligorLimit = 0;
            payload.customerBalance = 0;
            payload.totalIncome = 0;
            payload.loanProductCode = item.loanProduct;
            payload.loanCycle = 0;
            payload.loanFees = 0;
            payload.defaultingLoanInterest = "0";
            payload.securityDeposit = "";
            payload.economicSector = item.loanSector;
            payload.loanAccountCreationDate = "";
            payload.loanAccountType = "";
            payload.loanAccountCustomerType = "";
            payload.repaymentAccountType = "";
            payload.branch = this.state.currentUser?.branch?.name;
            payload.preferredRepaymentBankCBNCode = item.preferredRepaymentBankCBNCode;
            payload.preferredRepaymentAccount = item.preferredRepaymentAccount

            // console.log(payload)
            const response = await loanRequestService.cretaeIndividualLoanRequest(payload);

            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Loan Request created successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        }
        finally {
            return true;
        }
    }


    generateUniqueIdentifier() {
        const randomFourDigits = Math.floor(Math.random() * 9000) + 1000;
        const currentDateTime = new Date().toLocaleDateString();
        const uniqueIdentifier = `${randomFourDigits}${currentDateTime}`;
        return uniqueIdentifier;
    }


    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    async componentDidMount() {
        this._isMounted = true;
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const response = await Promise.allSettled([
            institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"]),
            usersService.retrieveUsersByInstitutionId(decodedToken?.["InstitutionId"]),
            commercialBankService.retrieveAll(),
            loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
            usersService.retrieveUserById(decodedToken?.["UserId"]),
        ])
        // console.log(decodedToken?.["UserRoleId"])
        const institution = response[0].status == "fulfilled" ? response[0].value.data : [];
        const institutionUsers = response[1].status == "fulfilled" ? response[1].value.data : [];
        const commercialBanksDetails = response[2].status == "fulfilled" ? response[2].value.data : [];
        const loanProducts = response[3].status == "fulfilled" ? response[3].value.data : [];
        const currentUser = response[4].status == "fulfilled" ? response[4].value.data : [];

        if (this._isMounted) {
            this.setState({
                institution: institution,
                relationshipManagers: institutionUsers,
                fetchingRequiredItems: false,
                canLoadNewAcc: true,
                commercialBanks: commercialBanksDetails,
                loanProducts: loanProducts,
                currentUser: currentUser
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            institution,
            validated,
            errors,
            createAccount, canLoad, relationshipManagers, fetchingRequiredItems, canLoadNewAcc, accounts, accountOfficers,
            loanProducts, newAccount, commercialBanks, AccountCreationPayload, accountProducts, loadAccounts
        } = this.state;
        const { showDetailsModal, toggleDetailsModal } = this.props;

        let genderInputData: ISelectItems[] = [
            { name: "Male", value: "0" },
            { name: "Female", value: "1" },
        ]

        let loanPurposeInputData: ISelectItems[] = [
            { name: "Personal", value: "Personal" },
            { name: "Vehicle Acquisition", value: "VehicleAcquisition" },
            { name: "Home Acquisition", value: "HomeAcquisition" },
            { name: "Business Finance", value: "BusinessFinance" }
        ];

        let loanSectorInputData: ISelectItems[] = [
            { name: "Agriculture and Forestry", value: "AgricultureAndForestry" },
            { name: "Mining and Quarry", value: "MiningAndQuarry" },
            { name: "Manufacturing and Food Processing", value: "ManufacturingAndFoodProcessing" },
            { name: "Trade and Commerce", value: "TradeAndCommerce" },
            { name: "Transport and Communication", value: "TransportAndCommunication" },
            { name: "Real Estate and Construction", value: "RealEstateAndConstruction" },
            { name: "Rent or Housing", value: "RentOrHousing" },
            { name: "Consumer or Personal", value: "ConsumerOrPersonal" },
            { name: "Health", value: "Health" },
            { name: "Education", value: "Education" },
            { name: "Tourism and Hospitality", value: "TourismAndHospitality" },
            { name: "Purchase of Shares", value: "PurchaseOfShares" },
            { name: "Other", value: "Other" },
            { name: "Public Utilities", value: "PublicUtilities" },
            { name: "Finance or Insurance", value: "FinanceOrInsurance" },
            { name: "General", value: "General" },
            { name: "Electricity", value: "Electricity" },
            { name: "Gas", value: "Gas" },
            { name: "Water", value: "Water" },
            { name: "Building and Construction", value: "BuildingAndConstruction" },
            { name: "Financial Intermediaries", value: "FinancialIntermediaries" },
            { name: "Hotels and Restaurants", value: "HotelsAndRestaurants" },
            { name: "Leasing", value: "Leasing" },
            { name: "Warehousing and Storage", value: "WarehousingAndStorage" },
            { name: "Fishing", value: "Fishing" },
            { name: "Forest", value: "Forest" },
            { name: "Hunting", value: "Hunting" }
        ];

        let relationshipManagerInputData: ISelectItems[] = relationshipManagers?.map((item) => ({
            name: `${item.firstName} ${item.lastName} (${item.email})`,
            value: `${item.firstName} ${item.lastName} (${item.email})`,
        }));

        let accountsInputData: ISelectItems[] = accounts?.map((item) => ({
            name: `${item.AccountNumber} - ${item.CustomerName}`,
            value: `${item.AccountNumber}`,
        }));

        let accountsInputData2: ISelectItems[] = accountsInputData.concat({ name: "Create New Account", value: "New" })

        let actionData: ISelectItems[] = accountsInputData2;

        let actionInputData: ISelectItems[] | undefined = actionData?.map((item) => ({
            name: `${item.name}`,
            value: `${item.value}`,
        }));

        let accountOfficerInputData: ISelectItems[] = accountOfficers?.map((item) => ({
            name: `${item.Name}`,
            value: `${item.Code}`,
        }));

        let loanProductInputData: ISelectItems[] = loanProducts?.map((item) => ({
            name: `${item.name}`,
            value: `${item.productCode}`,
        }));

        let accountProductInputData: ISelectItems[] = accountProducts?.map((item) => ({
            name: `${item.ProductName}`,
            value: `${item.ProductCode}`,
        }));

        let commercialBanksList: ISelectItems[] = commercialBanks?.map((item) => ({
            name: item.institutionName,
            value: item.cbnCode,
        }));

        // console.log(newAccount)
        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDetailsModal}
            >
                <Modal.Header >
                    <Modal.Title>
                        <h5>
                            <i className="icon icon--columns s-18 mr-3" />
                            New Loan Application -{" "}
                            <span className="font-weight-bold"></span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewLoanApplicationForm
                        initialValues={
                            {
                                bvn: "",
                                lastName: AccountCreationPayload.lastName ? AccountCreationPayload.lastName : "",
                                firstName: AccountCreationPayload.firstName ? AccountCreationPayload.firstName : "",
                                middleName: AccountCreationPayload.middleName ? AccountCreationPayload.middleName : "",
                                phoneNumber: AccountCreationPayload.phoneNumber ? AccountCreationPayload.phoneNumber : "",
                                gender: "",
                                dateOfBirth: AccountCreationPayload.dateOfBirth ? AccountCreationPayload.dateOfBirth : "",
                                address: AccountCreationPayload.address ? AccountCreationPayload.address : "",
                                nextOfKinPhoneNumber: "",
                                nextOfKinName: "",
                                loanApplicationId: generateGUID(),
                                dateInitiated: new Date().toLocaleDateString(),
                                branch: "",
                                loanAmount: "",
                                loanProduct: "",
                                loanPurpose: "",
                                loanSector: "",
                                relationshipManager: "",
                                branchManager: "",
                                accountNumber: newAccount.bankoneAccountNumber ? newAccount.bankoneAccountNumber : "",
                                accountOfficer: "",
                                loanProduct2: "",
                                preferredRepaymentBankCBNCode: "",
                                preferredRepaymentAccount: "",
                                email: "",
                                action: ""
                            }
                        }
                        FormComponent={
                            ({
                                fields:
                                {
                                    bvn,
                                    lastName,
                                    firstName,
                                    middleName,
                                    phoneNumber,
                                    gender,
                                    dateOfBirth,
                                    address,
                                    nextOfKinPhoneNumber,
                                    nextOfKinName,
                                    loanApplicationId,
                                    dateInitiated,
                                    branch,
                                    loanAmount,
                                    loanProduct,
                                    loanPurpose,
                                    loanSector,
                                    relationshipManager,
                                    branchManager,
                                    accountNumber,
                                    accountOfficer,
                                    loanProduct2,
                                    preferredRepaymentBankCBNCode,
                                    preferredRepaymentAccount,
                                    email,
                                    action
                                },
                                onChange,
                                onReloadFields,
                                onHandleSubmit,
                            }) => (
                                <div className="container-fluid relative animatedParent animateOnce">
                                    <div className="animated fadeInUpShort go">
                                        {institution.category === "BankOneOFI" ? (
                                            <>
                                                < FormInputComponent
                                                    id="bvn"
                                                    name="bvn"
                                                    type="number"
                                                    placeholder=""
                                                    divClass={4}
                                                    value={bvn}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                />

                                                <button
                                                    type="button"
                                                    className="btn btn-primary my-2 mx-2"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "33px",
                                                        fontSize: "12px",
                                                        borderRadius: "8px",
                                                        color: "rgb(255, 255, 255)",
                                                        borderColor: "rgb(105, 65, 198)",
                                                        backgroundColor: "rgb(105, 65, 198)"
                                                    }}
                                                    onClick={() =>
                                                        this.handleBVNSubmit({ bvn })
                                                    }
                                                >
                                                    {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                                        className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                                            <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                                                    <span>Validate</span></button>

                                                {loadAccounts ? (
                                                    < div className="card" >
                                                        < div className="card-header clear-fix" >
                                                            < h6
                                                                className="float-left"
                                                                style={{ marginBottom: "0px" }}
                                                            >
                                                                < b >Existing Account Details</ b >
                                                            </ h6 >
                                                        </ div >
                                                        < div className="card-body" >
                                                            < div className="form-row" >

                                                                {accounts?.map((x) => (
                                                                    <><FormInputComponent
                                                                        label="Account Number"
                                                                        id="accountNumber"
                                                                        name="accountNumber"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={x.AccountNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled />
                                                                        <FormInputComponent
                                                                            label="AccountName"
                                                                            id="accountName"
                                                                            name="accountName"
                                                                            type="text"
                                                                            placeholder=""
                                                                            divClass={6}
                                                                            value={x.CustomerName}
                                                                            required={true}
                                                                            validated={validated}
                                                                            errors={errors}
                                                                            onChange={onChange}
                                                                            disabled />
                                                                    </>

                                                                ))}
                                                                <SelectInputComponent
                                                                    label="Choose Account"
                                                                    id="action"
                                                                    name="action"
                                                                    divClass={6}
                                                                    value={action}
                                                                    items={actionInputData}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary my-2 mx-2"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "33px",
                                                                        fontSize: "12px",
                                                                        borderRadius: "8px",
                                                                        color: "rgb(255, 255, 255)",
                                                                        borderColor: "rgb(105, 65, 198)",
                                                                        backgroundColor: "rgb(105, 65, 198)"
                                                                    }}
                                                                    onClick={() =>
                                                                        this.handleAccountPick({ action })
                                                                    }
                                                                >
                                                                    {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                                        className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                                            <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                                                                    <span>Submit</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ("")}

                                                {createAccount && canLoad ? (
                                                    <>
                                                        < div className="card" >
                                                            < div className="card-header clear-fix" >
                                                                < h6
                                                                    className="float-left"
                                                                    style={{ marginBottom: "0px" }}
                                                                >
                                                                    < b >Bank Account Creation </ b >
                                                                </ h6 >
                                                            </ div >
                                                            < div className="card-body" >
                                                                < div className="form-row" >
                                                                    <FormInputComponent
                                                                        label="First Name"
                                                                        id="firstName"
                                                                        name="firstName"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={firstName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        label="Middle Name"
                                                                        id="middleName"
                                                                        name="middleName"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={middleName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        label="Last Name"
                                                                        id="lastName"
                                                                        name="lastName"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={lastName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        label="Phone Number"
                                                                        id="phoneNumber"
                                                                        name="phoneNumber"
                                                                        type="number"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={phoneNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        label="BVN"
                                                                        id="bvn"
                                                                        name="bvn"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.bvn}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled />

                                                                    <SelectInputComponent
                                                                        label="Gender"
                                                                        id="gender"
                                                                        name="gender"
                                                                        divClass={6}
                                                                        value={gender}
                                                                        items={genderInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />

                                                                    <FormInputComponent
                                                                        label="Date of Birth"
                                                                        id="dateOfBirth"
                                                                        name="dateOfBirth"
                                                                        type="date"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={dateOfBirth}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        label="Address"
                                                                        id="address"
                                                                        name="address"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={address}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        // label="Contact Number"
                                                                        id="nextOfKinName"
                                                                        name="nextOfKinName"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={nextOfKinName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />

                                                                    <FormInputComponent
                                                                        // label="Email Address"
                                                                        id="nextOfKinPhoneNumber"
                                                                        name="nextOfKinPhoneNumber"
                                                                        type="number"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={nextOfKinPhoneNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange} />
                                                                    <SelectInputComponent
                                                                        id="accountOfficer"
                                                                        name="accountOfficer"
                                                                        divClass={6}
                                                                        value={accountOfficer}
                                                                        items={accountOfficerInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                    <SelectInputComponent
                                                                        label="Account Product"
                                                                        id="loanProduct2"
                                                                        name="loanProduct2"
                                                                        divClass={6}
                                                                        value={loanProduct2}
                                                                        items={accountProductInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary my-2 mx-2"
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "33px",
                                                                            fontSize: "12px",
                                                                            borderRadius: "8px",
                                                                            color: "rgb(255, 255, 255)",
                                                                            borderColor: "rgb(105, 65, 198)",
                                                                            backgroundColor: "rgb(105, 65, 198)"
                                                                        }}
                                                                        onClick={() =>
                                                                            this.handleAccountOpening({
                                                                                bvn,
                                                                                lastName,
                                                                                firstName,
                                                                                middleName,
                                                                                phoneNumber,
                                                                                gender,
                                                                                dateOfBirth,
                                                                                address,
                                                                                nextOfKinPhoneNumber,
                                                                                nextOfKinName,
                                                                                accountOfficer,
                                                                                loanProduct2
                                                                            })
                                                                        }
                                                                    >
                                                                        {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                                                            className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                                                                <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                                                                        <span>Initiate Account Opening</span></button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (!createAccount && canLoad ? (
                                                    < div className="card" >
                                                        < div className="card-header clear-fix" >
                                                            < h6
                                                                className="float-left"
                                                                style={{ marginBottom: "0px" }}
                                                            >
                                                                < b >New Loan Application </ b >
                                                            </ h6 >
                                                        </ div >
                                                        < div className="card-body" >
                                                            < div className="form-row" >
                                                                <FormInputComponent
                                                                    id="loanApplicationId"
                                                                    name="loanApplicationId"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={loanApplicationId}
                                                                    required={false}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled />
                                                                {Object.keys(newAccount).length === 0 ? (
                                                                    <SelectInputComponent
                                                                        id="accountNumber"
                                                                        name="accountNumber"
                                                                        divClass={6}
                                                                        value={accountNumber}
                                                                        items={accountsInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}

                                                                    />
                                                                ) : (
                                                                    <FormInputComponent
                                                                        id="accountNumber"
                                                                        name="accountNumber"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.newAccount.bankoneAccountNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled
                                                                    />
                                                                )}
                                                                <FormInputComponent
                                                                    id="dateInitiated"
                                                                    name="dateInitiated"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={dateInitiated}
                                                                    required={false}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled />

                                                                <FormInputComponent
                                                                    id="firstName"
                                                                    name="firstName"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={firstName}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled={this.state.firstName === "" ? false : true} />

                                                                <FormInputComponent
                                                                    id="middleName"
                                                                    name="middleName"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={middleName}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled={this.state.middleName === "" ? false : true} />

                                                                <FormInputComponent
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={lastName}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled={this.state.lastName === "" ? false : true} />
                                                                <FormInputComponent
                                                                    label="BVN"
                                                                    id="bvn"
                                                                    name="bvn"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={this.state.bvn}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled />

                                                                <FormInputComponent
                                                                    label="Phone Number"
                                                                    id="phoneNumber"
                                                                    name="phoneNumber"
                                                                    type="number"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={phoneNumber}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    disabled={this.state.phoneNumber === "" ? false : true} />

                                                                <FormInputComponent
                                                                    label="Email Address"
                                                                    id="email"
                                                                    name="email"
                                                                    type="email"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={email}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange} />

                                                                <SelectInputComponent
                                                                    label="Loan Product"
                                                                    id="loanProduct"
                                                                    name="loanProduct"
                                                                    items={loanProductInputData}
                                                                    value={loanProduct}
                                                                    divClass={6}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                <SelectInputComponent
                                                                    id="loanPurpose"
                                                                    name="loanPurpose"
                                                                    divClass={6}
                                                                    value={loanPurpose}
                                                                    items={loanPurposeInputData}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                <SelectInputComponent
                                                                    id="loanSector"
                                                                    name="loanSector"
                                                                    divClass={6}
                                                                    value={loanSector}
                                                                    items={loanSectorInputData}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />

                                                                <FormInputComponent
                                                                    id="loanAmount"
                                                                    name="loanAmount"
                                                                    type="number"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={loanAmount}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange} />

                                                                {/* <FormInputComponent
                                                                    id="branch"
                                                                    name="branch"
                                                                    type="text"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={branch}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange} /> */}

                                                                <SelectInputComponent
                                                                    label="Bank Name"
                                                                    id="preferredRepaymentBankCBNCode"
                                                                    name="preferredRepaymentBankCBNCode"
                                                                    items={commercialBanksList}
                                                                    value={preferredRepaymentBankCBNCode}
                                                                    divClass={6}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={async (id, value) => {
                                                                        await onChange(id, value)
                                                                        // this.setState({ bankCode: value, ownUpdate: true });
                                                                        await this.fetchAccountName(value, "bankName")
                                                                    }} />

                                                                <FormInputComponent
                                                                    id="preferredRepaymentAccount"
                                                                    type="text"
                                                                    name="preferredRepaymentAccount"
                                                                    placeholder=""
                                                                    value={preferredRepaymentAccount}
                                                                    divClass={6}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    onBlur={(e) => { this.fetchAccountName(e.target.value, "accountNumber") }}
                                                                />
                                                                <FormInputComponent
                                                                    id="accountName"
                                                                    type="text"
                                                                    name="accountName"
                                                                    placeholder=""
                                                                    value={this.state.accountName}
                                                                    divClass={6}
                                                                    required={false}
                                                                    disabled
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange} />
                                                                {/* <SelectInputComponent
                                                                    id="relationshipManager"
                                                                    name="relationshipManager"
                                                                    divClass={6}
                                                                    value={relationshipManager}
                                                                    items={relationshipManagerInputData}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    labelIconClass={fetchingRequiredItems === true
                                                                        ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                        : ""
                                                                    }
                                                                />
                                                                <SelectInputComponent
                                                                    id="branchManager"
                                                                    name="branchManager"
                                                                    divClass={6}
                                                                    value={branchManager}
                                                                    items={relationshipManagerInputData}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                    labelIconClass={fetchingRequiredItems === true
                                                                        ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                        : ""
                                                                    }
                                                                /> */}
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary my-3"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "33px",
                                                                        fontSize: "12px",
                                                                        borderRadius: "8px",
                                                                        color: "rgb(255, 255, 255)",
                                                                        borderColor: "rgb(105, 65, 198)",
                                                                        backgroundColor: "rgb(105, 65, 198)"
                                                                    }}
                                                                    onClick={() =>
                                                                        this.handleLoanInitiation({
                                                                            accountNumber,
                                                                            lastName,
                                                                            firstName,
                                                                            middleName,
                                                                            loanApplicationId,
                                                                            dateInitiated,
                                                                            branch,
                                                                            loanAmount,
                                                                            loanProduct,
                                                                            loanPurpose,
                                                                            loanSector,
                                                                            bvn,
                                                                            phoneNumber,
                                                                            // relationshipManager,
                                                                            // branchManager,
                                                                            preferredRepaymentBankCBNCode,
                                                                            preferredRepaymentAccount,
                                                                            email
                                                                        })
                                                                    }
                                                                >
                                                                    {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                                                        className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                                                            <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                                                                    <span>Initiate Request</span></button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (""))}
                                            </>
                                        ) : (canLoadNewAcc ? (
                                            < div className="card" >
                                                < div className="card-header clear-fix" >
                                                    < h6
                                                        className="float-left"
                                                        style={{ marginBottom: "0px" }}
                                                    >
                                                        < b >New Loan Application </ b >
                                                    </ h6 >
                                                </ div >
                                                < div className="card-body" >
                                                    < div className="form-row" >
                                                        <FormInputComponent
                                                            id="loanApplicationId"
                                                            name="loanApplicationId"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={loanApplicationId}
                                                            required={false}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            disabled />

                                                        <FormInputComponent
                                                            id="dateInitiated"
                                                            name="dateInitiated"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={dateInitiated}
                                                            required={false}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange} />

                                                        <FormInputComponent
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={firstName}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            disabled={firstName === "" ? false : true} />

                                                        <FormInputComponent
                                                            id="middleName"
                                                            name="middleName"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={middleName}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            disabled={middleName === "" ? false : true} />

                                                        <FormInputComponent
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={lastName}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            disabled={lastName === "" ? false : true} />

                                                        <FormInputComponent
                                                            label="BVN"
                                                            id="bvn"
                                                            name="bvn"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={this.state.bvn}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            disabled />

                                                        <FormInputComponent
                                                            label="Phone Number"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            type="number"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={phoneNumber}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                        <FormInputComponent
                                                            label="Email Address"
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={email}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange} />

                                                        <SelectInputComponent
                                                            label="Loan Product"
                                                            id="loanProduct"
                                                            name="loanProduct"
                                                            items={loanProductInputData}
                                                            value={loanProduct}
                                                            divClass={6}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />

                                                        <SelectInputComponent
                                                            id="loanPurpose"
                                                            name="loanPurpose"
                                                            divClass={6}
                                                            value={loanPurpose}
                                                            items={loanPurposeInputData}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                        <SelectInputComponent
                                                            id="loanSector"
                                                            name="loanSector"
                                                            divClass={6}
                                                            value={loanSector}
                                                            items={loanSectorInputData}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />

                                                        <FormInputComponent
                                                            id="loanAmount"
                                                            name="loanAmount"
                                                            type="number"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={loanAmount}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange} />

                                                        {/* <FormInputComponent
                                                            id="branch"
                                                            name="branch"
                                                            type="text"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={branch}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange} /> */}

                                                        {/* <SelectInputComponent
                                                            id="relationshipManager"
                                                            name="relationshipManager"
                                                            divClass={6}
                                                            value={relationshipManager}
                                                            items={relationshipManagerInputData}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            labelIconClass={fetchingRequiredItems === true
                                                                ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                : ""
                                                            }
                                                        />
                                                        <SelectInputComponent
                                                            id="branchManager"
                                                            name="branchManager"
                                                            divClass={6}
                                                            value={branchManager}
                                                            items={relationshipManagerInputData}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                            labelIconClass={fetchingRequiredItems === true
                                                                ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                : ""
                                                            }
                                                        /> */}
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary my-3 "
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                height: "33px",
                                                                fontSize: "12px",
                                                                borderRadius: "8px",
                                                                color: "rgb(255, 255, 255)",
                                                                borderColor: "rgb(105, 65, 198)",
                                                                backgroundColor: "rgb(105, 65, 198)"
                                                            }}
                                                            onClick={() =>
                                                                this.handleLoanInitiation({
                                                                    accountNumber,
                                                                    lastName,
                                                                    firstName,
                                                                    middleName,
                                                                    loanApplicationId,
                                                                    dateInitiated,
                                                                    branch,
                                                                    loanAmount,
                                                                    loanProduct,
                                                                    loanPurpose,
                                                                    loanSector,
                                                                    bvn,
                                                                    phoneNumber,
                                                                    // relationshipManager,
                                                                    // branchManager,
                                                                    preferredRepaymentBankCBNCode,
                                                                    preferredRepaymentAccount,
                                                                    email
                                                                })
                                                            }
                                                        >
                                                            {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                                                className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                                                    <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                                                            <span>Initiate Request</span></button>

                                                    </div>
                                                </div>
                                            </div>
                                        ) : (""))}
                                    </div>
                                </div >
                            )}
                    ></NewLoanApplicationForm >
                </Modal.Body > <Modal.Footer>

                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={toggleDetailsModal}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

export default NewLoanApplication;
