import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "../../services/utility/autoMapperService";
import cash from "../../images/icons/icons8-cash-48.png";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import feePaymentService from "../../services/billing/feePaymentService";
import InstitutionFeePayableReportDetail from "./institutionFeePayableReportDetail";
import settlementWithdrawalService from "../../services/collectionSweep/settlementWithdrawalService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import textFormatService from "../../services/utility/textFormatService";
import institutionsService from "../../services/auth/institutionsService";
import { toast } from "react-toastify";
import SettlementWithdrawalAmount from "./settlementWithdrawalAmountModal";

export interface WalletTransactionsReportProps { }

export interface WalletTransactionsReportState
    extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
    settlementWalletItem: any
    fetchingData: boolean;
    institutionCode: string;
    submitting: boolean;
    ownUpdate: boolean;
    showModal: boolean;
}


class WalletTransactionsReportList extends BaseListComponent<WalletTransactionsReportState> { }

class WalletTransactions extends React.Component<
    WalletTransactionsReportProps,
    WalletTransactionsReportState
> {
    constructor(props: WalletTransactionsReportProps) {
        super(props);
        this.state = {
            data: createFilterData([
                "settlementReference",
                "date"
            ]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "settlementReference", fieldType: "text", value: "" },
                { name: "date", fieldType: "date", value: "" }
            ],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            settlementWalletItem: {},
            fetchingData: false,
            institutionCode: "",
            submitting: false,
            ownUpdate: false,
            showModal: false
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        data = typeof data === "undefined" ? this.state.data : data;
        var dataFilter = this.getFilteredItems(data)
        const currentSession = getCurrentUserSession();
        const decodedToken = decodeToken(currentSession.token || "");
        dataFilter["institutionCode"] = decodedToken?.["InstitutionCode"]?.toString()
        try {
            const reportItems = await settlementWithdrawalService.getWalletTransactionsByInstitution(
                currentPage,
                pageSize,
                dataFilter
            );

            // const report: any = [];
            // const totalLength = reportItems.data.length;
            // const initial = (currentPage - 1) * pageSize;
            // for (let i = initial; i < initial + pageSize; i++) {
            //     if (i === totalLength) {
            //         break;
            //     }
            //     report.push(reportItems.data[i]);
            // }


            // console.log(reportItems.data);
            this.setState({
                tableItems: mapEntityList({
                    entityList: reportItems.data.data,
                    properties: [
                        { oldName: "id", newName: "id" },
                        { oldName: "settlementReference", newName: "settlementReference" },
                        { oldName: "transactionAmount", newName: "transactionAmount" },
                        // { oldName: "transactionType", newName: "transactionType" },
                        { oldName: "transactionDate", newName: "transactionDate" }
                    ],
                }),
                currentPage: reportItems.data.currentPage,
                pageSize: reportItems.data.pageSize,
                totalSize: reportItems.data.totalCount,
                fetching: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => {
        try {
            switch (type) {
                case "XLSX":
                    return await feePaymentService.downloadPayableReport("", "EXCEL");
                case "CSV":
                    return await feePaymentService.downloadPayableReport("", type);
                case "PDF":
                    return await feePaymentService.downloadPayableReport("", type);
                default:
                    break;
            }
        } catch (ex) {
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                //this.setState({ errors, fetching: false });
            }
        }
    };

    fetchItemById = async (item: any) => {
        this.setState({ fetchingDetail: true });
        const report = await feePaymentService.retrievePayableReportById(
            item.id
        );
        this.setState({ item: report.data, fetchingDetail: false });
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    onToggleAmountModal = () => {
        if (this.state.settlementWalletItem?.balance === 0
            || this.state.settlementWalletItem?.balance === ""
            || this.state.settlementWalletItem?.balance === null
            || this.state.settlementWalletItem?.balance === undefined) {
            toast.error("No withdrawable balance", {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            return false;
        }
        this.setState({ showModal: !this.state.showModal, ownUpdate: true });
    };

    async componentDidMount() {

        const currentSession = getCurrentUserSession();
        const decodedToken = decodeToken(currentSession.token || "");
        try {
            var institution = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"])
            var institutionWallet = await settlementWithdrawalService.getWalletByInstitution(decodedToken?.["InstitutionCode"])
        }
        catch {

        }
        this.handlePageChange(this.state.currentPage, this.state.pageSize);

        this.setState({ settlementWalletItem: institutionWallet?.data?.data, institutionCode: institution.data.code })
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
            settlementWalletItem,
            institutionCode,
            submitting,
            showModal
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">

                    <div className="wallet-container">
                        <div className="wallet-box">
                            <div className="wallet-details">
                                <div className="wallet-icon">
                                    <img src={cash} alt="Cash icon" />
                                </div>
                                <h6 className="wallet-title">Wallet Balance</h6>

                                <div className="wallet-balance">
                                    {this.state.fetchingData ? (
                                        <div className="wallet-preloader">
                                            <div className="spinner-layer">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        settlementWalletItem?.balance === 0
                                            || settlementWalletItem?.balance === ""
                                            || settlementWalletItem?.balance === null
                                            || settlementWalletItem?.balance === undefined
                                            ? "0.00" :
                                            textFormatService.formatMoney(settlementWalletItem?.balance)
                                        // "10,000,000,000"
                                    )}
                                </div>

                            </div>
                            {console.log(settlementWalletItem?.balance)}
                            <div className="withdrawal-section">
                                <button className="btn-withdraw"
                                    onClick={() => this.onToggleAmountModal()}
                                    disabled={false}
                                >
                                    {this.state.submitting ? ("Withrawal in progress.....") :
                                        // settlementWalletItem?.availableBalance === 0
                                        //     || settlementWalletItem?.availableBalance === ""
                                        //     || settlementWalletItem?.availableBalance === null
                                        //     || settlementWalletItem?.availableBalance === undefined ?
                                        // ("What do you want to withdraw") :
                                        ("Withdraw")}
                                </button>
                                {/* <p className="withdrawal-notice">Please note, this attracts withdrawal charges</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Wallet Credit Transactions</b>
                            </h3>
                        </div>
                        <WalletTransactionsReportList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: createFilterData([
                                    "settlementReference",
                                    "date"
                                ]) as IFilterData,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: [
                                    { name: "settlementReference", fieldType: "text", value: "" },
                                    { name: "date", fieldType: "date", value: "" }
                                ],
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                                item,
                                onToggleDetailsModal,
                                showDetailsModal,
                            }) => (
                                <Fragment>
                                    {/* <InstitutionFeePayableReportDetail
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        item={item}
                                    /> */}
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="walletCreditTransactions"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                // this.fetchItemById(item);
                                                                // onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["transactionAmount"]}
                                                            amountInNaira={true}
                                                            timeColumn={["transactionDate"]}
                                                            disableViewDetails
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SettlementWithdrawalAmount
                                        institutionCode={institutionCode}
                                        showAmountModal={showModal}
                                        toggleAmountModal={this.onToggleAmountModal}

                                    />
                                </div>
                            )}
                        ></WalletTransactionsReportList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default WalletTransactions;
