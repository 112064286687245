import Joi from "joi-browser";
import _ from "lodash";

export function validateAddAssetDetailForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      category: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetCategory")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Category is required." };
          })
        : Joi.any(),
      type: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Type is required." };
          })
        : Joi.any(),
      description: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetDescription")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Description is required." };
          })
        : Joi.any(),
      year: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetYear")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Year is required." };
          })
        : Joi.any(),
      model: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetModel")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Model is required." };
          })
        : Joi.any(),
      life: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetLife")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Life is required." };
          })
        : Joi.any(),
      age: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetAge")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Age is required." };
          })
        : Joi.any(),
      manufacturer: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetBrand")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Asset Brand is required." };
          })
        : Joi.any(),
      supplier: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetSupplier")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Supplier is required." };
          })
        : Joi.any()
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddAssetLoanSummaryDetailForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      cost: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetCost")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Cost is required." };
          })
        : Joi.any(),
      loanAmount: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetLoanAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Amount is required." };
          })
        : Joi.any(),
      equityPayment: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetEquityPayment")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Equity Payment is required." };
          })
        : Joi.any(),
      loanToValue: mandatoryFields?.inputFields.filter(x => x.fieldName === "assetLoanToValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan To Value is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddPropertyDetailForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      propertyType: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Type is required." };
          })
        : Joi.any(),
      propertyDescription: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyDescription")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Description is required." };
          })
        : Joi.any(),
      address: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Address is required." };
          })
        : Joi.any(),
      city: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property City/Town is required." };
          })
        : Joi.any(),
      country: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyCountry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Country is required." };
          })
        : Joi.any(),
      state: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyState")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property State/Region is required." };
          })
        : Joi.any(),
      marketValue: mandatoryFields?.inputFields.filter(x => x.fieldName === "marketValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Market Value is required." };
          })
        : Joi.any(),
      appraisedValue: mandatoryFields?.inputFields.filter(x => x.fieldName === "appraisedValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Appraised Value is required." };
          })
        : Joi.any(),
      locationTier: mandatoryFields?.inputFields.filter(x => x.fieldName === "locationCategory")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Location Category is required." };
          })
        : Joi.any(),
      appraiser: mandatoryFields?.inputFields.filter(x => x.fieldName === "appraiser")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Appraiser is required." };
          })
        : Joi.any(),
      appraisalDate: mandatoryFields?.inputFields.filter(x => x.fieldName === "appraisalDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Appraisal Date is required." };
          })
        : Joi.any()
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddPropertyLoanSummaryDetailForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      cost: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyCost")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Cost is required." };
          })
        : Joi.any(),
      loanAmount: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyLoanAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Amount is required." };
          })
        : Joi.any(),
      equityPayment: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyEquityPayment")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Equity Payment is required." };
          })
        : Joi.any(),
      loanToValue: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyLoanToValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan to Value is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCollateralForm(data, isPropertyCategory: boolean, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      category: mandatoryFields?.inputFields.filter(x => x.fieldName === "collateralCategory")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Collateral Category is required." };
          })
        : Joi.any(),
      type: mandatoryFields?.inputFields.filter(x => x.fieldName === "collateralType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Collateral Type is required." };
          })
        : Joi.any(),
      description: mandatoryFields?.inputFields.filter(x => x.fieldName === "collateralDescription")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Collateral Description is required." };
          })
        : Joi.any(),
      value: mandatoryFields?.inputFields.filter(x => x.fieldName === "collateralValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Collateral Value is required." };
          })
        : Joi.any(),
      propSize: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertySize")[0]?.mandatory
        ? isPropertyCategory ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Size is required." };
          }
          ) : Joi.string().allow(null, '')
        : Joi.any(),
      propLocation: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyLocation")[0]?.mandatory
        ? isPropertyCategory ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Location is required." };
          }
          ) : Joi.string().allow(null, '')
        : Joi.any(),
      ownershipStructure: mandatoryFields?.inputFields.filter(x => x.fieldName === "ownershipStructure")[0]?.mandatory
        ? isPropertyCategory ? Joi.string().required()
          .error((errors) => {
            return { message: "Ownership Structure is required." };
          }
          ) : Joi.string().allow(null, '')
        : Joi.any(),
      serialNo: mandatoryFields?.inputFields.filter(x => x.fieldName === "serialRegistrationNumber")[0]?.mandatory
        ? isPropertyCategory ? Joi.string().required()
          .error((errors) => {
            return { message: "Serial Registration Number is required." };
          }
          ) : Joi.string().allow(null, '')
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateCreditSummaryForm(data, decisionNeeded) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      decision: Joi.string().required()
        .error((errors) => {
          return { message: "Decision is required." };
        }
        ),
      comment: Joi.string().required()
        .error((errors) => {
          return { message: "Comment is required." };
        }
        ),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateContactDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      addressType: mandatoryFields?.inputFields.filter(x => x.fieldName === "addressType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Address Type is required." };
          })
        : Joi.any(),
      propertyStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "propertyStatus")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Property Status is required." };
          })
        : Joi.any(),
      address: mandatoryFields?.inputFields.filter(x => x.fieldName === "address")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Address is required." };
          })
        : Joi.any(),
      city: mandatoryFields?.inputFields.filter(x => x.fieldName === "city")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "City/Town is required." };
          })
        : Joi.any(),
      state: mandatoryFields?.inputFields.filter(x => x.fieldName === "state")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "State/Region is required." };
          })
        : Joi.any(),
      country: mandatoryFields?.inputFields.filter(x => x.fieldName === "country")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Country is required." };
          })
        : Joi.any(),
      phonenumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "phoneNumber1")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 1 is required." };
          })
        : Joi.any(),
      phonenumber2: mandatoryFields?.inputFields.filter(x => x.fieldName === "phoneNumber2")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 2 is required." };
          })
        : Joi.any(),
      customerEmail: mandatoryFields?.inputFields.filter(x => x.fieldName === "emailAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Email Address is required." };
          })
        : Joi.any(),
      yearsOfStayAtCurrentAddress: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfYearAtCurrentAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Years of Stay at Address is required." };
          })
        : Joi.any(),
      monthsOfStayAtCurrentAddress: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfMonthAtCurrentAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Months of Stay at Address is required." };
          })
        : Joi.any(),
      yearsOfStayAtCurrentCity: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfYearAtCurrentCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Years of Stay at City is required." };
          })
        : Joi.any(),
      monthsOfStayAtCurrentCity: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfMonthAtCurrentCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Months of Stay at City is required." };
          })
        : Joi.any(),
      twitterHandle: mandatoryFields?.inputFields.filter(x => x.fieldName === "twitterHandle")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Twitter Handle is required." };
          })
        : Joi.any(),
      instagramID: mandatoryFields?.inputFields.filter(x => x.fieldName === "instagramId")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Instagram ID is required." };
          })
        : Joi.any(),
      facebookID: mandatoryFields?.inputFields.filter(x => x.fieldName === "facebookId")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Facebook ID is required." };
          })
        : Joi.any(),
      location: mandatoryFields?.inputFields.filter(x => x.fieldName === "locationCoordinates")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Location Co-ordinates is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateCorporateDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      companyName: mandatoryFields?.inputFields.filter(x => x.fieldName === "companyName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Company Name is required." };
          })
        : Joi.any(),
      registrationNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "registrationNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Registration Number is required." };
          })
        : Joi.any(),
      constitution: mandatoryFields?.inputFields.filter(x => x.fieldName === "constitution")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Constitution is required." };
          })
        : Joi.any(),
      industry: mandatoryFields?.inputFields.filter(x => x.fieldName === "industry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Industry is required." };
          })
        : Joi.any(),
      incorporationDate: mandatoryFields?.inputFields.filter(x => x.fieldName === "incorporationDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Incorporation Date is required." };
          })
        : Joi.any(),
      contactPerson: mandatoryFields?.inputFields.filter(x => x.fieldName === "contactPerson")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Contact Person is required." };
          })
        : Joi.any(),
      designation: mandatoryFields?.inputFields.filter(x => x.fieldName === "contactPersonDesignation")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Designation is required." };
          })
        : Joi.any(),
      lengthOfYearsInBusiness: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfYearsInBusiness")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Length of Years in Business is required." };
          })
        : Joi.any(),
      monthsInCurrentJob: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfMonthsInBusiness")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Length of Months in Business is required." };
          })
        : Joi.any(),
      accountType: mandatoryFields?.inputFields.filter(x => x.fieldName === "corporateAccountType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Type is required." };
          })
        : Joi.any(),
      accountNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "corporateAccountNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Number is required." };
          })
        : Joi.any(),
      ageOfAccount: mandatoryFields?.inputFields.filter(x => x.fieldName === "corporateAgeOfAccount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Age Of Account is required." };
          })
        : Joi.any(),
      domiciliationOfBusinessProceeds: mandatoryFields?.inputFields.filter(x => x.fieldName === "domiciliationOfBusinessProceeds")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Domiciliation of Business Proceeds is required." };
          })
        : Joi.any(),
      typeOfProductOffered: mandatoryFields?.inputFields.filter(x => x.fieldName === "typeOfProduct")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Type of Product/Service Offered is required." };
          })
        : Joi.any(),
      productDurability: mandatoryFields?.inputFields.filter(x => x.fieldName === "productDurability")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Product Durability is required." };
          })
        : Joi.any(),
      threatOfCompetition: mandatoryFields?.inputFields.filter(x => x.fieldName === "threatOfSubstitute")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Threat of Substitute/Competition is required." };
          })
        : Joi.any(),
      availabilityOfTradingStock: mandatoryFields?.inputFields.filter(x => x.fieldName === "availabilityOfInputMaterial")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Availability of Input material/Trading stock is required." };
          })
        : Joi.any(),
      complexityOfProduct: mandatoryFields?.inputFields.filter(x => x.fieldName === "complexityOfProduct")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Complexity of Product/Service is required." };
          })
        : Joi.any(),
      operationalStructure: mandatoryFields?.inputFields.filter(x => x.fieldName === "operationalStructure")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Operational Structure is required." };
          })
        : Joi.any(),
      numberOfYearsOperatingInTheSameLocation: mandatoryFields?.inputFields.filter(x => x.fieldName === "numberOfYearsOperatingInTheSameLocation")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Number of years operating in the same location is required." };
          })
        : Joi.any(),
      foreignExchangeRisk: mandatoryFields?.inputFields.filter(x => x.fieldName === "foreignExchangeRisk")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Foreign Exchange Risk is required." };
          })
        : Joi.any(),
      membershipOfAnOrganizedBody: mandatoryFields?.inputFields.filter(x => x.fieldName === "membershipOfAnOrganizedBody")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Membership of an Organized Body is required." };
          })
        : Joi.any(),
      customerTin: mandatoryFields?.inputFields.filter(x => x.fieldName === "corporateCustomerTin")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "TIN is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateDocumentCollectionForm(data, documentType, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      documentName: mandatoryFields?.inputFields.filter(x => x.fieldName === "documentName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Document Name is required." };
          })
        : Joi.any(),
      documentNewName: documentType === "Others" ? Joi.string().required()
        .error((errors) => {
          return { message: "Document New Name is required." };
        }) : Joi.any(),
      documentCategory: mandatoryFields?.inputFields.filter(x => x.fieldName === "documentCategory")[0]?.mandatory
        ? documentType === "Others" ? Joi.any() : Joi.string().required()
          .error((errors) => {
            return { message: "Document Category is required." };
          })
        : Joi.any(),
      documentStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "documentStatus")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Document Status is required." };
          })
        : Joi.any(),
      documentExpiryDate: mandatoryFields?.inputFields.filter(x => x.fieldName === "documentExpiryDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Document Expiry Date is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateBankStatementForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      bankName: mandatoryFields?.inputFields.filter(x => x.fieldName === "bankName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Bank Name is required." };
          }) : Joi.any(),
      accountType: mandatoryFields?.inputFields.filter(x => x.fieldName === "bankAccountType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Type is required." };
          }) : Joi.any(),
      accountNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "bankAccountNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Number is required." };
          }) : Joi.any(),
      accountStatementPeriod: mandatoryFields?.inputFields.filter(x => x.fieldName === "accountStatementPeriod")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Statement Period is required." };
          }) : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateGuarantorDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      title: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceTitle")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Title is required." };
          })
        : Joi.any(),
      relationship: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceRelationship")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Relationship is required." };
          })
        : Joi.any(),
      firstName: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceFirstName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "First Name is required." };
          })
        : Joi.any(),
      middleName: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceMiddleName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Middle Name is required." };
          })
        : Joi.any(),
      lastName: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceLastName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Last Name is required." };
          })
        : Joi.any(),
      gender: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceGender")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Gender is required." };
          })
        : Joi.any(),
      dateOfBirth: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceDateOfBirth")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Date Of Birth is required." };
          })
        : Joi.any(),
      address: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Address is required." };
          })
        : Joi.any(),
      city: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "City is required." };
          })
        : Joi.any(),
      state: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceState")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "State is required." };
          })
        : Joi.any(),
      country: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceCountry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Country is required." };
          })
        : Joi.any(),
      phoneNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "referencePhoneNumber1")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 1 is required." };
          })
        : Joi.any(),
      phoneNumber2: mandatoryFields?.inputFields.filter(x => x.fieldName === "referencePhoneNumber2")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 2 is required." };
          })
        : Joi.any(),
      email: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceEmailAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Email Address is required." };
          })
        : Joi.any(),
      locationCoordinates: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceLocationCoordinates")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Location Co-ordinates is required." };
          })
        : Joi.any(),
      monthlyIncome: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceMonthlyIncome")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Monthly Income is required." };
          })
        : Joi.any(),
      otherAssets: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceOtherAssetsValue")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Other Assets is required." };
          })
        : Joi.any(),
      liabilities: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceLiabilities")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Liabilities is required." };
          })
        : Joi.any(),
      maritalStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceMaritalStatus")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Marital Status is required." };
          })
        : Joi.any(),
      guarantorsBVN: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceBvn")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "BVN is required." };
          })
        : Joi.any(),
      occupation: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceOccupation")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Occupation is required." };
          })
        : Joi.any(),
      stateOfOrigin: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceStateOfOrigin")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "State of Origin is required." };
          })
        : Joi.any(),
      lga: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceLga")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "LGA is required." };
          })
        : Joi.any(),
      officeAddress: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceOfficeAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Office Address is required." };
          })
        : Joi.any(),
      designation: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceDesignation")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Designation is required." };
          })
        : Joi.any(),
      loanAmountGuaranteed: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanAmountGuaranteed")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Amount Guaranteed is required." };
          })
        : Joi.any(),
      employerName: mandatoryFields?.inputFields.filter(x => x.fieldName === "referenceEmployerName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Employer Name is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddIncomeDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      incomeType: mandatoryFields?.inputFields.filter(x => x.fieldName === "incomeType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Income Type is required." };
          })
        : Joi.any(),
      frequencyIncome: mandatoryFields?.inputFields.filter(x => x.fieldName === "frequencyIncome")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Income Frequency is required." };
          })
        : Joi.any(),
      amount: mandatoryFields?.inputFields.filter(x => x.fieldName === "incomeAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Income Amount is required." };
          })
        : Joi.any(),
      currency: mandatoryFields?.inputFields.filter(x => x.fieldName === "incomeCurrency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Income Currency is required." };
          })
        : Joi.any()
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddExpenseDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      expenseType: mandatoryFields?.inputFields.filter(x => x.fieldName === "expenseType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Expense Type is required." };
          })
        : Joi.any(),
      frequencyExpense: mandatoryFields?.inputFields.filter(x => x.fieldName === "frequencyExpense")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Expense Frequency is required." };
          })
        : Joi.any(),
      expenseAmount: mandatoryFields?.inputFields.filter(x => x.fieldName === "expenseAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Expense Amount is required." };
          })
        : Joi.any(),
      expenseCurrency: mandatoryFields?.inputFields.filter(x => x.fieldName === "expenseCurrency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Expense Currency is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddInternalDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      numberOfLoans: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityInternalNumberOfLoans")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Number of Loans is required." };
          })
        : Joi.any(),
      totalOutstanding: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityInternalTotalOutstanding")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Total Outstanding is required." };
          })
        : Joi.any(),
      repaymentFrequency: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityInternalRepaymentFrequency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Frequency is required." };
          })
        : Joi.any(),
      internalAmount: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityInternalRepaymentAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Amount is required." };
          })
        : Joi.any(),
      internalCurrency: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityInternalCurrency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Currency is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddExternalDetailsForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      noOfLoansExt: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityExternalNumberOfLoans")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Number of Loans is required." };
          })
        : Joi.any(),
      frequencyExt: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityExternalRepaymentFrequency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Frequency is required." };
          })
        : Joi.any(),
      repaymentAmountExt: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityExternalRepaymentAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Amount is required." };
          })
        : Joi.any(),
      totalOutstandingExt: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityExternalTotalOutstanding")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Total Outstanding is required." };
          })
        : Joi.any(),
      currencyExt: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanLiabilityExternalCurrency")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Currency is required." };
          })
        : Joi.any()
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateLoanDetailsForm(data, loanType, editLoanCategory, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      loanAmount: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanAmount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Amount is required." };
          })
        : Joi.any(),
      tenureInDays: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanTenor")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Tenor is required." };
          })
        : Joi.any(),
      interestRate: mandatoryFields?.inputFields.filter(x => x.fieldName === "interestRate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Interest Rate is required." };
          })
        : Joi.any(),
      repaymentFrequencyPrincipal: mandatoryFields?.inputFields.filter(x => x.fieldName === "repaymentFrequencyPrincipal")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Frequency (Principal) is required." };
          })
        : Joi.any(),
      repaymentFrequencyInterest: mandatoryFields?.inputFields.filter(x => x.fieldName === "repaymentFrequencyInterest")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Repayment Frequency (Interest) is required." };
          })
        : Joi.any(),
      dueDate: mandatoryFields?.inputFields.filter(x => x.fieldName === "dueDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Due Day is required." };
          })
        : Joi.any(),
      moratorium: mandatoryFields?.inputFields.filter(x => x.fieldName === "moratorium")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Moratorium is required." };
          })
        : Joi.any(),
      moratoriumPeriod: mandatoryFields?.inputFields.filter(x => x.fieldName === "moratoriumPeriod")[0]?.mandatory
        ? Joi.string().when(
          "moratorium",
          {
            is: "No",
            then: Joi.string().allow(null, ''),
            otherwise: Joi.string().required()
              .error((errors) => {
                return { message: "Moratorium Period is required." };
              }),
          })
        : Joi.any(),
      impactOnTenor: mandatoryFields?.inputFields.filter(x => x.fieldName === "impactOnTenor")[0]?.mandatory
        ? Joi.string().when(
          "moratorium",
          {
            is: "No",
            then: Joi.string().allow(null, ''),
            otherwise: Joi.string().required()
              .error((errors) => {
                return { message: "Impact On Tenor is required." };
              }),
          })
        : Joi.any(),
      chargeInterest: mandatoryFields?.inputFields.filter(x => x.fieldName === "chargeInterest")[0]?.mandatory
        ? Joi.string().when(
          "moratorium",
          {
            is: "No",
            then: Joi.string().allow(null, ''),
            otherwise: Joi.string().required()
              .error((errors) => {
                return { message: "Charge Interest during Moratorium is required." };
              }),
          })
        : Joi.any(),
      supplierName: mandatoryFields?.inputFields.filter(x => x.fieldName === "supplierName")[0]?.mandatory
        ? Joi.string().when(
          "disburseTo",
          {
            is: "Supplier",
            then: Joi.string().required()
              .error((errors) => {
                return { message: "Supplier Name is required." };
              }),
          })
        : Joi.any(),
      disburseTo: mandatoryFields?.inputFields.filter(x => x.fieldName === "disburseTo")[0]?.mandatory
        ? loanType === "Corporate" ? Joi.string().required()
          .error((errors) => {
            return { message: "Disbured To is required." };
          }) : Joi.any()
        : Joi.any(),
      loanCategory: mandatoryFields?.inputFields.filter(x => x.fieldName === "loanCategory")[0]?.mandatory
        ? editLoanCategory ? Joi.string().required()
          .error((errors) => {
            return { message: "Loan Category is required." };
          }) : Joi.any()
        : Joi.any(),
      accrual: mandatoryFields?.inputFields.filter(x => x.fieldName === "interestAccrual")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Interest Accural is required." };
          })
        : Joi.any(),
      interestRateFrequency: Joi.string().required()
        .error((errors) => {
          return { message: "Interest Rate Frequency is required." };
        })

    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddSupplierDetailsForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string().required()
        .error((errors) => {
          return { message: "Name is required." };
        }),
      bankName: Joi.string().required()
        .error((errors) => {
          return { message: "Bank Name is required." };
        }),
      accountNumber: Joi.string().required()
        .error((errors) => {
          return { message: "Account Number is required." };
        }),
      phoneNumber: Joi.string().required()
        .error((errors) => {
          return { message: "Phone Number is required." };
        }),
      address: Joi.string().required()
        .error((errors) => {
          return { message: "Address is required." };
        })
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}
export function validateAddEmployerDetailsForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string().required()
        .error((errors) => {
          return { message: "Name is required." };
        }),
      email: Joi.string().required()
        .error((errors) => {
          return { message: "Email is required." };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddNotepadForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      noteDetails: mandatoryFields?.inputFields.filter(x => x.fieldName === "noteDetails")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Note Details is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validatePersonalDetailsForm(data, localClient, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      title: mandatoryFields?.inputFields.filter(x => x.fieldName === "title")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Title is required." };
          })
        : Joi.any(),
      customerFirstName: mandatoryFields?.inputFields.filter(x => x.fieldName === "firstName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "First Name is required." };
          })
        : Joi.any(),
      customerMiddleName: mandatoryFields?.inputFields.filter(x => x.fieldName === "middleName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Middle Name is required." };
          })
        : Joi.any(),
      customerLastName: mandatoryFields?.inputFields.filter(x => x.fieldName === "lastName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Last Name is required." };
          })
        : Joi.any(),
      gender: mandatoryFields?.inputFields.filter(x => x.fieldName === "gender")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Gender is required." };
          })
        : Joi.any(),
      maritalStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "maritalStatus")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Marital Status is required." };
          })
        : Joi.any(),
      dateOfBirth: mandatoryFields?.inputFields.filter(x => x.fieldName === "dateOfBirth")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Date Of Birth is required." };
          })
        : Joi.any(),
      age: mandatoryFields?.inputFields.filter(x => x.fieldName === "age")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Age is required." };
          })
        : Joi.any(),
      motherMaidenName: mandatoryFields?.inputFields.filter(x => x.fieldName === "motherMaidenName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Mother's Maiden Name is required." };
          })
        : Joi.any(),
      numberOfDependent: mandatoryFields?.inputFields.filter(x => x.fieldName === "numberOfDependent")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Number of Dependent(s) is required." };
          })
        : Joi.any(),
      nationality: mandatoryFields?.inputFields.filter(x => x.fieldName === "nationality")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Nationality is required." };
          })
        : Joi.any(),
      educationalQualification: mandatoryFields?.inputFields.filter(x => x.fieldName === "educationalQualification")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Educational Qualification is required." };
          })
        : Joi.any(),
      accountType: mandatoryFields?.inputFields.filter(x => x.fieldName === "accountType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Type is required." };
          })
        : Joi.any(),
      accountNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "accountNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Account Number is required." };
          })
        : Joi.any(),
      ageOfAccount: mandatoryFields?.inputFields.filter(x => x.fieldName === "ageOfAccount")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Age of Account is required." };
          })
        : Joi.any(),
      domiciliationOfSalary: mandatoryFields?.inputFields.filter(x => x.fieldName === "domiciliationOfSalary")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Domiciliation of Salary is required." };
          })
        : Joi.any(),
      identificationType: mandatoryFields?.inputFields.filter(x => x.fieldName === "identificationType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Type is required." };
          })
        : Joi.any(),
      identificationNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "identificationNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Number is required." };
          })
        : Joi.any(),
      identificationExpiryDate: mandatoryFields?.inputFields.filter(x => x.fieldName === "identificationExpiryDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Expiry Date is required." };
          })
        : Joi.any(),
      tin: mandatoryFields?.inputFields.filter(x => x.fieldName === "tin")[0]?.mandatory
        ? localClient ? Joi.any()
          : Joi.string().required()
            .error((errors) => {
              return { message: "Customer TIN is required." };
            })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddStakeHolderForm(data, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      title: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderTitle")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Title is required." };
          })
        : Joi.any(),
      position: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderPosition")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Position is required." };
          })
        : Joi.any(),
      firstName: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderFirstName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "First Name is required." };
          })
        : Joi.any(),
      middleName: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderMiddleName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Middle Name is required." };
          })
        : Joi.any(),
      lastName: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderLastName")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Last Name is required." };
          })
        : Joi.any(),
      gender: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderGender")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Gender is required." };
          })
        : Joi.any(),
      dateOfBirth: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderDateOfBirth")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Date Of Birth is required." };
          })
        : Joi.any(),
      address: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Address is required." };
          })
        : Joi.any(),
      city: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "City is required." };
          })
        : Joi.any(),
      state: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderState")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "State is required." };
          })
        : Joi.any(),
      country: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderCountry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Country is required." };
          })
        : Joi.any(),
      phoneNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderPhoneNumber1")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 1 is required." };
          })
        : Joi.any(),
      phoneNumber2: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderPhoneNumber2")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Phone Number 2 is required." };
          })
        : Joi.any(),
      email: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderEmailAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Email Address is required." };
          })
        : Joi.any(),
      educationalQualification: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderEducationalQualification")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Educational Qualification is required." };
          })
        : Joi.any(),
      stakePercentage: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakePercentage")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Stake Percentage is required." };
          })
        : Joi.any(),
      lengthOfYearsInCompany: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderLengthOfTimeInCompanyInYears")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Length of Years in Company is required." };
          })
        : Joi.any(),
      monthsInCompany: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderLengthOfTimeInCompanyInMonths")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Length of Months in Company is required." };
          })
        : Joi.any(),
      idType: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderIdentificationType")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Type is required." };
          })
        : Joi.any(),
      idNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderIdentificationNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Number is required." };
          })
        : Joi.any(),
      idExpiry: mandatoryFields?.inputFields.filter(x => x.fieldName === "stakeholderIdentificationExpiryDate")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Identification Expiry Date is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateWorkDetailsForm(data, selfEmployed: boolean, otherEmployer: boolean, mandatoryFields) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      employmentIndustry: mandatoryFields?.inputFields.filter(x => x.fieldName === "employmentIndustry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Employment Industry is required." };
          })
        : Joi.any(),
      employmentDesignation: mandatoryFields?.inputFields.filter(x => x.fieldName === "employmentDesignation")[0]?.mandatory
        ? selfEmployed ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Employment Designation is required." };
          })
        : Joi.any(),
      employmentStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "employmentStatus")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Employment Status is required." };
          })
        : Joi.any(),
      employerCategory: mandatoryFields?.inputFields.filter(x => x.fieldName === "employerCategory")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Employment Category is required." };
          })
        : Joi.any(),
      businessName: mandatoryFields?.inputFields.filter(x => x.fieldName === "businessName")[0]?.mandatory
        ? !selfEmployed ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Business Name is required." };
          })
        : Joi.any(),
      businessRegistration: mandatoryFields?.inputFields.filter(x => x.fieldName === "businessRegistration")[0]?.mandatory
        ? !selfEmployed ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Business Registration is required." };
          })
        : Joi.any(),
      employerName: mandatoryFields?.inputFields.filter(x => x.fieldName === "employerName")[0]?.mandatory
        ? selfEmployed ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Employer Name is required." };
          })
        : Joi.any(),
      officePhoneNumber: mandatoryFields?.inputFields.filter(x => x.fieldName === "officePhoneNumber")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Office Phone Number is required." };
          })
        : Joi.any(),
      yearsInCurrentJob: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfYearInCurrentJob")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Years in Current Job is required." };
          })
        : Joi.any(),
      monthsInCurrentJob: mandatoryFields?.inputFields.filter(x => x.fieldName === "lengthOfMonthInCurrentJob")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Months in Current Job is required." };
          })
        : Joi.any(),
      jobAddress: mandatoryFields?.inputFields.filter(x => x.fieldName === "workAddress")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Work Address is required." };
          })
        : Joi.any(),
      jobCity: mandatoryFields?.inputFields.filter(x => x.fieldName === "workCity")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Work City/Town is required." };
          })
        : Joi.any(),
      jobCountry: mandatoryFields?.inputFields.filter(x => x.fieldName === "workCountry")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Work Country is required." };
          })
        : Joi.any(),
      jobState: mandatoryFields?.inputFields.filter(x => x.fieldName === "workState")[0]?.mandatory
        ? Joi.string().required()
          .error((errors) => {
            return { message: "Work State/Region is required." };
          })
        : Joi.any(),
      employeeStatus: mandatoryFields?.inputFields.filter(x => x.fieldName === "employeeStatus")[0]?.mandatory
        ? selfEmployed ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Employee Status is required." };
          })
        : Joi.any(),
      otherEmployerName: mandatoryFields?.inputFields.filter(x => x.fieldName === "otherEmployerName")[0]?.mandatory
        ? !otherEmployer ? Joi.string().allow(null, '') : Joi.string().required()
          .error((errors) => {
            return { message: "Other Employer Name is required." };
          })
        : Joi.any(),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateNewApplicationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      loanPurpose: Joi.string().required()
        .error((errors) => {
          return { message: "Loan Purpose is required." };
        }
        ),
      relationshipManager: Joi.string().required()
        .error((errors) => {
          return { message: "Relationship Manager is required." };
        }
        ),
      branchManager: Joi.string().required()
        .error((errors) => {
          return { message: "Branch Manager is required." };
        }
        ),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateLoanCategoryForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      Name: Joi.string().required()
        .error((errors) => {
          return { message: "Name is required." };
        }
        ),
      loanLimit: Joi.string().required()
        .error((errors) => {
          return { message: "Loan Limit is required." };
        }
        ),
      loanProductCode: Joi.string().required()
        .error((errors) => {
          return { message: "Loan Product is required." };
        }
        ),
      loanTenor: Joi.string().required()
        .error((errors) => {
          return { message: "Loan Tenor is required." };
        }
        ),
      interestRate: Joi.string().required()
        .error((errors) => {
          return { message: "Loan InterestRate is required." };
        }
        ),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateAddAssetDetailForm,
  validateAddAssetLoanSummaryDetailForm,
  validateAddPropertyDetailForm,
  validateAddPropertyLoanSummaryDetailForm,
  validateAddCollateralForm,
  validateContactDetailsForm,
  validateCorporateDetailsForm,
  validateDocumentCollectionForm,
  validateBankStatementForm,
  validateGuarantorDetailsForm,
  validateAddIncomeDetailsForm,
  validateAddExpenseDetailsForm,
  validateAddInternalDetailsForm,
  validateAddExternalDetailsForm,
  validateLoanDetailsForm,
  validateAddNotepadForm,
  validatePersonalDetailsForm,
  validateAddStakeHolderForm,
  validateWorkDetailsForm,
  validateNewApplicationForm,
  validateCreditSummaryForm,
  validateAddSupplierDetailsForm,
  validateAddEmployerDetailsForm,
  validateLoanCategoryForm
};
