import React, { Component, Fragment } from "react";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import usersService from "../../../../services/auth/usersService";
import groupLoanRequestService from "../../../../services/hybridAppraisal/groupLoanRequestService";
import hybridAppraisalValidationService from "../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import GroupDetailedDataEntry from "./editDetailedDataEntry";
// import DetailedDataEntry from "./editDetailedDataEntry";

interface NewApplicationFields {
    loanApplicationId: string;
    groupName: string;
    dateOfReceipt: string;
    groupAccountNumber: string;
    branch: string;
    loanAmount: string;
    tenureInDays: string;
    loanProduct: string;
    loanPurpose: string;
    relationshipManager: string;
    branchManager: string;
    loanChannel: string;
    loanOriginator: string;
}

class NewApplicationForm extends BaseFormComponent<NewApplicationFields> { }

interface NewApplicationProps {
    item: any;
    // showAppraisalProcess: boolean;
    // toggleAppraisalProcess: any;
    toggleLoanRequestDetails: any;
    reloadItem: any;
    systemProcess: any;
}

interface NewApplicationState {
    relationshipManagers: any;
    fetchingRequiredItems: boolean;
    showDetailedDataEntry: boolean;
    appraisalActions: any;
}

class GroupNewApplication extends Component<NewApplicationProps, IBaseFormState & NewApplicationState> {
    constructor(props: NewApplicationProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            relationshipManagers: [],
            fetchingRequiredItems: false,
            showDetailedDataEntry: false,
            appraisalActions: []
        };
    }

    async componentDidMount() {
        const { errors } = this.state;
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            // this.setState({ fetchingRequiredItems: true })
            // const response = await Promise.allSettled([
            //   usersService.retrieveAll()
            // ])
            // const users = response[0].status == "fulfilled" ? response[0].value.data : [];
            // this.setState({
            //   relationshipManagers: users,
            //   fetchingRequiredItems: false
            // });

            const response = await Promise.allSettled([
                usersService.retrieveUsersByInstitutionId(decodedToken?.["InstitutionId"])
            ])
            const institutionUsers = response[0].status == "fulfilled" ? response[0].value.data : [];
            // this.setState({
            //   relationshipManagers: users,
            //   fetchingRequiredItems: false
            // });
            this.setState({
                relationshipManagers: institutionUsers,
                fetchingRequiredItems: false
            });

            const appraisalActions = this.props.item.appraisalActions;

            if (appraisalActions) {
                const userPromises: any = [];
                for (let index = 0; index < appraisalActions.length; index++) {
                    if (appraisalActions[index].logType !== "SystemType") {
                        const userPromise = usersService.retrieveUserById(appraisalActions[index].userID);
                        userPromises.push(userPromise);
                    }
                }

                // const users = await Promise.all(userPromises);
                const users = await Promise.allSettled(userPromises);
                // console.log(users)
                let userIndex = 0;
                for (let index = 0; index < appraisalActions.length; index++) {
                    if (appraisalActions[index].logType !== "SystemType") {
                        const user = users[userIndex];
                        appraisalActions[index]["user"] = user.status == "fulfilled" ? user.value.data : null;
                        userIndex++;
                    }
                }

                this.setState({
                    appraisalActions
                })
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                errors.response = "Internal server error.";
                this.setState({ errors });
            }
        }
    }

    onFormSubmit(fields: NewApplicationFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateNewApplicationForm(fields) },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: NewApplicationFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true });
            const payload: any = { ...this.props.item };
            payload.branch = fields.branch;
            payload.loanPurpose = fields.loanPurpose;
            payload.relationshipManager = fields.relationshipManager;
            payload.branchManager = fields.branchManager;

            const response = await groupLoanRequestService.updateLoanRequest(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`New Application saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false });
            return false;
        } finally {
            return true;
        }
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [] });
    }

    toggleShowDetailedDataEntry = () => {
        this.setState({ showDetailedDataEntry: !this.state.showDetailedDataEntry });
    };

    render() {
        const { item, toggleLoanRequestDetails, reloadItem, systemProcess
        } = this.props;
        const {
            errors,
            validated,
            relationshipManagers,
            fetchingRequiredItems,
            showDetailedDataEntry
        } = this.state;


        const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails);

        let loanPurposeInputData: ISelectItems[] = [
            { name: "Personal", value: "Personal" },
            { name: "Vehicle Acquisition", value: "VehicleAcquisition" },
            { name: "Home Acquisition", value: "HomeAcquisition" },
            { name: "Business Finance", value: "BusinessFinance" }
        ];

        let loanChannelInputData: ISelectItems[] = [
            { name: "Web", value: "Web" },
            { name: "Mobile", value: "Mobile" },
        ];
        let loanOriginatorInputData: ISelectItems[] = [
            { name: "Field Agent", value: "Field Agent" },
            { name: "Staff", value: "Staff" },
            { name: "Customer", value: "Customer" }
        ];

        // let relationshipManagerInputData: ISelectItems[] = [
        //     "Samsudeen Bah (sbah@rfs.gm)",
        //     "Muhammed Sillah (msillah@rfs.gm)",
        //     "Alhagie Gaye (agaye@rfs.gm)",
        //     "Ebrima Jawo (ejawo@rfs.gm)",
        //     "Lamin Jatta (ljatta@rfs.gm)",
        //     "Anna Jobe (ajobe@rfs.gm)",
        //     "Joanna Jarju (joanna.jarjou100@yahoo.com)",
        //     "Mustapha Camara (mcamara@rfs.gm)",
        //     "Maimuna Jatta (mjatta@rfs.gm)",
        //     "Armando Gomez (agomez@rfs.gm)",
        //     "Ousman Ndure (ousman.ndure@rfs.gm)",
        //     "Sulayman Jobe (sjobe@rfs.gm)",
        //     "Saidina Sowe (saidinasowe@gmail.com)",
        //     "Muhammed Mbye (mbyemuhammed@gmail.com)",
        //     "Bully Ceesay  (bullyceesay@rfs.gm)",
        //     "Abdourahman jallow (ajallow@rfs.gm)",
        //     "Sainabou Nyang (sainabou.nyang@rfs.gm)",
        // ].map((item) => ({
        //     name: item,
        //     value: item,
        // }));

        let relationshipManagerInputData: ISelectItems[] = relationshipManagers.map((item) => ({
            name: `${item.firstName} ${item.lastName} (${item.email})`,
            value: item.id,
        }));

        return (
            <Fragment>
                {!showDetailedDataEntry ? (
                    <div className="container-fluid relative animatedParent animateOnce">
                        <div className="animated fadeInUpShort go">
                            <div className="row my-3 mx-2">
                                <h3>
                                    <b>New Application</b>
                                </h3>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <NewApplicationForm
                                        initialValues={{
                                            loanApplicationId: `${item.requestIdentifier.substr(0, 8)}_${item.id}`,
                                            groupName: item.customerName,
                                            groupAccountNumber: item.linkedAccountNo,
                                            dateOfReceipt: item.dateCreated,
                                            branch: JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).Branch,
                                            loanAmount: item.loanAmount,
                                            tenureInDays: jsonLoanDetails.LoanCycle,
                                            loanProduct: JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).Product.Name,
                                            loanPurpose: item.loanPurpose,
                                            relationshipManager: item.relationshipManager,
                                            branchManager: item.branchManager,
                                            loanChannel: item?.loanChannel ? item?.loanChannel : "Web",
                                            loanOriginator: item?.loanOriginator ? item?.loanOriginator : "Staff"
                                        }}
                                        FormComponent={({
                                            fields: {
                                                loanApplicationId,
                                                groupName,
                                                groupAccountNumber,
                                                dateOfReceipt,
                                                branch,
                                                loanAmount,
                                                tenureInDays,
                                                loanProduct,
                                                loanPurpose,
                                                relationshipManager,
                                                branchManager,
                                                loanChannel,
                                                loanOriginator
                                            },
                                            onChange,
                                            onReloadFields,
                                            onHandleSubmit,
                                        }) => (
                                            <form action="#">
                                                <div className="card no-b">
                                                    <div className="card-body">
                                                        <div className="form-row">
                                                            <div className="col-md-12">

                                                                {/* Loan Application ID and Customer/Company Name */}
                                                                <div className="form-row">
                                                                    <FormInputComponent
                                                                        label="Loan Application ID"
                                                                        id="loanApplicationId"
                                                                        name="loanApplicationId"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={loanApplicationId}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="groupName"
                                                                        name="groupName"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={groupName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                                {/* Date of Receipt and Branch */}
                                                                <div className="form-row">

                                                                    <FormInputComponent
                                                                        id="groupAccountNumber"
                                                                        name="groupAccountNumber"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={groupAccountNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="dateOfReceipt"
                                                                        name="dateOfReceipt"
                                                                        type="date"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={dateOfReceipt.split("T")[0]}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                                {/* Loan Amount and Loan Tenor */}
                                                                <div className="form-row">
                                                                    <FormInputComponent
                                                                        id="loanProduct"
                                                                        name="loanProduct"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={loanProduct}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="loanAmount"
                                                                        name="loanAmount"
                                                                        type="amount"
                                                                        amountDecimalPlaces={2}
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={loanAmount}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />

                                                                </div>
                                                                {/* Loan Channel and Loan Originator */}
                                                                <div className="form-row">
                                                                    <SelectInputComponent
                                                                        id="loanChannel"
                                                                        name="loanChannel"
                                                                        divClass={6}
                                                                        value={loanChannel}
                                                                        items={loanChannelInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <SelectInputComponent
                                                                        id="loanOriginator"
                                                                        name="loanOriginator"
                                                                        divClass={6}
                                                                        value={loanOriginator}
                                                                        items={loanOriginatorInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                                {/* Loan Product and Loan Purpose */}
                                                                <div className="form-row">
                                                                    <FormInputComponent
                                                                        label="Loan Tenor (in Days)"
                                                                        id="tenureInDays"
                                                                        name="tenureInDays"
                                                                        type="number"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={tenureInDays}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <SelectInputComponent
                                                                        id="loanPurpose"
                                                                        name="loanPurpose"
                                                                        divClass={6}
                                                                        value={loanPurpose}
                                                                        items={loanPurposeInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                </div>

                                                                {/* Relationship Manager */}
                                                                <div className="form-row">
                                                                    <SelectInputComponent
                                                                        id="relationshipManager"
                                                                        name="relationshipManager"
                                                                        divClass={6}
                                                                        value={relationshipManager}
                                                                        items={relationshipManagerInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        labelIconClass={fetchingRequiredItems === true
                                                                            ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                            : ""
                                                                        }
                                                                    />
                                                                    <SelectInputComponent
                                                                        id="branchManager"
                                                                        name="branchManager"
                                                                        divClass={6}
                                                                        value={branchManager}
                                                                        items={relationshipManagerInputData}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        labelIconClass={fetchingRequiredItems === true
                                                                            ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                            : ""
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <FormInputComponent
                                                                        id="branch"
                                                                        name="branch"
                                                                        type="text"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={branch}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary"
                                                            disabled={this.state.submitting}
                                                            onClick={(e) => {
                                                                onHandleSubmit(
                                                                    e,
                                                                    ActionTypes.SAVE,
                                                                    "New Application",
                                                                    () => {
                                                                        this.onFormSubmit(
                                                                            {
                                                                                loanApplicationId,
                                                                                groupName,
                                                                                groupAccountNumber,
                                                                                dateOfReceipt,
                                                                                branch,
                                                                                loanAmount,
                                                                                tenureInDays,
                                                                                loanProduct,
                                                                                loanPurpose,
                                                                                relationshipManager,
                                                                                branchManager,
                                                                                loanChannel,
                                                                                loanOriginator
                                                                            },
                                                                            onReloadFields
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {this.state.submitting === false ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-lg fa-save mr-3" /> Save
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                    Please wait...
                                                                </React.Fragment>
                                                            )}
                                                        </button>
                                                        <div className="float-right">
                                                            <button
                                                                className="btn btn-sm btn-outline-primary my-2 mx-2"
                                                                style={{ cursor: "pointer" }}
                                                                type="button"
                                                                onClick={() => { this.toggleShowDetailedDataEntry() }}
                                                            >
                                                                <React.Fragment>
                                                                    <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                                                    Detailed Data Entry
                                                                </React.Fragment>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    ></NewApplicationForm>
                                </div>
                            </div>
                        </div>
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        style={{
                                            // width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                        }}
                                    >
                                        <strong>Stage</strong>
                                    </th>
                                    <th
                                        scope="col"
                                        style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                        }}
                                    >
                                        <strong>Decision</strong>
                                    </th>
                                    <th
                                        scope="col"
                                        style={{
                                            // width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                        }}
                                    >
                                        <strong>Comment</strong>
                                    </th>
                                    <th
                                        scope="col"
                                        style={{
                                            // width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                        }}
                                    >
                                        <strong>User</strong>
                                    </th>
                                    <th
                                        scope="col"
                                        style={{
                                            // width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                        }}
                                    >
                                        <strong>Date</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.appraisalActions.length === 0 ? (
                                    <React.Fragment>
                                        <tr>
                                            No Decisions Made yet
                                        </tr>
                                    </React.Fragment>
                                ) : (
                                    item.appraisalActions.filter(x => x.logType === "UserType").map((action) => (
                                        <React.Fragment key={generateGUID()}>
                                            <tr>
                                                <td
                                                    style={{
                                                        // width: "5px",
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    {(action.logType === "UserType" ?
                                                        action.itemLevel === Number(-1) ? "Detailed Data Entry" :
                                                            action.itemLevel === Number(0) ? "Data Validation" :
                                                                action.itemLevel === Number(100) ? "Document Generation" :
                                                                    action.itemLevel === Number(101) ? "Post Approval Document Collection" :
                                                                        action.itemLevel === Number(102) ? "Loan Disbursement Initiation" :
                                                                            action.itemLevel === Number(103) ? "Loan Disbursement Approval" : systemProcess?.filter(x => x.id === Number(item?.financialGroup?.rules?.filter(x => x.level === action.itemLevel && x.approverType === "SystemProcess")[0]?.systemProcessID))[0]?.name
                                                        :
                                                        action.systemProcessID === Number(2) ? "Credit Policy" : action.systemProcessID === Number(5) ? "Credit Score Card" : systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name)}
                                                </td>
                                                <td
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    {action.action === "Submit" || action.action === "Processed" || action.action === "Approve" ? "Completed" : action.action}
                                                </td>
                                                <td
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    {/* {action.comment === "Done with data validation" || action.comment === "Ok to proceed" || action.comment === "Done with data entry" ? "Completed" : action.comment} */}
                                                    {action.comment}
                                                </td>
                                                <td
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    {console.log(action)}
                                                    {action.logType === "SystemType" ? "System" : action?.user?.firstName}{" "}{action?.user?.lastName}
                                                </td>
                                                <td
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    {textFormatService.formatTimeString(action.dateCreated)}
                                                </td>
                                            </tr>
                                            {/* ))} */}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <GroupDetailedDataEntry
                        loanDetails={item}
                        // showAppraisalProcess={showAppraisalProcess}
                        // toggleAppraisalProcess={toggleAppraisalProcess}
                        toggleLoanRequestDetails={toggleLoanRequestDetails}
                        reloadItem={reloadItem}
                    />

                )}
            </Fragment>
        );
    }
}

export default GroupNewApplication;
