import React, { CSSProperties, useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import _ from "lodash";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import { formatCurrency } from "../../../../../../../../services/utility/textFormatService";

export const CollateralValuationOutput = ({ loan }: any) => {
  const [collateralValuationOutput, setCollateralValuationOutput] =
    useState<any>();

  useEffect(() => {
    const getCollateralValuation = async () => {
      const response = await corporateAnalysisService.retrieveCVTOutput({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setCollateralValuationOutput(response?.data);
      }
    };
    getCollateralValuation();
  }, [loan]);

  const getWeight = (type: string) => {
    switch (type) {
      case "Residential space":
        return 15;
      case "Non-Residential space":
      case "Production space":
        return 14;
      case "Land":
        return 9;
      case "Gold":
        return 20;
      case "Vehicle":
        return 5;
      case "Equipment and machinery":
        return 4;
      case "Domestic appliances":
        return 3;
      case "Furniture":
        return 4;
      case "Antiques":
        return 4;
      case "Inventory":
        return 6;
      case "Guarantee":
        return 2;
      default:
        return 0;
    }
  };

  const collateralTypeArrRaw = [
    "Residential space",
    "Non-Residential space",
    "Production space",
    "Land",
    "Domestic appliances",
    "Equipment and machinery",
    "Vehicle",
    "Gold",
    "Furniture",
    "Antiques",
    "Inventory",
    "Guarantee",
  ];
  const assignedCollateralType: string[] =
    collateralValuationOutput?.collateralSummary?.map(
      ({ collateralType }) => collateralType
    );
  const unassignedCollateralType = [...collateralTypeArrRaw]
    .flat(2)
    ?.filter((type) => !assignedCollateralType?.includes(type))
    ?.map((item) => ({
      collateralType: item,
      count: "-",
      weight: getWeight(item),
      assignedWeights: 0,
    }));

  const collateralWeight = !_.isEmpty(collateralValuationOutput)
    ? [
        ...collateralValuationOutput?.collateralSummary
          ?.filter(({ collateralType }) => collateralType !== "Equity")
          ?.map(({ collateralType, count, assignedWeights }) => ({
            collateralType,
            count,
            weight: getWeight(collateralType),
            assignedWeights,
          })),
        ...unassignedCollateralType,
      ]
    : [];

  return (
    <div style={{ marginBottom: 20 }}>
      <Container
        title="Collateral Valuation Tool"
        body={
          <div style={{ width: "100%" }} className="clearfix">
            {!_.isEmpty(collateralValuationOutput) ? (
              <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    overflowY: "scroll",
                    border: "0.5px solid #ccc",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        {tHead(
                          [
                            "S/N",
                            "Item Name",
                            "Collateral Type",
                            "Assessed Condition",
                            "Original Value",
                            "Collateral Value (EFSV)",
                          ],
                          [
                            {},
                            {},
                            {},
                            {},
                            { textAlign: "right" },
                            { textAlign: "right" },
                          ]
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {collateralValuationOutput?.cvtData?.map(
                        (item, index) => (
                          <tr key={index}>
                            {tData(
                              [
                                index + 1,
                                item?.itemName,
                                item?.collateralType,
                                item?.assessedCondition,
                                formatCurrency(item?.originalValue, 2),
                                formatCurrency(
                                  item?.estimatedForcedSaleValue,
                                  2
                                ),
                              ],
                              [
                                {},
                                {},
                                {},
                                {},
                                { textAlign: "right" },
                                { textAlign: "right" },
                              ]
                            )}
                          </tr>
                        )
                      )}
                      <tr>
                        {tData(
                          [
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                color: "#86939e",
                                height: 20,
                              }}
                            >
                              Total
                            </div>,
                            "",
                            "",
                            "",
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                color: "#86939e",
                                height: 20,
                              }}
                            >
                              {formatCurrency(
                                collateralValuationOutput?.cvtData?.reduce(
                                  (acc, item) => acc + item?.originalValue,
                                  0
                                ),
                                2
                              )}
                            </div>,

                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                color: "#86939e",
                                height: 20,
                              }}
                            >
                              {formatCurrency(
                                collateralValuationOutput?.cvtData?.reduce(
                                  (acc, item) =>
                                    acc + item?.estimatedForcedSaleValue,
                                  0
                                ),
                                2
                              )}
                            </div>,
                          ],
                          [
                            {},
                            {},
                            {},
                            {},
                            { textAlign: "right" },
                            { textAlign: "right" },
                          ]
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 20,
                }}
                className="py-2"
              >
                <i>No Collateral Valuation Output available</i>
              </div>
            )}
          </div>
        }
      />
      <br />
      <Container
        title="Collateral Weight"
        body={
          <div style={{ width: "100%" }} className="clearfix">
            {!_.isEmpty(collateralValuationOutput) ? (
              <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    overflowY: "scroll",
                    border: "0.5px solid #ccc",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        {tHead([
                          "Collateral Type",
                          "Weight (%)",
                          "Count",
                          "Assigned Weights (%)",
                        ])}
                      </tr>
                    </thead>
                    <tbody>
                      {collateralWeight?.map((item, index) => (
                        <tr key={index}>
                          {tData([
                            item?.collateralType,
                            item?.weight,
                            item?.count,
                            item?.assignedWeights,
                          ])}
                        </tr>
                      ))}
                      <tr>
                        {tData(
                          [
                            "Total",
                            collateralWeight?.reduce(
                              (acc, curr) => acc + curr?.weight,
                              0
                            ) + " %",
                            collateralWeight
                              ?.filter(({ count }) => count !== "-")
                              ?.reduce((acc, curr) => acc + curr?.count, 0),
                            collateralWeight?.reduce(
                              (acc, curr) => acc + curr?.assignedWeights,
                              0
                            ) + " %",
                          ],
                          [
                            { fontWeight: "bold" },
                            { fontWeight: "bold" },
                            { fontWeight: "bold" },
                            { fontWeight: "bold" },
                          ]
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 20,
                }}
                className="py-2"
              >
                <i>No Collateral Type Summary available</i>
              </div>
            )}
          </div>
        }
      />
      <br />
      <Container
        title="Collateral Summary"
        body={
          <div style={{ width: "100%" }} className="clearfix">
            {!_.isEmpty(collateralValuationOutput) ? (
              <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    overflowY: "scroll",
                    border: "0.5px solid #ccc",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>{tHead(["Decription", "Value"])}</tr>
                    </thead>
                    <tbody>
                      <tr>
                        {tData([
                          "Loan Amount",
                          formatCurrency(
                            collateralValuationOutput?.loanAmount,
                            2
                          ),
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Collateral Value without Land or Equity",
                          formatCurrency(
                            collateralValuationOutput?.collateralValueWithoutLandOrEquity,
                            2
                          ),
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Total Collateral Value",
                          formatCurrency(
                            collateralValuationOutput?.totalCollateralValue,
                            2
                          ),
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Equity",
                          formatCurrency(collateralValuationOutput?.equity, 2),
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Collateral Ratio without Land or Equity",
                          formatCurrency(
                            collateralValuationOutput?.collateralRatioWithoutLandOrEquity,
                            2
                          ) + " %",
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Collateral Ratio",
                          collateralValuationOutput?.collateralRatio.toFixed(
                            2
                          ) + " %",
                        ])}
                      </tr>
                      <tr>
                        {tData([
                          "Collateral Weight",
                          collateralValuationOutput?.collateralWeight.toFixed(
                            2
                          ) + " %",
                        ])}
                      </tr>
                      <tr>
                        {tData(["Remark", collateralValuationOutput?.remark])}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 20,
                }}
                className="py-2"
              >
                <i>No Collateral Summary available</i>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

const tHead = (texts: (string | number)[], style?: CSSProperties[]) => (
  <>
    {texts?.map((text: any, index: number) => (
      <th
        key={text}
        scope="col"
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (
  texts: (string | number | React.ReactNode)[],
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <td
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: "small",
        }}
      >
        {text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
