import React, { Fragment } from "react";
import _ from "lodash";
import { Formik } from "formik";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ReactWizard from "react-bootstrap-wizard";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../services/auth/authService";
import PersonalDetails from "./wizardComponents/editDetailedDataEntry/personalDetails";
import CorporateDetails from "./wizardComponents/editDetailedDataEntry/corporateDetails";
import ContactDetails from "./wizardComponents/editDetailedDataEntry/contactDetails";
import WorkDetails from "./wizardComponents/editDetailedDataEntry/workDetails";
import GuarantorDetails from "./wizardComponents/editDetailedDataEntry/guarantorDetails";
import StakeholderDetails from "./wizardComponents/editDetailedDataEntry/stakeholderDetails";
import CollateralDetails from "./wizardComponents/editDetailedDataEntry/collateralDetails";
import DocumentCollection from "./wizardComponents/editDetailedDataEntry/documentCollection";
import Notepad from "./wizardComponents/editDetailedDataEntry/notepad";
import AssetPropertyDetails from "./wizardComponents/editDetailedDataEntry/assetPropertyDetails";
import LoanDetails from "./wizardComponents/editDetailedDataEntry/loanDetails";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import IncomeDetails from "./wizardComponents/editDetailedDataEntry/incomeDetails";
import PropertyDetails from "./wizardComponents/editDetailedDataEntry/propertyDetails";
import workFlowConfigService from "../../../../services/hybridAppraisal/workFlowConfigService";
import loanProductMapService from "../../../../services/hybridAppraisal/loanProductMapService";
import FinancialAnalysis from "./wizardComponents/editDetailedDataEntry/financialAnalysis";
import FAM from "./wizardComponents/editDetailedDataEntry/FAM";
import OtherDetails from "./wizardComponents/editDetailedDataEntry/otherDetails";
import IdVerificationDetails from "./wizardComponents/editDetailedDataEntry/idVerificationDetails";

interface IDetailedDataEntryProps {
  loanDetails: any;
  toggleLoanRequestDetails: any;
  reloadItem: any;
}

interface IDetailedDataEntryState {
  item: any;
  errors: any;
  fetchingRequiredItems: boolean;
  mandatoryFieldConfig: any;
  dataEntryTabs: any;
}

class DetailedDataEntry extends React.Component<IDetailedDataEntryProps, IDetailedDataEntryState> {
  formRef: HTMLFormElement;
  _isMounted = false;
  constructor(props: IDetailedDataEntryProps) {
    super(props);
    this.state = {
      item: {},
      errors: {},
      fetchingRequiredItems: false,
      mandatoryFieldConfig: [],
      dataEntryTabs: []
    };
  }

  static getDerivedStateFromProps(nextProps: IDetailedDataEntryProps, prevState) {
    return {
      item: nextProps.loanDetails,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    const { loanDetails } = this.props;
    try {
      this.setState({ fetchingRequiredItems: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const loanProductMap = await (await loanProductMapService.retrieveByLoanProduct(decodedToken?.["InstitutionCode"], loanDetails?.loanProduct?.id)).data
      const response = await Promise.allSettled([workFlowConfigService.retrieveById(loanProductMap[0]?.workflowId)])
      console.log(response[0])
      console.log(loanProductMap[0]?.workflowId)
      const mandatoryFieldConfig = response[0].status === "fulfilled" ? response[0].value.data?.dataEntrySections : [];
      console.log(mandatoryFieldConfig)
      const dataEntryTabs = response[0].status === "fulfilled" ? response[0].value.data?.dataEntryTabs : [];
      this.setState({
        dataEntryTabs,
        mandatoryFieldConfig,
        fetchingRequiredItems: true,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, fetchingRequiredItems: false });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { reloadItem } = this.props;
    const { item, mandatoryFieldConfig, dataEntryTabs } = this.state;

    // console.log(item) 

    const IndividualSteps = [{
      stepName: "Personal Details",
      stepValue: "PersonalDetails",
      component: PersonalDetails,
      stepProps: {
        values: this.state.item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "PersonalDetails")[0]
      },
    },
    {
      stepName: "Contact Details",
      stepValue: "ContactDetails",
      component: ContactDetails,
      stepProps: {
        values: this.state.item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "ContactDetails")[0]
      },
    },
    {
      stepName: "Address & Identity Verification Details",
      stepValue: "Verify",
      component: IdVerificationDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "Verify")[0]
      },
    },
    {
      stepName: "Work Details",
      stepValue: "WorkDetails",
      component: WorkDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "WorkDetails")[0]
      },
    },
    {
      stepName: "Reference Details",
      stepValue: "ReferenceDetails",
      component: GuarantorDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "ReferenceDetails")[0]
      },
    },
    {
      stepName: "Collateral Details",
      stepValue: "CollateralDetails",
      component: CollateralDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "CollateralDetails")[0]
      },
    },
    {
      stepName: "Document Collection",
      stepValue: "DocumentCollection",
      component: DocumentCollection,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "DocumentCollection")[0]
      },
    },
    {
      stepName: "Bank Statement",
      stepValue: "BankStatement",
      component: FinancialAnalysis,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "BankStatement")[0]
      },
    },
    // {
    //   stepName: "Notepad Details",
    //   stepValue: "NotepadDetails",
    //   component: Notepad,
    //   stepProps: {
    //     values: item,
    //     reloadItem: reloadItem,
    //     mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "NotepadDetails")[0]
    //   },
    // },
    {
      stepName: "Income Details",
      stepValue: "IncomeDetails",
      component: IncomeDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "IncomeDetails")[0]
      },
    },
    {
      stepName: "Asset Details",
      stepValue: "AssetDetails",
      component: AssetPropertyDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "AssetDetails")[0]
      },
    },
    {
      stepName: "Property Details",
      stepValue: "PropertyDetails",
      component: PropertyDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "PropertyDetails")[0]
      },
    },
    {
      stepName: "Loan Details",
      stepValue: "LoanDetails",
      component: LoanDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "LoanDetails")[0]
      },
    },
    {
      stepName: "Vendor Details",
      stepValue: "OtherDetails",
      component: OtherDetails,
      stepProps: {
        values: item,
        reloadItem: reloadItem,
        mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "OtherDetails")[0]
      },
    }
    ];

    const CorporateSteps = [
      {
        stepName: "Corporate Details",
        stepValue: "CorporateDetails",
        component: CorporateDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "CorporateDetails")[0]
        },
      },
      {
        stepName: "Contact Details",
        stepValue: "ContactDetails",
        component: ContactDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "ContactDetails")[0]
        },
      },
      {
        stepName: "Address & Identity Verification Details",
        stepValue: "Verify",
        component: IdVerificationDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "Verify")[0]
        },
      },
      {
        stepName: "Stakeholder Details",
        stepValue: "StakeholderDetails",
        component: StakeholderDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "StakeholderDetails")[0]
        },
      },
      {
        stepName: "Reference Details",
        stepValue: "ReferenceDetails",
        component: GuarantorDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "ReferenceDetails")[0]
        },
      },
      {
        stepName: "Collateral Details",
        stepValue: "CollateralDetails",
        component: CollateralDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "CollateralDetails")[0]
        },
      },
      {
        stepName: "Document Collection",
        stepValue: "DocumentCollection",
        component: DocumentCollection,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "DocumentCollection")[0]
        },
      },
      {
        stepName: "Bank Statement",
        stepValue: "BankStatement",
        component: FinancialAnalysis,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "BankStatement")[0]
        },
      },
      // {
      //   stepName: "Notepad Details",
      //   stepValue: "NotepadDetails",
      //   component: Notepad,
      //   stepProps: {
      //     values: item,
      //     reloadItem: reloadItem,
      //     mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "NotepadDetails")[0]
      //   },
      // },
      {
        stepName: "Asset Details",
        stepValue: "AssetDetails",
        component: AssetPropertyDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "AssetDetails")[0]
        },
      },
      {
        stepName: "Property Details",
        stepValue: "PropertyDetails",
        component: PropertyDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "PropertyDetails")[0]
        },
      },
      {
        stepName: "Corporate Analysis",
        stepValue: "CorporateAnalysis",
        component: FAM,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(
            (x) => x.sectionName === "CorporateAnalysis"
          )[0],
        },
      },

      {
        stepName: "Loan Details",
        stepValue: "LoanDetails",
        component: LoanDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig.filter(x => x.sectionName === "LoanDetails")[0]
        },
      },
      {
        stepName: "Vendor Details",
        stepValue: "OtherDetails",
        component: OtherDetails,
        stepProps: {
          values: item,
          reloadItem: reloadItem,
          mandatoryFields: mandatoryFieldConfig?.filter(x => x.sectionName === "OtherDetails")[0]
        },
      },
    ]

    const filterdIndividualSteps = IndividualSteps?.filter(x => dataEntryTabs?.includes(x.stepValue));
    const filteredCorporateSteps = CorporateSteps?.filter(x => dataEntryTabs?.includes(x.stepValue));
    // const filterdIndividualSteps = CorporateSteps.filter(x => [...dataEntryTabs, "OtherDetails"]?.includes(x.stepValue));

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Detailed Data Entry - {item.customerName}</b>
              </h3>


            </div>
            <div className="row">
              {this.state.dataEntryTabs.length > 0 ? (
                <div className="col-md-12">
                  <Formik
                    initialValues={{
                      id: item.id,
                      requestIdentifier: item.requestIdentifier,
                      institutionCode: item.institutionCode,
                      externalInstitutionCode: item.externalInstitutionCode,
                      description: item.description,
                      currentLevel: item.currentLevel,
                      bvn: item.bvn,
                      linkedAccountNumber: item.linkedAccountNo,
                      requestStatus: item.requestStatus,
                      obligorLimit: item.obligorLimit,
                      totalIncome: item.totalIncome,
                      productCode: item.productCode,
                      approvalDate: item.approvalDate,
                      appraisalActions: item.appraisalActions,
                      loanPurpose: item.loanPurpose,
                      relationshipManager: item.relationshipManager,
                      loanType: item.loanType,
                      loanAmount: item.loanAmount,
                      tenureInDays: item.tenureInDays,
                      branch: item.branch,
                      financialGroup: item.financialGroup,
                      dateCreated: item.dateCreated,
                      jsonData: item.jsonData,

                      // personal details
                      customerName: item.customerName,
                      customerFirstName: item.customerFirstName,
                      customerLastName: item.customerLastName,
                      customerMiddleName: item.customerMiddleName,
                      gender: item.gender,
                      maritalStatus: item.maritalStatus,
                      identificationType: item.identificationType,
                      identificationNumber: item.identificationNumber,
                      identificationExpiryDate: item.identificationExpiryDate,
                      dateOfBirth: item.dateOfBirth,
                      title: item.title,
                      educationalQualification: item.educationalQualification,
                      motherMaidenName: item.motherMaidenName,
                      age: item.age,
                      numberOfDependent: item.numberOfDependent,
                      accountType: item.accountType,
                      ageOfAccount: item.ageOfAccount,
                      domiciliationOfSalary: item.domiciliationOfSalary,
                      accountNumber: item.accountNumber,
                      nationality: item.nationality,
                      tin: item.TIN,
                      repaymentAccountCreationDate: item.repaymentAccountCreationDate,

                      // contact details
                      addressType: item.addressType,
                      propertyStatus: item.propertyStatus,
                      address: item.address,
                      city: item.city,
                      country: item.country,
                      state: item.state,
                      phonenumber: item.phonenumber,
                      phonenumber2: item.phonenumber2,
                      customerEmail: item.customerEmail,
                      yearsOfStayAtCurrentAddress: item.yearsOfStayAtCurrentAddress,
                      monthsOfStayAtCurrentAddress: item.monthsOfStayAtCurrentAddress,
                      yearsOfStayAtCurrentCity: item.yearsOfStayAtCurrentCity,
                      monthsOfStayAtCurrentCity: item.monthsOfStayAtCurrentCity,
                      twitterHandle: item.twitterHandle,
                      instagramID: item.instagramID,
                      facebookID: item.facebookID,

                      // work details
                      employmentIndustry: item.employmentIndustry,
                      employmentDesignation: item.employmentDesignation,
                      employmentStatus: item.employmentStatus,
                      employerName: item.employerName,
                      employerCategory: item.employerCategory,
                      officePhoneNumber: item.officePhoneNumber,
                      yearsInCurrentJob: item.yearsInCurrentJob,
                      monthsInCurrentJob: item.monthsInCurrentJob,
                      jobAddress: item.jobAddress,
                      jobCity: item.jobCity,
                      jobCountry: item.jobCountry,
                      jobState: item.jobState,

                      // corporate details
                      companyName: item.companyName,
                      registrationNumber: item.registrationNumber,
                      constitution: item.constitution,
                      industry: item.industry,
                      incorporationDate: item.incorporationDate,
                      contactPerson: item.contactPerson,
                      designation: item.designation,
                      lengthOfYearsInBusiness: item.lengthOfYearsInBusiness,
                      domiciliationOfBusinessProceed: item.domiciliationOfBusinessProceed,
                      typeOfProductOffered: item.typeOfProductOffered,
                      productDurability: item.productDurability,
                      threatOfCompetition: item.threatOfCompetition,
                      availabilityOfTradingStock: item.availabilityOfTradingStock,
                      complexityOfProduct: item.complexityOfProduct,
                      operationalStructure: item.operationalStructure,
                      numberOfYearsOperatingInTheSameLocation: item.numberOfYearsOperatingInTheSameLocation,
                      foreignExchangeRisk: item.foreignExchangeRisk,
                      membershipOfAnOrganizedBody: item.membershipOfAnOrganizedBody,

                      //others
                      guarantors: item.guarantors,
                      stakeholders: item.stakeholders,
                      collaterals: item.collaterals,
                      notepads: item.notepads,
                      assets: item.assets,
                      loanDetails: item.loanDetails,
                      incomeDetails: item.incomeDetails,
                      OtherDetails: item.otherDetails,

                      institution: item.institution
                    }}
                    validate={(values) => {
                      const errors = {};
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      swal({
                        title: "Confirm!",
                        text: `Are you sure you want to proceed to Save this Loan Request?`,
                        icon: "warning",
                        buttons: {
                          confirm: {
                            text: "Yes",
                            value: null,
                            visible: true,
                            className: "",
                            closeModal: true,
                          },
                          cancel: {
                            text: "Cancel",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true,
                          },
                        },
                        closeOnClickOutside: false,
                        dangerMode: false,
                      }).then(async (resp) => {
                        if (resp) {
                          return false;
                        } else {
                          try {
                            const currentUserSession = getCurrentUserSession();
                            var payload: any = {};
                            payload.loanRequestId = item.id
                            payload.action = "Submit"
                            payload.itemLevel = 0
                            payload.userID = currentUserSession.userId
                            payload.roleID = currentUserSession.userRoleId
                            payload.systemProcessID = 0
                            payload.errorMessage = ""
                            payload.logType = "UserType"
                            payload.comment = "Done with data entry"
                            payload.effectiveStatus = "Valid"
                            payload.processResponse = null
                            const response = await appraisalActionService.createAppraisalAction(payload)
                            if (response.status >= 200 && response.status <= 300) {
                              activityTrailService.saveActivityTrail({
                                actionType: "Add Appraisal Action",
                                description: `Added new Appraisal Action`,
                              });
                              toast.info(`Loan Request Details Submitted for Validation Successfully!`, {
                                autoClose: 6000,
                                type: toast.TYPE.INFO,
                                hideProgressBar: false,
                              });
                            } else {
                              return false;
                            }
                          } catch (error) {
                            toast.error(error.response.data, {
                              autoClose: 6000,
                              type: toast.TYPE.ERROR,
                              hideProgressBar: false,
                            });
                          }
                        }
                      });
                    }}
                  >

                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit} ref={(ref) => (this.formRef = ref)}>
                        {/* {console.log(this.state.fetchingRequiredItems)} */}
                        <ReactWizard
                          className="row my-3 mx-2 font-italic font-weight-bold"
                          style={{ fontSize: '20px' }}
                          steps={item.loanType === "Individual" ? (
                            filterdIndividualSteps
                          ) : (
                            filteredCorporateSteps
                          )}
                          previousButtonClasses={isSubmitting ? "hide" : ""}
                          finishButtonClasses={isSubmitting && !getCurrentUsersRoles().includes("updatedataentry") ? "hide" : ""}
                          nextButtonClasses={isSubmitting ? "hide" : ""}
                          nextButtonText="Next"
                          validate
                          finishButtonText="Save and Proceed"
                          navSteps
                          progressbar={true}
                          headerTextCenter
                          color="blue"
                          finishButtonClick={() => {
                            getCurrentUsersRoles().includes("updatedataentry") && (this.formRef.dispatchEvent(new Event("submit")));
                          }}
                        />
                      </form>
                    )}
                  </Formik>
                </div>
              ) : ("")}
            </div>
          </div>
        </div>
      </Fragment >
    );
  }
}

export default DetailedDataEntry;
