import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "../../services/utility/autoMapperService";
import cash from "../../images/icons/icons8-cash-48.png";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import feePaymentService from "../../services/billing/feePaymentService";
import InstitutionFeePayableReportDetail from "./institutionFeePayableReportDetail";
import settlementWithdrawalService from "../../services/collectionSweep/settlementWithdrawalService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import textFormatService from "../../services/utility/textFormatService";
import institutionsService from "../../services/auth/institutionsService";
import { toast } from "react-toastify";
import SettlementWithdrawalAmount from "./settlementWithdrawalAmountModal";
import reportService from "../../services/mandate/reportService";
import reportsService from "../../services/report/reportService";

export interface AppzoneCollectionsReportProps { }

export interface AppzoneCollectionsReportState
    extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
    settlementWalletItem: any
    fetchingData: boolean;
    institutionCode: string;
    submitting: boolean;
    ownUpdate: boolean;
    showModal: boolean;
}


class AppzoneCollectionsReportList extends BaseListComponent<AppzoneCollectionsReportState> { }

class AppzoneCollectionsReport extends React.Component<
    AppzoneCollectionsReportProps,
    AppzoneCollectionsReportState
> {
    constructor(props: AppzoneCollectionsReportProps) {
        super(props);
        this.state = {
            data: createFilterData([
                "institutionCode",
                "transactionDate",
                "settlementStatus",
                "customerName",
                "transactionReference",
                "mandateReference",
                "transactionStatus"
            ]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "institutionCode", fieldType: "text", value: "" },
                { name: "transactionDate", fieldType: "date", value: "" },
                {
                    name: "settlementStatus",
                    fieldType: "select",
                    options: ["All", "Success", "Pending", "Failed"],
                    value: ""
                },
                {
                    name: "transactionStatus",
                    fieldType: "select",
                    options: ["All", "Successful"],
                    value: ""
                },
                { name: "customerName", fieldType: "text", value: "" },
                { name: "transactionReference", fieldType: "text", value: "" },
                { name: "mandateReference", fieldType: "text", value: "" }

            ],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            settlementWalletItem: {},
            fetchingData: false,
            institutionCode: "",
            submitting: false,
            ownUpdate: false,
            showModal: false
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        data = typeof data === "undefined" ? this.state.data : data;
        var dataFilter = this.getFilteredItems(data)
        const currentSession = getCurrentUserSession();
        const decodedToken = decodeToken(currentSession.token || "");
        let institutionCode = decodedToken?.["InstitutionCode"]
        try {
            const reportItems = await reportsService.filterCollectionsReport(
                institutionCode,
                dataFilter,
                currentPage,
                pageSize
            );

            // console.log(reportItems.data);
            this.setState({
                tableItems: mapEntityList({
                    entityList: reportItems.data.data,
                    properties: [
                        { oldName: "id", newName: "id" },
                        { oldName: "transactionDate", newName: "transactionDate" },
                        { oldName: "customerName", newName: "customerName" },
                        { oldName: "mandateReference", newName: "mandateReference" },
                        { oldName: "loanAmount", newName: "loanAmount" },
                        { oldName: "amountAttempted", newName: "amountAttempted" },
                        { oldName: "amountDebited", newName: "amountDebited" },
                        { oldName: "feesCharged", newName: "feesCharged" },
                        { oldName: "debitedInstitution", newName: "debitedInstitution" },
                        { oldName: "debitedAccountNumber", newName: "debitedAccountNumber" },
                        { oldName: "transactionReference", newName: "transactionReference" },
                        { oldName: "settlementAmount", newName: "settlementAmount" },
                        // { oldName: "institutionCode", newName: "institutionCode" },
                        { oldName: "transactionStatus", newName: "transactionStatus" },
                        {
                            isTag: true,
                            oldName: "settlementStatus",
                            newName: "settlementStatus",
                            success: "Success",
                            danger: "Failed",
                            warning: "Pending",
                        },
                        { oldName: "loanUpdateStatus", newName: "loanUpdateStatus" },
                        { oldName: "loanUpdateStatusUpdated", newName: "loanUpdateStatusUpdated" },
                        { oldName: "isReversed", newName: "isReversed" },
                        { oldName: "reversedDate", newName: "dateReversed" },
                        { oldName: "reversalReason", newName: "reversalReason" },
                    ],
                }),
                currentPage: reportItems.data.currentPage,
                pageSize: reportItems.data.pageSize,
                totalSize: reportItems.data.totalCount,
                fetching: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItemById = async () => {
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    getFilteredItemsText = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
            settlementWalletItem,
            institutionCode,
            submitting,
            showModal
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Collections Report</b>
                            </h3>
                        </div>
                        <AppzoneCollectionsReportList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: createFilterData([
                                    "institutionCode",
                                    "transactionDate",
                                    "settlementStatus",
                                    "customerName",
                                    "transactionReference",
                                    "mandateReference",
                                    "transactionStatus"
                                ]) as IFilterData,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: [
                                    { name: "institutionCode", fieldType: "text", value: "" },
                                    { name: "transactionDate", fieldType: "date", value: "" },
                                    {
                                        name: "settlementStatus",
                                        fieldType: "select",
                                        options: ["All", "Success", "Pending", "Failed"],
                                        value: ""
                                    },
                                    {
                                        name: "transactionStatus",
                                        fieldType: "select",
                                        options: ["All", "Successful"],
                                        value: ""
                                    },
                                    { name: "customerName", fieldType: "text", value: "" },
                                    { name: "transactionReference", fieldType: "text", value: "" },
                                    { name: "mandateReference", fieldType: "text", value: "" }
                                ],
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                            }) => (
                                <>
                                </>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItemsText(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="AppzoneCollectionsReport"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                // this.fetchItemById(item);
                                                                // onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["loanAmount", "amountAttempted", "amountDebited", "feesCharged", "settlementAmount"]}
                                                            amountInNaira={true}
                                                            timeColumn={["transactionDate"]}
                                                            disableViewDetails
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></AppzoneCollectionsReportList>
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default AppzoneCollectionsReport;
