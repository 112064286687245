import moment from "moment";
import recova_logo from "../../images/recova-logo.svg";
import http from "../httpService";
import * as CryptoJS from "crypto-js";
import { IFilterData } from "../approval/approvalItemService";
import jsPDF from 'jspdf';

export function splitAtUpper(text: string): string {
  if (typeof text === "undefined") {
    return text;
  } else {
    return text
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/([A-Z])\s(?=[A-Z])/g, "$1");
  }
}

// Convert first character of every string to upper case
export function firstCharToUpper(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function generateGUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function formatCurrency(amount: number, decimalPlaces: number): string {
  // (12345.67).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
  return amount.toFixed(decimalPlaces).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function convertKoboToNaira(
  amount: number,
  decimalPlaces: number
): string {
  var _amount = amount / 100;
  return _amount.toFixed(decimalPlaces).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function getBase64StringFromFile(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      }
    };
    reader.onerror = (error) => reject(error);
  });
}

export function generateRandomRGBA(thickness: Number) {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return (
    "rgba(" +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    thickness +
    ")"
  );
}

export function getDateForTable(date: string): string {
  return moment(date).format("lll");
}

export function printPDF(document: any) {
  var mywindow = window.open("Settlements & Payouts", "PRINT", "height=500,width=900");

  // mywindow.document.write(
  //   "<link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css' integrity='sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T' crossorigin='anonymous'>"
  // );
  if (mywindow !== null) {
    console.log("final testing: ", document);
    // console.log("testing: ", mywindow?.document.getElementById("recovalogo"));
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.open(); // necessary for IE >= 10*/
    let logo = mywindow.document.getElementById("recovalogo");
    if (logo !== null) {
      logo.innerHTML = `<img src=${recova_logo} width="145" style="float: right; padding-right: 20px; padding-top: 20px" alt="" class="image" />`;
    }
    mywindow.document.write(document);
    mywindow.print();
  }
}

export function downloadPDF(documentContent: any) {
  const doc = new jsPDF();



  // Add the document content
  doc.text(documentContent, 10, 30);

  // Save the PDF
  doc.save('Guarantor_Approved_List.pdf');

  // Add the document content if you don't need to wait for the logo to load
  // doc.text(documentContent, 10, 30);
  // doc.save('Settlements_and_Payouts.pdf');
}

// export function getIpAddress(): any {
//   var response: any = http.get("http://www.geoplugin.net/json.gp");
//   console.log("response: ", response);
//   return response;
// }

export function getIpAddress(): any {
  var response: any = http.get("https://www.cloudflare.com/cdn-cgi/trace");
  // console.log("response: ", response);
  return response;
}

export function getWhileAgo(maindate: string): string {
  // var _date = maindate.split(" ")[0];
  // var _time = maindate.split(" ")[1];
  // var date = new Date(
  //   _date.split("-")[2],
  //   parseInt(_date.split("-")[1]) - 1,
  //   // _date.split("-")[1],
  //   _date.split("-")[0],
  //   _time.split(":")[0],
  //   _time.split(":")[1],
  //   _time.split(":")[2]
  // );
  // console.log("month: ", parseInt(_date.split("-")[1]) - 1);
  // console.log("original date: ", maindate);
  // console.log("date: ", date);
  var date = new Date(maindate);
  // console.log("now: ", new Date());
  // console.log("date: ", date);
  let seconds = Math.floor((+new Date() - +new Date(date)) / 1000);
  // console.log("seconds: ", seconds);
  var interval = Math.floor(seconds / 31536000);
  // console.log("interval", interval);

  if (interval > 1) {
    return interval + ` years ago [${maindate.split(",")[0]}]`;
  }
  interval = Math.floor(seconds / 2592000);
  // console.log("interval 2592000", interval);
  if (interval > 1) {
    return interval + ` months ago [${maindate.split(",")[0]}]`;
  }
  interval = Math.floor(seconds / 86400);
  // console.log("interval 86400", interval);
  if (interval > 1) {
    return interval + ` days ago [${maindate.split(",")[0]}]`;
  }
  interval = Math.floor(seconds / 3600);
  // console.log("interval 3600", interval);
  if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  // console.log("interval 60", interval);
  if (interval > 1) {
    return interval + " minutes ago";
  }
  return "just now";
  // return moment(date).fromNow();

  // return moment(date).fromNow();
  // let d = date instanceof Date ? date : new Date().parseDateTimeOffset(date);
  // return (
  //   <a title={d.toUTCString()}>
  //     <TimeAgo datetime={d.getTime()} />
  //   </a>
  // );
}

export function displayNullIfNull(item: any): string {
  if (item === null) {
    return "null";
  }
  return item;
}

export function formatTimeString(timeString: string): string {
  const newTimeString = moment(timeString).format("DD-MMM-YYYY h:mm:ss A");
  return newTimeString;
}

export function formatShortTimeString(timeString: string): string {
  const newTimeString = moment(timeString).format("DD-MMM-YYYY");
  return newTimeString;
}

export function removeLeadingZeros(s: string): string {
  return s.replace(/^0+/, "");
}

export function bin2String(array) {
  var result = "";
  for (var i = 0; i < array.length; i++) {
    result += String.fromCharCode(parseInt(array[i], 2));
  }
  return result;
}

export function cryptoAESEncrypt(text) {
  var key = CryptoJS.enc.Utf8.parse("8080808080808088");
  var iv = CryptoJS.enc.Utf8.parse("8080808080808088");

  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

// Date.prototype.parseDateTimeOffset = function (str) {
//   var o = new Date(str);
//   if (!isNaN(o)) {
//     return o;
//   } else {
//     var parts = str.split(/ [\+,\-]/);
//     var sign = / [\-]/.test(str) ? -1 : 1;
//     var dt = new Date(parts[0]);
//     if (isNaN(dt)) return dt; // If not valid end
//     if (parts.length < 2) return dt; // if there is no tmezonespec return
//     var mins = 0;
//     //calculate the diffeerence in minutes
//     var arr = parts[1].split(":");
//     arr
//       .map(
//         (x, idx) =>
//           Math.pow(60, arr.length - 1 - idx) * parseInt(x) +
//           (idx === arr.length - 1 ? parseInt(x) : 0)
//       )
//       .map((n) => {
//         mins = mins + n;
//         return n;
//       });

//     mins = mins * sign; //set the sign
//     mins += dt.getTimezoneOffset(); // correct for the offset
//     dt = new Date(dt.setTime(dt.getTime() - mins * 60 * 1000)); //apply the calculated minutes
//     return dt;
//   }
// };

function getExtensionString(currentExtension: number) {
  let extensionStrings: string[] = ["K", "M", "B", "T", "Quad", "Quint"];
  return extensionStrings[currentExtension - 1];
}

export function shortenMoney(digit: number, currentExtension: number): string {
  if (digit < 1000) {
    if (currentExtension === 0) {
      return digit.toString();
    }
    return digit.toFixed(2) + getExtensionString(currentExtension);
  } else {
    // divide
    let current: number = digit / 1000;

    //perform recursion with increased extension
    return shortenMoney(current, currentExtension + 1);
  }
}

export function formatMoney(digit: number): string {
  if (digit === undefined) {
    // console.log("digit is undefined");
    return "0";
  }
  return digit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function shortenNumber(digit: number, currentExtension: number): string {
  if (digit < 1000) {
    if (currentExtension === 0) {
      return digit.toString();
    }
    return digit.toFixed(2) + getExtensionString(currentExtension);
  } else {
    // divide
    let current: number = digit / 1000;

    //perform recursion with increased extension
    return shortenNumber(current, currentExtension + 1);
  }
}

export function setCurrentDateAsReference(): string {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  return (
    `${year}_` +
    (month < 10 ? `0${month}_` : `${month}_`) +
    (day < 10 ? `0${day}` : day)
  );
}
export function removeSpace(text: string): string {
  return text.split(" ").join("");
}

export function getAgeFromPastDate(pastDate: string): number {
  if (pastDate === null)
    return 0;
  let currentDate = new Date();
  let oldDate = new Date(pastDate)
  let age = currentDate.getFullYear() - oldDate.getFullYear()
  return age;
}

export function getAgeInMonthsFromPastDate(pastDate: string): number {
  if (pastDate === null)
    return 0;
  let currentDate = new Date();
  let oldDate = new Date(pastDate.split("T")[0])
  let yearDiff = getAgeFromPastDate(pastDate)

  let startMonth = oldDate.getMonth();
  let endMonth = currentDate.getMonth();
  let monthDiff = (yearDiff * 12) + (endMonth - startMonth);
  return monthDiff;
}

export function filterList(objList: any, data: IFilterData): any {
  let response = [...objList];
  // console.log(data);
  for (let key in data) {
    if (data[key].item2 === "") {
      continue;
    }
    if (data[key].item1 === "=") {
      response = response.filter(item => item[key].toString().toLowerCase() === data[key].item2.toLowerCase());
    } else if (data[key].item1 === "like") {
      response = response.filter(item => item[key].toString().toLowerCase().includes(data[key].item2.toLowerCase()));
    } else {
      let splitData = data[key].item2.replaceAll("'", "").split(" and ")
      response = response.filter(item => moment(item[key]).isBetween(splitData[0], splitData[1]));
      // console.log(splitData);
    }
    // console.log(key + ': ' + data[key].item1);
  }
  // console.log(response)
  return response;
}

export const downloadBlobAsFile = (myBlob: any, fileName: string) => {
  const blobUrl = URL.createObjectURL(myBlob);
  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(blobUrl);
};

export default {
  generateGUID,
  generateRandomRGBA,
  getDateForTable,
  getWhileAgo,
  formatCurrency,
  convertKoboToNaira,
  splitAtUpper,
  getBase64StringFromFile,
  removeLeadingZeros,
  displayNullIfNull,
  formatTimeString,
  printPDF,
  bin2String,
  cryptoAESEncrypt,
  shortenMoney,
  formatMoney,
  shortenNumber,
  setCurrentDateAsReference,
  removeSpace,
  formatShortTimeString,
  getAgeFromPastDate,
  getAgeInMonthsFromPastDate,
  downloadBlobAsFile
};
