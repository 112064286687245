import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import _ from "lodash";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface WorkDetailsFields {
  employmentIndustry: string;
  employmentDesignation: string;
  employmentStatus: string;
  employeeStatus: string;
  employmentNyscNumber: string;
  employerName: string;
  employerCategory: string;
  officePhoneNumber: string;
  yearsInCurrentJob: string;
  monthsInCurrentJob: string;
  jobAddress: string;
  jobCity: string;
  jobCountry: string;
  jobState: string;
  jobEmail: string;
  businessName: string;
  businessRegistration: string;
  otherEmployerName: string;
}

class WorkDetailsForm extends BaseFormComponent<WorkDetailsFields> { }

interface WorkDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface WorkDetailsState {
  jobCountry: any;
  selfEmployed: string;
  otherEmployer: boolean;
  ownUpdate: boolean;
  item: any;
}

class WorkDetails extends Component<WorkDetailsProps, IBaseFormState & WorkDetailsState> {
  constructor(props: WorkDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      jobCountry: this.props.values.jobCountry,
      selfEmployed: "",
      otherEmployer: false,
      item: {},
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: WorkDetailsProps,
    prevState: WorkDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  onFormSubmit(fields: WorkDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields } = this.props;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateWorkDetailsForm(fields, this.state.selfEmployed === "Self Employed" ? true : false, this.state.otherEmployer, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: WorkDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.employmentIndustry = fields.employmentIndustry;
      payload.employmentDesignation = fields.employmentDesignation;
      payload.employmentStatus = fields.employmentStatus;
      payload.employerName = fields.employerName === "Others" ? fields.otherEmployerName : fields.employerName;
      payload.employerCategory = fields.employerCategory;
      payload.officePhoneNumber = fields.officePhoneNumber;
      payload.yearsInCurrentJob = Number(fields.yearsInCurrentJob);
      payload.monthsInCurrentJob = Number(fields.monthsInCurrentJob);
      payload.jobAddress = fields.jobAddress;
      payload.jobCity = fields.jobCity;
      payload.jobCountry = this.state.jobCountry;
      payload.jobState = fields.jobState;
      payload.employeeStatus = fields.employeeStatus
      payload.businessName = fields.businessName;
      payload.businessRegistration = fields.businessRegistration;
      payload.employmentNyscNumber = fields.employmentNyscNumber
      payload.jobEmail = fields.jobEmail

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Work Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
      this.props.reloadItem(payload);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }


  handleEmployeeStatusChange = async (status: string) => {
    // console.log(status)
    // if (status === "SelfEmployed") {
    await this.setState({ selfEmployed: status, ownUpdate: true });
    // }
    // else {
    //   if (this.state.selfEmployed) {
    //     await this.setState({ selfEmployed: !this.state.selfEmployed, ownUpdate: true });
    //   }
    //   else {
    //     await this.setState({ selfEmployed: this.state.selfEmployed, ownUpdate: true });
    //   }

    // }
  }

  onChangeEmplyerName = async (value: string) => {
    if (value === "Others") {
      await this.setState({ otherEmployer: !this.state.otherEmployer, ownUpdate: true });
    }
    else {
      if (this.state.otherEmployer) {
        await this.setState({ otherEmployer: !this.state.otherEmployer, ownUpdate: true });
      }
      else {
        await this.setState({ otherEmployer: this.state.otherEmployer, ownUpdate: true });
      }
    }
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  render() {
    const { values } = this.props;
    const { errors, validated } = this.state;

    let statesInputData: ISelectItems[] = [];
    let countriesInputData: ISelectItems[] = [];
    countries.forEach((country) => {
      countriesInputData.push({ name: country.name, value: country.name });
    });
    countries
      .filter((c) => c.name === String(this.state.jobCountry))[0]
      ?.states.forEach((state) => {
        statesInputData.push({ name: state.name, value: state.name });
      });

    const dropdownItems = values.institution.employerListItems;
    const rawEmployerNames: any = dropdownItems == null ? [] : dropdownItems?.filter(ddItem => ddItem.fieldName == "Employer Names")[0].values;
    const processedEmpNames: string[] = [];
    rawEmployerNames.forEach(empName => {

      processedEmpNames.push(`${empName.name}=${empName.tier}`);

    });
    processedEmpNames.push("Others=Tier 2")

    let employerNames: ISelectItems[] = processedEmpNames.map(item => {
      return { name: item.split("=")[0], value: item.split("=")[1] }
    })

    // let employerNames: ISelectItems[] = [
    //   "Project aid=Tier 1",
    //   "Catholic Educations Secretariat=Tier 1",
    //   "ORYX OIL=Tier 1",
    //   "Social Security Pensions=Tier 1",
    //   "Settle Cleaning=Tier 1",
    //   "National-food security processing corporations=Tier 1",
    //   "Kanifing General Hospital=Tier 1",
    //   "Marshall Security=Tier 1",
    //   "United Nations Development Programme (UNDP)=Tier 1",
    //   "Minister of Defence=Tier 1",
    //   "Minister of Foreign Affairs=Tier 1",
    //   "Minister of Finance and Economic Affairs=Tier 1",
    //   "Minister of Tourism and Culture=Tier 1",
    //   "Minister of Higher Education, Research, Science and Technology=Tier 1",
    //   "Minister of Basic and Secondary Education=Tier 1",
    //   "Minister of Health and Social Welfare=Tier 1",
    //   "Minister of Agriculture=Tier 1",
    //   "Minister of Trade, Industry, Regional Integration and Employment=Tier 1",
    //   "Minister of Forestry, Environment, Climate Change and Natural Resources=Tier 1",
    //   "Minister of Fisheries, Water Resources and National Assembly Matters=Tier 1",
    //   "Minister of Energy and Petroleum=Tier 1",
    //   "Minister of Lands and Regional Government=Tier 1",
    //   "Minister of Justice/Attorney General=Tier 1",
    //   "Minister of Information and Communication Infrastructure=Tier 1",
    //   "Minister of the Interior=Tier 1",
    //   "Minister of Youth and Sports=Tier 1",
    //   "Minister of Transport, Works and Infrastructure=Tier 1",
    //   "Personnel Management Office (PMO)=Tier 1",
    //   "Office of the Public Service Commission (PSC)=Tier 1",
    //   "Gambia Tourism Authority (GTA)=Tier 1",
    //   "Gambia Divestiture Agency (GDA)=Tier 1",
    //   "Gambia Investment and Export Promotion Agency (GIEPA)=Tier 1",
    //   "Gambia Ports Authority (GPA)=Tier 1",
    //   "Gambia Postal Services Corporation (GAMPOST)=Tier 1",
    //   "Gambia International Airlines (GIA)=Tier 1",
    //   "Gambia Telecommunications Company Ltd.(GAMTEL)=Tier 1",
    //   "Gambia National Olympic Committee (GNOC)=Tier 1",
    //   "Gambia Civil Aviation Authority (GCAA)=Tier 1",
    //   "Gambia Bureau of Statistics (GBOS)=Tier 1",
    //   "National Planning Commission (NPC)=Tier 1",
    //   "National Environment Agency (NEA)=Tier 1",
    //   "National Assembly Services Authority=Tier 1",
    //   "National Training Authority (NTA)=Tier 1",
    //   "National Centre for Arts and Culture (NCAC)=Tier 1",
    //   "National Nutrition Agency (NaNA)=Tier 1",
    //   "Central Bank of the Gambia=Tier 1",
    //   "Public Utilities Regulatory Authority (PURA)=Tier 1",
    //   "Independent Electoral Commission (IEC)=Tier 1",
    //   "Social Security & Housing Finance Corporation (SSHFC)=Tier 1",
    //   "Others=Tier 2"
    // ].map(item => {
    //   return { name: item.split("=")[0], value: item.split("=")[1] }
    // })

    // let employerNames: ISelectItems[] = [
    //   "ACCENTURE=TIER 1",
    //   "ADDAX=TIER 1",
    //   "AKINTOLA WILLIAMS DELIOTTE=TIER 1",
    //   "ASHAKACEM PLC=TIER 1",
    //   "ASSET MANAGEMENT CORPORATION OF NIGERIA (AMCON)=TIER 1",
    //   "BANK OF INDUSTRY =TIER 1",
    //   "BRITISH AMERICAN TOBACCO COMPANY LTD=TIER 1",
    //   "BRITISH HIGH COMMISSION=TIER 1",
    //   "BUREAU FOR PUBLIC ENTERPRISE-BPE=TIER 1",
    //   "CADBURY NIGERIA PLC=TIER 1",
    //   "CENTRAL BANK OF NIGERIA-CBN=TIER 1",
    //   "CHEVRON=TIER 1",
    //   "COCA COLA INTERNATIONAL/COCA COLA NIGERIA=TIER 1",
    //   "DANGOTE GROUP=TIER 1",
    //   "DEBT MANAGEMENT OFFICE-DMO=TIER 1",
    //   "DEPARTMENT OF PETROLEUM RESOURCES (DPR)=TIER 1",
    //   "DE-UNITED=TIER 1",
    //   "DHL INTERNATIONAL=TIER 1",
    //   "ECOWAS=TIER 1",
    //   "ERNST & YOUNG=TIER 1",
    //   "FEDERAL MDAS=TIER 1",
    //   "FLOUR MILLS NIGERIA PLC=TIER 1",
    //   "FORTE OIL=TIER 1",
    //   "FRIESLAND CAMPINA WAMCO NIG.PLC.=TIER 1",
    //   "GLAXO SMITHKLINE CONSUMER NIG. PLC=TIER 1",
    //   "GUINNESS NIGERIA PLC=TIER 1",
    //   "HALLIBURTON ENERGY=TIER 1",
    //   "HP HEWLETT PACKARD=TIER 1",
    //   "INDORAMA ELEME PETROCHEMICAL  CO LTD=TIER 1",
    //   "KPMG=TIER 1",
    //   "LAFARGE WAPCO NIGERIA=TIER 1",
    //   "MASTER CARD=TIER 1",
    //   "MAY AND BAKER=TIER 1",
    //   "MICROSOFT NIGERIA=TIER 1",
    //   "MOBIL OIL NIGERIA PLC=TIER 1",
    //   "MOBIL PRODUCING UNLTD=TIER 1",
    //   "MONEYGRAM INTERNATIONAL=TIER 1",
    //   "MTN=TIER 1",
    //   "NESTLE NIGERIA PLC=TIER 1",
    //   "NIGER DELTA DEVELOPMENT COMMISSION-NDDC=TIER 1",
    //   "NIGERIA BOTTLING COMPANY PLC=TIER 1",
    //   "NIGERIA BREWERIES PLC=TIER 1",
    //   "NIGERIA INTERBANK SETTLEMENT SYSTEM-NIBSS=TIER 1",
    //   "NIGERIA NATIONAL PETROLEUM CORPORATION (NNPC AND SUBSIDIARIES)=TIER 1",
    //   "NIGERIAN DEPOSIT INSURANCE CORPORATION-NDIC=TIER 1",
    //   "NLNG AND SUBSIDIARIES=TIER 1",
    //   "OIL MAJOR NON GRATUITY=TIER 1",
    //   "PRICE-WATERHOUSE COOPERS =TIER 1",
    //   "PROMASIDOR NIGERIA LIMITED=TIER 1",
    //   "PZ GROUP=TIER 1",
    //   "RECKITT BENCKISER LTD=TIER 1",
    //   "SCHLUMBERGER NIGERIA LIMITED=TIER 1",
    //   "SECURITIES AND EXCHANGE COMMISSION-SEC=TIER 1",
    //   "SEPLAT=TIER 1",
    //   "SHELL NIGERIA GAS=TIER 1",
    //   "SHELL PETROLEUM DEVELOPMENT COMPANY (SPDC) AND SUBSIDIARIES=TIER 1",
    //   "SHELL STAFF SCHOOL=TIER 1",
    //   "STANDARD ORGANISATION OF NIGERIA -SON=TIER 1",
    //   "TOTAL E & P=TIER 1",
    //   "TOTAL NIGERIA PLC=TIER 1",
    //   "TOYOTA NIGERIA LIMITED=TIER 1",
    //   "UAC FOODS=TIER 1",
    //   "UACN PLC=TIER 1",
    //   "UACN PROPERTY DEVELOPMENT CO PLC-UPDC=TIER 1",
    //   "UNICEF=TIER 1",
    //   "UNILEVER NIG PLC=TIER 1",
    //   "UNITED NATIONS =TIER 1",
    //   "UNITED NATIONS DEVELOPMENT PROGRAMME (UNDP)=TIER 1",
    //   "UNITED STATES CONSULATE=TIER 1",
    //   "USAID=TIER 1",
    //   "VISA =TIER 1",
    //   "WEST AFRICA EXAMINATION COUNCIL-WAEC =TIER 1",
    //   "WESTERN UNION=TIER 1",
    //   "WORLD HEALTH ORGANIZATION (WHO)=TIER 1",
    //   "INTERNATIONAL BUSINESS MACHINE-IBM=TIER 1",
    //   "INTERSWITCH LIMITED=TIER 1",
    //   "PROCTER AND GAMBLE=TIER 1",
    //   "UNICEM LAFARGE=TIER 1",
    //   "ACADEMY PRESS PLC=TIER 2",
    //   "ADEKUNLE AJASIN UNIVERSITY AKUNGBA, ONDO STATE=TIER 2",
    //   "ADELEKE UNIVERSITY, EDE=TIER 2",
    //   "ADESOYE COLLEGE, KWARA STATE=TIER 2",
    //   "ADRAO INTERNATIONAL SCHOOL, LAGOS=TIER 2",
    //   "AFE BABALOLA UNIVERSITY, ADO-EKITI - EKITI STATE=TIER 2",
    //   "AIICO INSURANCE PLC=TIER 2",
    //   "AIICO PENSION FUND MANAGER=TIER 2",
    //   "AIR LIQUIDE NIGERIA PLC=TIER 2",
    //   "AJAYI CROWTHER UNIVERSITY, IBADAN=TIER 2",
    //   "AKWA-IBOM STATE GOVERNMENT=TIER 2",
    //   "AKWA-IBOM STATE UNIVERSITY OF TECHNOLOGY, UYO=TIER 2",
    //   "ALCATEL LUCENT=TIER 2",
    //   "AMERICAN INTERNATIONAL SCHOOL=TIER 2",
    //   "AMERICAN UNIVERSITY OF NIGERIA=TIER 2",
    //   "AMO GROUP=TIER 2",
    //   "ANGLICAN GIRLS GRAMMAR SCHOOL=TIER 2",
    //   "ARM PENSIONS=TIER 2",
    //   "ATLANTIC HALL EDUCATION TRUST COUNCIL AND SCHOOL, LAGOS=TIER 2",
    //   "AVI-CENNA SCHOOL LAGOS=TIER 2",
    //   "BABCOCK UNIVERSITY,ILISHAN-REMO, OGUN STATE=TIER 2",
    //   "BAKER HUGHES =TIER 2",
    //   "BDO PROFESSIONALS=TIER 2",
    //   "BELLS UNIVERSITY OF TECHNOLOGY, OTTA, OGUN STATE=TIER 2",
    //   "BENSON IDAHOSA UNIVERSITY, BENIN CITY=TIER 2",
    //   "BETA GLASS COMPANY PLC=TIER 2",
    //   "BISHOP AJAYI CROWTHER UNIVERSITY OYO=TIER 2",
    //   "BOWEN UNIVERSITY, IWO, OGUN STATE=TIER 2",
    //   "BRITISH INTERNATIONAL SCHOOL, LAGOS =TIER 2",
    //   "BROOKSTONE SCHOOLS, PORTHARCOURT=TIER 2",
    //   "BUA GROUP=TIER 2",
    //   "CALEB SECONDARY SCHOOL LAGOS=TIER 2",
    //   "CALEB UNIVERSITY, LAGOS=TIER 2",
    //   "CAPL PLC=TIER 2",
    //   "CEMENT COMPANY OF NOTHERN NIGERIA PLC=TIER 2",
    //   "CENTRAL SECURITIES CLEARING SYSTEM (CSCS)=TIER 2",
    //   "CFAO NIGERIA PLC=TIER 2",
    //   "CHAMPION BREWERIES=TIER 2",
    //   "CHARTERED INSTITUTE OF BANKERS OF NIGERIA (CIBN)=TIER 2",
    //   "CHELLARAMS PLC =TIER 2",
    //   "CHI NIGERIA LIMITED=TIER 2",
    //   "CHILDREN'S INTERNATIONAL SCHOOL, LAGOS=TIER 2",
    //   "CHRISTLAND COLLEGE, LAGOS=TIER 2",
    //   "COMPUTER WAREHOUSE GROUP=TIER 2",
    //   "CORNERSTONE INSURANCE PLC=TIER 2",
    //   "CORONA SECONDARY SCHOOL=TIER 2",
    //   "COVENANT UNIVERSITY OTA, OGUN STATE=TIER 2",
    //   "CRAWFORD UNIVERSITY IGBESA, OGUN STATE=TIER 2",
    //   "CRC CREDIT BUREAU LIMITED=TIER 2",
    //   "CRESCENT UNIVERSITY, OGUN STATE=TIER 2",
    //   "CROSS RIVER STATE GOVERNMENT=TIER 2",
    //   "CROSS RIVER STATE UNIVERSITY OF SCIENCE &TECHNOLOGY, CALABAR (TIER 2)",
    //   "CRUSADER STERLING AND PENSIONS=TIER 2",
    //   "CUMMINS WEST AFRICA LIMITED=TIER 2",
    //   "CWAY LIMITED=TIER 2",
    //   "DAFINONE & CO=TIER 2",
    //   "DAY WATERMAN COLLEGE, OGUN STATE=TIER 2",
    //   "DOWEN COLLEGE, LAGOS=TIER 2",
    //   "DULFIL PRIMA=TIER 2",
    //   "ECOLE FRANCAISE MARCEL PAGNOLD D AB=TIER 2",
    //   "EKO HOTEL =TIER 2",
    //   "EMERALD SCHOOL, OGUN STATE=TIER 2",
    //   "EMZOR PHARMACEUTICAL INDUSTRIES LTD=TIER 2",
    //   "FAN MILK PLC =TIER 2",
    //   "FIDSON HEALTHCARE=TIER 2",
    //   "FIRST BANK OF NIGERIA LIMITED=TIER 2",
    //   "FIRST REGISTRARS & INVESTOR SERVICES =TIER 2",
    //   "FIRST TRUSTEES=TIER 2",
    //   "FOUNTAIN UNVERISTY, OSHOGBO,OSUN STATE=TIER 2",
    //   "GASLINK=TIER 2",
    //   "GENERAL ELECTRIC NP NIGERIA LTD=TIER 2",
    //   "GM NIGERIA LTD=TIER 2",
    //   "GRAND CEREALS & OIL MILLS LTD=TIER 2",
    //   "GRANGE EDUCATION LIMITED, LAGOS=TIER 2",
    //   "GREENSPRINGS SCHOOL, LAGOS=TIER 2",
    //   "GRUNDTVIG INTERNATIONAL SECONDARY SCHOOL=TIER 2",
    //   "HLB  Z.O OSOSANYA AND CO.=TIER 2",
    //   "HOME SCIENCE ASSOCIATION SECONDARY SCHOOL, LAGOS=TIER 2",
    //   "HONEYWELL GROUP LTD=TIER 2",
    //   "HUAWEI TECHNOLOGIES CO. NIGERIA LTD=TIER 2",
    //   "HYUNDAI HEAVY INDUSTRIES LTD=TIER 2",
    //   "HYUNDAI MOTORS LTD=TIER 2",
    //   "IBFC ALLIANCE=TIER 2",
    //   "IGBINEDION EDUCATIONAL CENTRE, EDO STATE=TIER 2",
    //   "IGBINEDION UNIVERSITY OKADA, EDO STATE=TIER 2",
    //   "IGNATIUS AJURU UNIVERSITY OF EDUCATION,RUMUOLUMENI PORTHARCOURT (TIER 2)",
    //   "INSTITUTE OF CHARTERED ACCOUNTANTS OF NIGERIA (ICAN)=TIER 2",
    //   "INTELS NIGERIA LIMITED=TIER 2",
    //   "INTERNATIONAL BREWERIES LTD=TIER 2",
    //   "INTERNATIONAL INSTITUTE OF TROPICAL AGRICULTURE-IITA IBADAN=TIER 2",
    //   "JESUIT MEMORIAL COLLEGE PORT HARCOURT=TIER 2",
    //   "JOHNSON WAX NIG LIMITED=TIER 2",
    //   "KADUNA STATE GOVERNMENT=TIER 2",
    //   "KADUNA STATE UNIVERSITY, KADUNA (TIER 2)",
    //   "KLM ROYAL DUTCH AIRLINES=TIER 2",
    //   "LAGOON SECONDARY SCHOOL, LEKKI LAGOS=TIER 2",
    //   "LAGOS BUSINESS SCHOOL/PAN-ATLANTIC UNIVERSITY, LAGOS=TIER 2",
    //   "LAGOS PREPARATORY SCHOOL=TIER 2",
    //   "LANDMARK UNIVERSITY OMU ARAN, KWARA STATE=TIER 2",
    //   "LASACO ASSURANCE =TIER 2",
    //   "LAW REFORM=TIER 2",
    //   "MANDILAS GROUP LIMITED=TIER 2",
    //   "MANTRAC LIMITED=TIER 2",
    //   "MEADOW HALL SCHOOL, LAGOS=TIER 2",
    //   "MRS OIL NIGERIA PLC=TIER 2",
    //   "MULTICHOICE NIGERIA LIMITED=TIER 2",
    //   "MUTUAL BENEFIT ASSURANCE=TIER 2",
    //   "NCR CORPORATION=TIER 2",
    //   "NIGERDOCK PLC=TIER 2",
    //   "NOKIA SIEMENS NETWORK LIMITED=TIER 2",
    //   "OLAM NIGERIA LTD=TIER 2",
    //   "OLASHORE INTERNATIONAL HIGH SCHOOL, ILOKO-IJESA OSUN STATE=TIER 2",
    //   "PFIZER SPECIALIST LIMITED=TIER 2",
    //   "PHILLIPS CONSULTING NIGERIA=TIER 2",
    //   "PKF=TIER 2",
    //   "PORTLAND PAINTS AND PRODUCTS PLC=TIER 2",
    //   "PREMIER FEED MILLS CO.LTD. =TIER 2",
    //   "PRESCO OIL NIGERIA PLC=TIER 2",
    //   "PUNCH NIGERIA LIMITED=TIER 2",
    //   "REDEEMER'S UNIVERSITY, EDE, OSUN STATE=TIER 3",
    //   "RESOUCERY NIGERIA=TIER 3",
    //   "RIVER STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY=TIER 3",
    //   "RIVERS STATE GOVERNMENT=TIER 3",
    //   "ROYAL EXCHANGE ASSURANCE  PLC=TIER 3",
    //   "SAB MILLER=TIER 3",
    //   "SCHNEIDER ELECTRIC NIGERIA LIMITED=TIER 3",
    //   "SEVEN UP BOTTLING COMPANY PLC=TIER 3",
    //   "SEVENTHDAY ADVENTIST SCHOOLS/HOSPITALS=TIER 3",
    //   "SIAO PARTNERS=TIER 3",
    //   "SOVEREIGN TRUST INSURANCE PLC=TIER 3",
    //   "ST NICHOLAS=TIER 3",
    //   "ST. GREGORYS COLLEGE, LAGOS=TIER 3",
    //   "ST. SAVIOURS SCHOOL IKOYI, LAGOS=TIER 3",
    //   "STACO INSURANCE PLC=TIER 3",
    //   "SUMAL FOODS=TIER 3",
    //   "TEMPLE SCHOOL, LAGOS =TIER 3",
    //   "TRINITY INTERNATIONAL COLLEGE, OFADA, OGUN STATE=TIER 3",
    //   "TRUSTFUND PENSIONS=TIER 3",
    //   "VANGUARD=TIER 3",
    //   "VITAFOAM NIGERIA PLC =TIER 3",
    //   "VIVIAN FOWLER MEMORIAL COLLEGE, LAGOS=TIER 3",
    //   "WARM SPRINGS WATERS LTD=TIER 3",
    //   "WEST AFRICAN BOOK PUB. LTD=TIER 3",
    //   "WEST AFRICAN INSTITUTE FOR FINANCIAL AND ECONOMIC MANAGEMENT-WAIFEM=TIER 3",
    //   "WHITESANDS SCHOOL LEKKI=TIER 3",
    //   "ORACLE CORPORATION =TIER 3",
    //   "C AND I LEASING PLC=TIER 3",
    //   "HOLYCHILD COLLEGE=TIER 3",
    //   "SUNGLASS NIGERIA LTD=TIER 3",
    //   "SOCIETY FOR FAMILY HEALTH-NGO=TIER 3",
    //   "CEMENT COMPANY OF NORTHERN NIGERIA PLC-CCNN=TIER 3",
    // ].map(item => {
    //   return {name: item.split("=")[0], value: item.split("=")[1]}
    // })

    const employerNameInputData: ISelectItems[] = employerNames.map(item => {
      return { name: item.name, value: item.name }
    })

    let employmentIndustryInputData: ISelectItems[] = [
      { name: "Arts, Entertainment, and Recreation", value: "ArtsEntertainmentandRecreation" },
      { name: "Construction", value: "Construction" },
      { name: "Educational Services", value: "EducationalServices" },
      { name: "Finance and Insurance", value: "FinanceandInsurance" },
      { name: "Food & Beverage", value: "Food&Beverage" },
      { name: "General Merchandise Stores", value: "GeneralMerchandiseStores" },
      { name: "Health Care and Social Assistance", value: "HealthCareandSocialAssistance" },
      { name: "Information Technology", value: "InformationTechnology" },
      { name: "Leisure and Hospitality", value: "LeisureandHospitality" },
      { name: "Manufacturing", value: "Manufacturing" },
      { name: "Mining", value: "Mining" },
      { name: "Oil & Gas", value: "Oil&Gas" },
      { name: "Real Estate", value: "RealEstate" },
      { name: "Religious, Grantmaking, Civic, Professional, and Similar Organizations", value: "ReligiousGrantmakingCivicProfessionalandSimilarOrganizations" },
      { name: "Rental and Leasing Services", value: "RentalandLeasingServices" },
      { name: "Retail Trade", value: "RetailTrade" },
      { name: "Service-Providing Industries", value: "Service-ProvidingIndustries" },
      { name: "Telecommunications", value: "Telecommunications" },
      { name: "Utilities", value: "Utilities" },
      { name: "Warehousing and Storage", value: "WarehousingandStorage" },
      { name: "Waste Management and Remediation Services", value: "WasteManagementandRemediationServices" },
      { name: "Wholesale Trade", value: "WholesaleTrade" },
      { name: "Transportation", value: "Transportation" },
      { name: "Textiles", value: "Textiles" },
    ];
    let employmentDesignationInputData: ISelectItems[] = [
      { name: "Executive Management", value: "Executive Management" },
      { name: "Senior Management", value: "Senior Management" },
      { name: "Middle Management", value: "Middle Management" },
      { name: "Junior Staff", value: "Junior Staff" },
      { name: "Others", value: "Others" }
    ];

    let employmentStatusInputData: ISelectItems[] = [
      { name: "Employed", value: "Employed" },
      { name: "Self Employed", value: "Self Employed" },
      { name: "Corper", value: "Corper" },
      // { name: "Unemployed", value: "Unemployed" }
    ];

    let employeeStatusInputData: ISelectItems[] = [
      { name: "Permanent", value: "Permanent" },
      { name: "Contract", value: "Contract" },
      // { name: "Unemployed", value: "Unemployed" }
    ];
    let businessRegistrationInputData: ISelectItems[] = [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" }
    ];

    return (
      <WorkDetailsForm
        initialValues={{
          employmentIndustry: values.employmentIndustry,
          employmentDesignation: values.employmentDesignation,
          employmentStatus: values.employmentStatus,
          employmentNyscNumber: values.employmentNyscNumber,
          employerName: values.employerName,
          employerCategory: values.employerCategory,
          officePhoneNumber: values.officePhoneNumber,
          yearsInCurrentJob: values.yearsInCurrentJob.toString(),
          monthsInCurrentJob: values.monthsInCurrentJob.toString(),
          jobAddress: values.jobAddress,
          jobCity: values.jobCity,
          jobCountry: values.jobCountry,
          jobState: values.jobState,
          employeeStatus: values.employeeStatus,
          jobEmail: values.jobEmail,
          businessName: "",
          businessRegistration: "",
          otherEmployerName: "",
        }}
        FormComponent={({
          fields: {
            employmentIndustry,
            employmentDesignation,
            employmentStatus,
            employmentNyscNumber,
            employerName,
            employerCategory,
            officePhoneNumber,
            yearsInCurrentJob,
            monthsInCurrentJob,
            jobAddress,
            jobCity,
            jobCountry,
            jobState,
            jobEmail,
            employeeStatus,
            businessName,
            businessRegistration,
            otherEmployerName
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <Fragment>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-row">
                  <SelectInputComponent
                    id="employmentIndustry"
                    name="employmentIndustry"
                    divClass={6}
                    value={employmentIndustry}
                    items={employmentIndustryInputData}
                    required={this.getRequiredFields("employmentIndustry")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="employmentDesignation"
                    name="employmentDesignation"
                    divClass={6}
                    value={employmentDesignation}
                    items={employmentDesignationInputData}
                    required={this.getRequiredFields("employmentDesignation")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled={this.state.selfEmployed === "Self Employed" ? true : false}
                  />
                  <SelectInputComponent
                    id="employmentStatus"
                    name="employmentStatus"
                    divClass={6}
                    value={employmentStatus}
                    items={employmentStatusInputData}
                    required={this.getRequiredFields("employmentStatus")}
                    validated={validated}
                    errors={errors}
                    onChange={async (id, value) => {
                      await onChange(id, value);
                      await this.handleEmployeeStatusChange(value)
                    }}
                  />
                  <FormInputComponent
                    id="businessName"
                    name="businessName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={businessName}
                    required={this.getRequiredFields("businessName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled={this.state.selfEmployed === "Self Employed" ? false : true}
                  />
                  <SelectInputComponent
                    id="businessRegistration"
                    name="businessRegistration"
                    divClass={6}
                    value={businessRegistration}
                    items={businessRegistrationInputData}
                    required={this.getRequiredFields("businessRegistration")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled={this.state.selfEmployed === "Self Employed" ? false : true}
                  />
                  <SelectInputComponent
                    id="employeeStatus"
                    name="employeeStatus"
                    divClass={6}
                    value={employeeStatus}
                    items={employeeStatusInputData}
                    required={this.getRequiredFields("employeeStatus")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled={this.state.selfEmployed === "Self Employed" ? true : false}
                  />
                  <FormInputComponent
                    label="Employer / Nysc Number"
                    id="employmentNyscNumber"
                    name="employmentNyscNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={employmentNyscNumber}
                    required={this.getRequiredFields("employmentNyscNumber")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="employerName"
                    name="employerName"
                    divClass={6}
                    value={employerName}
                    items={employerNameInputData}
                    required={this.getRequiredFields("employerName")}
                    validated={validated}
                    errors={errors}
                    onChange={async (id, value) => {
                      await onChange(id, value);
                      await onChange("employerCategory", employerNames.filter(nameObj => nameObj.name == value)[0].value)
                      await this.onChangeEmplyerName(value)
                    }}
                    disabled={this.state.selfEmployed === "Self Employed" ? true : false}
                  />
                  {this.state.otherEmployer ? (
                    <FormInputComponent
                      label="Employer Name"
                      id="otherEmployerName"
                      name="otherEmployerName"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={otherEmployerName}
                      required={this.getRequiredFields("otherEmployerName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    // disabled={!this.state.selfEmployed}
                    />
                  ) : ("")}
                  <FormInputComponent
                    id="employerCategory"
                    name="employerCategory"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={employerCategory}
                    required={this.getRequiredFields("employerCategory")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled
                  />
                  <FormInputComponent
                    id="officePhoneNumber"
                    name="officePhoneNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={officePhoneNumber}
                    required={this.getRequiredFields("officePhoneNumber")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <div className="col-md-6">
                    <div className="form-row">
                      <FormInputComponent
                        label="Length of Year(s) in Current Job/Business                        "
                        id="yearsInCurrentJob"
                        name="yearsInCurrentJob"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={yearsInCurrentJob}
                        required={this.getRequiredFields("lengthOfYearInCurrentJob")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                      <FormInputComponent
                        label="Length of Month(s) in Current Job/Business                        "
                        id="monthsInCurrentJob"
                        name="monthsInCurrentJob"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={monthsInCurrentJob}
                        required={this.getRequiredFields("lengthOfMonthInCurrentJob")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <FormInputComponent
                    label="Work Address"
                    id="jobAddress"
                    name="jobAddress"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={jobAddress}
                    required={this.getRequiredFields("workAddress")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Work City/Town"
                    id="jobCity"
                    name="jobCity"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={jobCity}
                    required={this.getRequiredFields("workCity")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />

                  <FormInputComponent
                    label="Work Email"
                    id="jobEmail"
                    name="jobEmail"
                    type="email"
                    placeholder=""
                    divClass={6}
                    value={jobEmail}
                    required={this.getRequiredFields("jobEmail")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Work Country"
                    id="jobCountry"
                    name="jobCountry"
                    divClass={6}
                    value={this.state.jobCountry}
                    items={countriesInputData}
                    required={this.getRequiredFields("workCountry")}
                    validated={validated}
                    errors={errors}
                    onChange={async (id, value) => {
                      await onChange(id, value);
                      this.setState({ jobCountry: value, ownUpdate: true });
                    }}
                  />
                  <SelectInputComponent
                    label="Work State/Region"
                    id="jobState"
                    name="jobState"
                    divClass={6}
                    value={jobState}
                    items={statesInputData}
                    required={this.getRequiredFields("workState")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="float-right">
              {(getCurrentUsersRoles().includes("updatedataentry") && (
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={this.state.submitting}
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Work Details",
                      () => {
                        this.onFormSubmit(
                          {
                            employmentIndustry,
                            employmentDesignation,
                            employmentStatus,
                            employeeStatus,
                            employmentNyscNumber,
                            employerName,
                            employerCategory,
                            officePhoneNumber,
                            yearsInCurrentJob,
                            monthsInCurrentJob,
                            jobAddress,
                            jobCity,
                            jobCountry,
                            jobState,
                            jobEmail,
                            businessName,
                            businessRegistration,
                            otherEmployerName
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                >
                  {this.state.submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                      Please wait...
                    </React.Fragment>
                  )}
                </button>
              ))}
            </div>
          </Fragment>
        )}
      ></WorkDetailsForm>
    );
  }
}

export default WorkDetails;