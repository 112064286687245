import http from "../httpService";
import axios from "axios";
import {
  getCurrentUserSession,
  retrieveCurrentUsersIpAddress,
} from "./authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface ISaveRegion {
  name: string;
  description: string;
  code: string;
  address: string;
  institutionId: number;
  assignedBranches: number[];
  clientIPAddress: string;
}

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export async function saveRegion(region: ISaveRegion) {
  region.clientIPAddress = String(retrieveCurrentUsersIpAddress());
  var url = authBaseAddress + `region`;
  return http.post(url, region, {
    url: url,
    method: "post",
    data: region,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function updateRegion(region: any, regionId: number) {
  region.clientIPAddress = retrieveCurrentUsersIpAddress();
  var url = authBaseAddress + `region/${regionId}`;
  return http.post(url, region, {
    url: url,
    method: "post",
    data: region,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRegionById(regionId: number) {
  var url = authBaseAddress + `region/${regionId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveAllRegions() {
  var url = authBaseAddress + `region/GetAll`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRegionByCode(code: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    authBaseAddress +
    `region/GetByCode?institutionId=${decodedToken?.["InstitutionId"]}&Code=${code}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function getAccessibleBranchCodes() {
  var url = authBaseAddress + `region/GetAccessibleBranchCodes`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function enableRegion(id: number) {
  var url = authBaseAddress + `region/Enable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export async function disableRegion(id: number) {
  var url = authBaseAddress + `region/Disable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function filterRegions(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url =
    authBaseAddress +
    `region/FilterRegions?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function downloadReport(type: string) {
  var url = authBaseAddress + `region/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Regions.${type}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = authBaseAddress + `region/DownloadReportPDF`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  saveRegion,
  updateRegion,
  retrieveRegionById,
  retrieveAllRegions,
  retrieveRegionByCode,
  getAccessibleBranchCodes,
  enableRegion,
  disableRegion,
  filterRegions,
  downloadReport,
  downloadReportPDF,
};
