import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import _ from "lodash";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getAgeInMonthsFromPastDate } from "../../../../../../services/utility/textFormatService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import DetailItem from "../../../../../../components/detailItem";

interface CorporateDetailsFields {
  companyName: string;
  registrationNumber: string;
  constitution: string;
  industry: string;
  incorporationDate: string;
  contactPerson: string;
  designation: string;
  lengthOfYearsInBusiness: string;
  accountType: string;
  accountNumber: string;
  ageOfAccount: string;
  domiciliationOfBusinessProceeds: string;
  typeOfProductOffered: string;
  productDurability: string;
  threatOfCompetition: string;
  availabilityOfTradingStock: string;
  complexityOfProduct: string;
  operationalStructure: string;
  numberOfYearsOperatingInTheSameLocation: string;
  foreignExchangeRisk: string;
  membershipOfAnOrganizedBody: string;
  monthsInCurrentJob: string;
  customerTin: string;
  phonenumber: string;
  phonenumber2: string;
  customerEmail: string;
  employeeCount: string;
}

class CorporateDetailsForm extends BaseFormComponent<CorporateDetailsFields> { }

interface CorporateDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface CorporateDetailsState {
  ownUpdate: boolean;
  item: any;
}

class CorporateDetails extends Component<CorporateDetailsProps, IBaseFormState & CorporateDetailsState> {
  constructor(props: CorporateDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      item: {},
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: CorporateDetailsProps,
    prevState: CorporateDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  onFormSubmit(fields: CorporateDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields } = this.props;
    console.log(mandatoryFields)
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateCorporateDetailsForm(fields, mandatoryFields), ownUpdate: true },
        () => {
          console.log(this.state.errors)
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: CorporateDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.companyName = fields.companyName;
      payload.registrationNumber = fields.registrationNumber;
      payload.constitution = fields.constitution;
      payload.industry = fields.industry;
      payload.incorporationDate = fields.incorporationDate;
      payload.contactPerson = fields.contactPerson;
      payload.designation = fields.designation;
      payload.lengthOfYearsInBusiness = Number(fields.lengthOfYearsInBusiness);
      payload.accountType = fields.accountType;
      payload.accountNumber = fields.accountNumber;
      payload.ageOfAccount = Number(fields.ageOfAccount);
      payload.domiciliationOfBusinessProceeds = fields.domiciliationOfBusinessProceeds;
      payload.typeOfProductOffered = fields.typeOfProductOffered;
      payload.productDurability = fields.productDurability;
      payload.threatOfCompetition = fields.threatOfCompetition;
      payload.availabilityOfTradingStock = fields.availabilityOfTradingStock;
      payload.complexityOfProduct = fields.complexityOfProduct;
      payload.operationalStructure = fields.operationalStructure;
      payload.phonenumber = fields.phonenumber;
      payload.phonenumber2 = fields.phonenumber2;
      payload.customerEmail = fields.customerEmail;
      payload.numberOfYearsOperatingInTheSameLocation = fields.numberOfYearsOperatingInTheSameLocation;
      payload.foreignExchangeRisk = fields.foreignExchangeRisk;
      payload.membershipOfAnOrganizedBody = fields.membershipOfAnOrganizedBody;
      payload.monthsInCurrentJob = fields.monthsInCurrentJob;
      payload.tin = fields.customerTin
      payload.employeeCount = fields.employeeCount
      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Corporate Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated } = this.state;

    let industryInputData: ISelectItems[] = [
      "Oil & Gas",
      "ICT",
      "Manufacturing",
      "Construction",
      "Distributive Trade - FMCG (Non-Perishable)",
      "Retail Trade",
      "Agriculture",
      "Real Estate",
      "Transportation & Storage",
      "Finance & Insurance",
      "Services/Hospitality/Management Consultants",
      "Education / Professional bodies",
      "Health / Medicare",
      "Textiles",
      "Others"].map(item => { return { name: item, value: item } });

    // const dropdownItems = values.institution.dropDownItems;
    // const accountTypes: any = dropdownItems == null ? [] : dropdownItems.filter(ddItem => ddItem.fieldName == "Account Types")[0].values;
    // let accountTypeInputData: ISelectItems[] = accountTypes.map((item) => ({
    //   name: `${item.value}`,
    //   value: `${item.value}`
    // }));

    let accountTypeInputData: ISelectItems[] = [
      { name: "Savings", value: "Savings" },
      { name: "Current", value: "Current" },
      { name: "Jula", value: "Jula" },
      { name: "Premium", value: "Premium" },
      { name: "Enterprise", value: "Enterprise" },
    ];

    let domiciliationOfBusinessProceedsInputData: ISelectItems[] = [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" }
    ];

    let typeOfProductOfferedInputData: ISelectItems[] = [
      { name: "Slow Moving Product or Service", value: "Slow moving Product or Service" },
      { name: "Luxury Product or Service", value: "Luxury Product or Service" },
      { name: "Fast Moving Product or Service", value: "Fast moving Product or Service" }
    ]

    let productDurabilityInputData: ISelectItems[] = [
      { name: "Perishable Goods", value: "Perishable Goods" },
      { name: "Non-Perishable Goods", value: "Non-Perishable Goods" },
      { name: "Not Applicable", value: "NotApplicable" }
    ]
    let threatOfCompetitionInputData: ISelectItems[] = [
      { name: "Hostile", value: "Hostile" },
      { name: "Aggressive", value: "Aggressive" },
      { name: "Unthreatening", value: "Unthreatening" },
      { name: "No Competition", value: "No Competition" }
    ]

    let availabilityOfTradingStockInputData: ISelectItems[] = [
      { name: "Seasonal", value: "Seasonal" },
      { name: "All Year", value: "All Year" },
      { name: "Other", value: "Other" }
    ]

    let complexityOfProductInputData: ISelectItems[] = [
      { name: "High", value: "High" },
      { name: "Medium", value: "Medium" },
      { name: "Low", value: "Low" },
      { name: "None", value: "None" }
    ]

    let operationalStructureInputData: ISelectItems[] = [
      { name: "Highly Regulated", value: "Highly Regulated" },
      { name: "Moderately Regulated", value: "Moderately Regulated" },
      { name: "Low Regulated", value: "Low Regulation" }
    ]
    let constitutionInputData: ISelectItems[] = [
      { name: "Sole Proprietor", value: "Sole Proprietor" },
      { name: "Partnership", value: "Partnership" },
      { name: "Limited Liability Company", value: "Limited Liability Company" },
      { name: "Unlimited Liability Company", value: "Unlimited Liability Company" }
    ]

    let numberOfYearsOperatingInTheSameLocationInputData: ISelectItems[] = [
      { name: "< 1 year", value: "<1year" },
      { name: "1 - 3 years", value: "1-3years" },
      { name: "3 - 5 years", value: "3-5years" },
      { name: "> 5 years", value: ">5years" }
    ]

    let foreignExchangeRiskInputData: ISelectItems[] = [
      { name: "Low", value: "Low" },
      { name: "Medium", value: "Medium" },
      { name: "High", value: "High" },
      { name: "Not Applicable", value: "Not Applicable" }
    ]

    let membershipOfAnOrganizedBodyInputData: ISelectItems[] = [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" }
    ]

    return (
      <CorporateDetailsForm
        initialValues={{
          companyName: values.customerName,
          registrationNumber: values.bvn,
          constitution: values.constitution,
          industry: values.industry,
          incorporationDate: values.incorporationDate === null ? "" : values.incorporationDate.split("T")[0],
          contactPerson: values.contactPerson,
          designation: values.designation,
          lengthOfYearsInBusiness: values.lengthOfYearsInBusiness?.toString(),
          accountType: values.accountType,
          accountNumber: values.linkedAccountNo,
          ageOfAccount: values.repaymentAccountCreationDate ? getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString() : values.ageOfAccount.toString(),
          domiciliationOfBusinessProceeds: values.domiciliationOfBusinessProceeds,
          typeOfProductOffered: values.typeOfProductOffered,
          productDurability: values.productDurability,
          threatOfCompetition: values.threatOfCompetition,
          availabilityOfTradingStock: values.availabilityOfTradingStock,
          complexityOfProduct: values.complexityOfProduct,
          operationalStructure: values.operationalStructure,
          numberOfYearsOperatingInTheSameLocation: values.numberOfYearsOperatingInTheSameLocation?.toString(),
          foreignExchangeRisk: values.foreignExchangeRisk,
          membershipOfAnOrganizedBody: values.membershipOfAnOrganizedBody,
          monthsInCurrentJob: values.monthsInCurrentJob?.toString(),
          customerTin: values.tin,
          phonenumber: values.phonenumber,
          phonenumber2: values.phonenumber2,
          customerEmail: values.customerEmail,
          employeeCount: values.employeeCount
        }}
        FormComponent={({
          fields: {
            companyName,
            registrationNumber,
            constitution,
            industry,
            incorporationDate,
            contactPerson,
            designation,
            lengthOfYearsInBusiness,
            accountType,
            accountNumber,
            ageOfAccount,
            domiciliationOfBusinessProceeds,
            typeOfProductOffered,
            productDurability,
            threatOfCompetition,
            availabilityOfTradingStock,
            complexityOfProduct,
            operationalStructure,
            numberOfYearsOperatingInTheSameLocation,
            foreignExchangeRisk,
            membershipOfAnOrganizedBody,
            monthsInCurrentJob,
            customerTin,
            phonenumber,
            phonenumber2,
            customerEmail,
            employeeCount
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <Fragment>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-row">
                  <FormInputComponent
                    id="companyName"
                    name="companyName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={companyName}
                    required={this.getRequiredFields("companyName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="registrationNumber"
                    name="registrationNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={registrationNumber}
                    required={this.getRequiredFields("registrationNumber")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="constitution"
                    name="constitution"
                    items={constitutionInputData}
                    divClass={6}
                    value={constitution}
                    required={this.getRequiredFields("constitution")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="industry"
                    name="industry"
                    divClass={6}
                    value={industry}
                    items={industryInputData}
                    required={this.getRequiredFields("industry")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="incorporationDate"
                    name="incorporationDate"
                    type="date"
                    placeholder=""
                    divClass={6}
                    value={incorporationDate}
                    required={this.getRequiredFields("incorporationDate")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="contactPerson"
                    name="contactPerson"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={contactPerson}
                    required={this.getRequiredFields("contactPerson")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Contact Person Designation"
                    id="designation"
                    name="designation"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={designation}
                    required={this.getRequiredFields("contactPersonDesignation")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <div className="col-md-6">
                    <div className="form-row">
                      <FormInputComponent
                        label="Length of Years(s) in Business"
                        id="lengthOfYearsInBusiness"
                        name="lengthOfYearsInBusiness"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={lengthOfYearsInBusiness}
                        required={this.getRequiredFields("lengthOfYearsInBusiness")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                      <FormInputComponent
                        label="Length of Months(s) in Business"
                        id="monthsInCurrentJob"
                        name="monthsInCurrentJob"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={monthsInCurrentJob}
                        required={this.getRequiredFields("lengthOfMonthsInBusiness")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <SelectInputComponent
                    id="accountType"
                    name="accountType"
                    divClass={6}
                    value={accountType}
                    items={accountTypeInputData}
                    required={this.getRequiredFields("corporateAccountType")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={accountNumber}
                    required={this.getRequiredFields("corporateAccountNumber")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Age of Account (in months)"
                    id="ageOfAccount"
                    name="ageOfAccount"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={ageOfAccount}
                    required={this.getRequiredFields("corporateAgeOfAccount")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Customer Tin"
                    id="customerTin"
                    name="customerTin"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={customerTin}
                    required={this.getRequiredFields("corporateCustomerTin")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Phone Number 1"
                    id="phonenumber"
                    name="phonenumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={phonenumber}
                    required={this.getRequiredFields("phoneNumber1")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Phone Number 2"
                    id="phonenumber2"
                    name="phonenumber2"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={phonenumber2}
                    required={this.getRequiredFields("phoneNumber2")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Email Address"
                    id="customerEmail"
                    name="customerEmail"
                    type="email"
                    placeholder=""
                    divClass={6}
                    value={customerEmail}
                    required={this.getRequiredFields("emailAddress")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  {/* <FormInputComponent
                    label="Customer Tin"
                    id="customerTin"
                    name="customerTin"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={customerTin}
                    required={this.getRequiredFields("corporateCustomerTin")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  /> */}
                  <SelectInputComponent
                    label="Domiciliation of Business proceeds"
                    id="domiciliationOfBusinessProceeds"
                    name="domiciliationOfBusinessProceeds"
                    divClass={6}
                    value={domiciliationOfBusinessProceeds}
                    items={domiciliationOfBusinessProceedsInputData}
                    required={this.getRequiredFields("domiciliationOfBusinessProceeds")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Type of Product/Service offered"
                    id="typeOfProductOffered"
                    name="typeOfProductOffered"
                    divClass={6}
                    value={typeOfProductOffered}
                    items={typeOfProductOfferedInputData}
                    required={this.getRequiredFields("typeOfProduct")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="productDurability"
                    name="productDurability"
                    divClass={6}
                    value={productDurability}
                    items={productDurabilityInputData}
                    required={this.getRequiredFields("productDurability")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Threat of Substitute/Competition"
                    id="threatOfCompetition"
                    name="threatOfCompetition"
                    divClass={6}
                    value={threatOfCompetition}
                    items={threatOfCompetitionInputData}
                    required={this.getRequiredFields("threatOfSubstitute")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Availability of Input material/Trading stock"
                    id="availabilityOfTradingStock"
                    name="availabilityOfTradingStock"
                    divClass={6}
                    value={availabilityOfTradingStock}
                    items={availabilityOfTradingStockInputData}
                    required={this.getRequiredFields("availabilityOfInputMaterial")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Complexity of Product/Service"
                    id="complexityOfProduct"
                    name="complexityOfProduct"
                    divClass={6}
                    value={complexityOfProduct}
                    items={complexityOfProductInputData}
                    required={this.getRequiredFields("complexityOfProduct")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="operationalStructure"
                    name="operationalStructure"
                    divClass={6}
                    value={operationalStructure}
                    items={operationalStructureInputData}
                    required={this.getRequiredFields("operationalStructure")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  {/* <SelectInputComponent
                  <FormInputComponent
                    label="Number of years operating in the same location"
                    id="numberOfYearsOperatingInTheSameLocation"
                    name="numberOfYearsOperatingInTheSameLocation"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={numberOfYearsOperatingInTheSameLocation}
                    required={this.getRequiredFields("numberOfYearsOperatingInTheSameLocation")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  /> */}
                  <FormInputComponent
                    label="Number of years operating in the same location"
                    id="numberOfYearsOperatingInTheSameLocation"
                    name="numberOfYearsOperatingInTheSameLocation"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={numberOfYearsOperatingInTheSameLocation}
                    required={true}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />

                  <FormInputComponent
                    label="Number of Employees"
                    id="employeeCount"
                    name="employeeCount"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={employeeCount}
                    required={this.getRequiredFields("employeeCount")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="foreignExchangeRisk"
                    name="foreignExchangeRisk"
                    divClass={6}
                    value={foreignExchangeRisk}
                    items={foreignExchangeRiskInputData}
                    required={this.getRequiredFields("foreignExchangeRisk")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Membership of an Organized Body"
                    id="membershipOfAnOrganizedBody"
                    name="membershipOfAnOrganizedBody"
                    divClass={6}
                    value={membershipOfAnOrganizedBody}
                    items={membershipOfAnOrganizedBodyInputData}
                    required={this.getRequiredFields("membershipOfAnOrganizedBody")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Customer Validation</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <DetailItem
                      label="BVN"
                      labelSize={7}
                      valueSize={5}
                      value={values.bvn}
                    />
                    <DetailItem
                      label="Customer Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.customerName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="float-right">
              {(getCurrentUsersRoles().includes("updatedataentry") && (
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={this.state.submitting}
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Corporate Details",
                      () => {
                        this.onFormSubmit(
                          {
                            companyName,
                            registrationNumber,
                            constitution,
                            industry,
                            incorporationDate,
                            contactPerson,
                            designation,
                            lengthOfYearsInBusiness,
                            accountType,
                            accountNumber,
                            ageOfAccount,
                            domiciliationOfBusinessProceeds,
                            typeOfProductOffered,
                            productDurability,
                            threatOfCompetition,
                            availabilityOfTradingStock,
                            complexityOfProduct,
                            operationalStructure,
                            numberOfYearsOperatingInTheSameLocation,
                            foreignExchangeRisk,
                            membershipOfAnOrganizedBody,
                            monthsInCurrentJob,
                            customerTin,
                            phonenumber,
                            phonenumber2,
                            customerEmail,
                            employeeCount
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                >
                  {this.state.submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                      Please wait...
                    </React.Fragment>
                  )}
                </button>
              ))}
            </div>
          </Fragment>
        )}
      ></CorporateDetailsForm>
    );
  }
}

export default CorporateDetails;