import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import appraisalLoanProductService from "../../../services/hybridAppraisal/loanProductService";
import DetailItemSelectComponent, { ISelectItems } from "../../../components/detailItemSelectBox";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import creditPolicyParameterService from "../../../services/hybridAppraisal/creditPolicyParameterService";
import { Accordion, AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import { CreditPolicyValueType } from "../../../enums/creditPolicyValueType";
import { CreditPolicyUseCase } from "../../../enums/creditPolicyUseCase";
import ModifyCreditPolicyParameters from "../creditPolicy/modifyCreditPolicyParameters";
import textFormatService, { generateGUID } from "../../../services/utility/textFormatService";
import { docData } from "./productDocumentsData";
import FormInputComponent from "../../../components/formInputComponent";
import SelectInputComponent from "../../../components/formSelectComponent";

export function ContextAwareToggle({ isHeader, eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <>
      {isHeader ? (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
          <b>{header}</b>
          <i
            className={angleType}
            aria-hidden="true"
            style={{ float: "right" }}
          ></i>
        </Card.Header>
      ) : (
        <div onClick={toggleOnClick} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>{header}</b>
          </div>
          <div style={{ float: "right", paddingRight: "3px", paddingTop: "1px" }}>
            <i
              className={angleType}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </>
  );
}
interface EditProductConfigurationFields {
  productName: string;
  productCode: string;
  productCategory: string;
  minimumAmount: string;
  maximumAmount: string;
  interestRate: string;
  tenure: string;
  moratorium: string;
  numberOfGuarantors?: string;
  scoreCardId: string;
  feeName: string;
  feeType: string;
  processingFee: string;
  minimum_amount: string;
  maximum_amount: string;
  minimum_tenure: string;
  maximum_tenure: string;
  interest: string;
}

// interface ILoanPrices {
//   name: string;
//   feeType: string;
//   value: string;
// }

interface ILoanPrices {
  name: string;
  minimumAmount: string;
  maximumAmount: string;
  minimumTenure: string;
  maximumTenure: string;
  interest: number
  feeType: string;
  processingFee: number;
}


class EditProductConfigurationForm extends BaseFormComponent<EditProductConfigurationFields> { }

interface IEditProductConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditProductConfigurationState {
  ownUpdate: boolean;
  scoreCards: any;
  hasGuarantor: boolean;
  fetchingRequiredItems: boolean;
  documents: any;
  documentsPost: any;
  documentsData: ISelectItems[];
  creditPolicyParameters: any;
  selectedCreditPolicies: any;
  showSetCreditPolicyModal: boolean;
  parameterItem: any;
  prices: ILoanPrices[];
}

class EditProductConfiguration extends React.Component<
  IEditProductConfigurationProps,
  IBaseFormState & IEditProductConfigurationState
> {
  _isMounted = false;
  constructor(props: IEditProductConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      scoreCards: [],
      documents: [],
      documentsPost: [],
      documentsData: [],
      hasGuarantor: false,
      fetchingRequiredItems: false,
      creditPolicyParameters: [],
      selectedCreditPolicies: [],
      showSetCreditPolicyModal: false,
      parameterItem: {},
      prices: []
    };
  }

  static getDerivedStateFromProps(nextProps: IEditProductConfigurationProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      prices: nextProps.item.productPricing ? nextProps.item.productPricing : [],
      hasGuarantor: nextProps.item.hasGuarantor,
      documents: nextProps.item.productDocuments ? nextProps.item.productDocuments.map(item => {
        return { name: item.name, category: item.category }
      }) : [],
      selectedCreditPolicies: nextProps.item.creditPolicyCriteria ? nextProps.item.creditPolicyCriteria : []
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true, ownUpdate: true });
      }
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const response = await Promise.allSettled([
        scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"]),
        appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        docData
      ])
      const scoreCards = response[0].status == "fulfilled" ? response[0].value.data : [];
      const creditPolicyParameters = response[1].status == "fulfilled" ? response[1].value.data.creditPolicyParameters : [];
      const documentsDataResp = response[2].status == "fulfilled" ? response[2].value.data : [];
      if (this._isMounted) {
        this.setState({
          scoreCards,
          creditPolicyParameters,
          fetchingRequiredItems: false,
          documentsData: documentsDataResp.map(item => {
            return { name: item.split("=")[0], value: item.split("=")[1] }
          }),
          ownUpdate: true
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, ownUpdate: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditProductConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            appraisalValidationService.validateEditProductConfigurationForm(fields),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
          // console.log(this.state.errors)
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: EditProductConfigurationFields, onReloadFieldsCB: any) {
    // console.log("Submitting")
    // if (this.state.hasGuarantor && Number(fields.numberOfGuarantors) <= 0) {
    //   toast.info(`Invalid Number of Guarantors`, {
    //     autoClose: 6000,
    //     type: toast.TYPE.ERROR,
    //     hideProgressBar: false,
    //   });
    //   return false;
    // }
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const document = this.state.documents;
      const documentPost = this.state.documentsPost
      const combinedDocuments = document.concat(documentPost);
      const item = this.props.item;
      const payload: any = {};
      payload.id = item.id;
      payload.institutionCode = item.institutionCode;
      payload.name = fields.productName;
      payload.productCode = fields.productCode;
      payload.productCategory = fields.productCategory;
      payload.tenure = Number(fields.tenure);
      payload.moratorium = Number(fields.moratorium);
      payload.minimumAmount = Number(fields.minimumAmount);
      payload.maximumAmount = Number(fields.maximumAmount);
      payload.interestRate = Number(fields.interestRate);
      payload.scoreCardId = fields.scoreCardId;
      payload.hasGuarantor = this.state.hasGuarantor;
      payload.numberOfGuarantors = this.state.hasGuarantor ? Number(fields.numberOfGuarantors) : 0;
      payload.ProductDocuments = combinedDocuments;
      payload.creditPolicyCriteria = this.state.selectedCreditPolicies;
      payload.productPricing = this.state.prices;

      // console.log("Payload: ", payload);
      const response = await appraisalLoanProductService.updateLoanProductConfig(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Loan Product",
          description: `Edited details for Loan Product ${fields.productName}`,
        });
        toast.info(`Loan Product ${fields.productName} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  toggleRequireGuarantor = () => {
    this.setState({
      hasGuarantor: !this.state.hasGuarantor,
      ownUpdate: true
    });
  };

  handleOnAddPricing = (item: any) => {

    const prices = [...this.state.prices];
    if (this.validateAmountRanges(item, prices) && this.validateTenureRanges(item, prices)) {
      prices.push({
        name: item.feeName,
        feeType: item.feeType,
        maximumAmount: item.maximum_amount,
        minimumAmount: item.minimum_amount,
        maximumTenure: item.maximum_tenure,
        minimumTenure: item.minimum_tenure,
        processingFee: item.processingFee,
        interest: item.interest
      }
      );
      // console.log(prices)
      this.setState({ prices, ownUpdate: true });
    }
    else {
      toast.info(`Amount / Tenure Range Overlap, Please Check Entries`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
    }
    // onReloadFieldsCB();

    // this.setState({ validated: true });
  };

  amountRangesOverlap = (a, b) => {
    return (Number(b.minimum_amount) >= Number(a.minimumAmount) && Number(b.minimum_amount) <= Number(a.maximumAmount)) || (Number(b.maximum_amount) >= Number(a.minimumAmount) && Number(b.maximum_amount) <= Number(a.maximumAmount));
    ;
  };

  validateAmountRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    var newPrices: any = []
    // console.log(this.validateTenureRanges(item, prices))
    // if (!this.validateTenureRanges(item, prices)) {
    for (let price of prices) {
      // var newPrice: any = {}
      // console.log(price)
      // console.log(item)
      // console.log((item.minScore >= price.minScore && item.minScore <= price.maxScore) || (item.maxScore >= price.minScore && item.maxScore <= price.maxScore))
      if (this.amountRangesOverlap(price, item)) {
        newPrices.push(price)
        if (!(price.minimumAmount === item.minimum_amount && price.maximumAmount === item.maximum_amount)) {
          return false;
        }
      }
    }
    for (let newPrice of newPrices) {
      if (this.tenureRangesOverlap(item, newPrice)) {
        return false; // No overlapping ranges
      }

    }
    return true;
  };

  tenureRangesOverlap = (a, b) => {
    return (Number(b.minimumTenure) >= Number(a.minimum_tenure) && Number(b.minimumTenure) <= Number(a.maximum_tenure)) || (Number(b.maximumTenure) >= Number(a.minimum_tenure) && Number(b.maximumTenure) <= Number(a.maximum_tenure));
    ;
  };

  validateTenureRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    // console.log(prices)
    // console.log(item)
    prices = prices.filter(x => x.minimumAmount === item.minimum_amount && x.maximumAmount === item.maximum_amount)
    // console.log(prices)
    for (let price of prices) {
      // console.log(price)
      // console.log(item)
      // console.log((item.minScore >= price.minScore && item.minScore <= price.maxScore) || (item.maxScore >= price.minScore && item.maxScore <= price.maxScore))
      if (this.tenureRangesOverlap(price, item)) {
        return false; // New range overlaps with existing, don't add it
      }
    }
    return true; // No overlapping ranges
  };

  handleOnRemovePricing = (item: ILoanPrices) => {
    const prices = [...this.state.prices];
    if (prices.includes(item)) {
      prices.splice(
        prices.indexOf(item), 1
      );
    }
    this.setState({ prices, ownUpdate: true });
  };

  handleOnChangeDocuments = async (item) => {
    // console.log(item)
    const docs = [...this.state.documents];
    if (docs.findIndex(object => { return object.name === item.name }) !== -1) {
      docs.splice(docs.findIndex(object => { return object.name === item.name }), 1)
      this.setState({ documents: docs, ownUpdate: true });
    } else {
      const documents = [...this.state.documents];
      const documentsData = [...this.state.documentsData];
      // prepare payload 
      let selectedDoc = documentsData.filter(data => data.name == item.name)[0];
      let payload = {
        name: selectedDoc.name,
        category: selectedDoc.value,
        type: "pre"
      }
      documents.push(payload);
      this.setState({ documents, ownUpdate: true });
    }
  };

  handleOnChangePostDocuments = async (item) => {
    // console.log(item)
    const docs = [...this.state.documentsPost];
    if (docs.findIndex(object => { return object.name === item.name }) !== -1) {
      docs.splice(docs.findIndex(object => { return object.name === item.name }), 1)
      this.setState({ documentsPost: docs, ownUpdate: true });
    } else {
      const documentsPost = [...this.state.documentsPost];
      const documentsData = [...this.state.documentsData];
      // prepare payload 
      let selectedDoc = documentsData.filter(data => data.name == item.name)[0];
      let payload = {
        name: selectedDoc.name,
        category: selectedDoc.value,
        type: "post"
      }
      documentsPost.push(payload);
      this.setState({ documentsPost, ownUpdate: true });
    }
  };

  handleOnChangeCreditPolicy = async (item) => {
    let parameters;
    console.log(item);
    const creditPolicy = [...this.state.selectedCreditPolicies];
    if (creditPolicy.findIndex(object => { return object.creditPolicyParameterId === item.id }) !== -1) {
      creditPolicy.splice(creditPolicy.findIndex(object => { return object.creditPolicyParameterId === item.id }), 1)
      this.setState({ selectedCreditPolicies: creditPolicy, ownUpdate: true });
    } else {
      parameters = (await creditPolicyParameterService.retrieveById(item.id)).data;
      this.setState({ parameterItem: parameters, showSetCreditPolicyModal: true, ownUpdate: true });
    }
  };

  handleOnIndicatorParamsSave = (toSave: boolean, payload) => {
    if (toSave) {
      const selectedCreditPolicies = [...this.state.selectedCreditPolicies];
      const creditPolicyParameters = [...this.state.creditPolicyParameters];
      selectedCreditPolicies.push(payload);
      const creditPolicy = creditPolicyParameters.filter(item => item.id === payload.creditPolicyParameterId)[0];
      creditPolicy.parameter = payload
      this.setState({ selectedCreditPolicies, creditPolicyParameters, showSetCreditPolicyModal: false, ownUpdate: true });
    } else {
      this.setState({ showSetCreditPolicyModal: false, ownUpdate: true });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      scoreCards,
      hasGuarantor,
      creditPolicyParameters,
      parameterItem,
      selectedCreditPolicies,
      documentsData,
      documents,
      prices
    } = this.state;

    let productCategoryInputData: ISelectItems[] = [
      { name: "Individual", value: "Individual" },
      { name: "Corporate", value: "Corporate" },
      { name: "Group", value: "Group" }
    ];

    let feeTypeInputData: ISelectItems[] = [
      { name: "Value", value: "Value" },
      { name: "Percentage", value: "Percentage" }
    ];

    let scoreCardInputData: ISelectItems[] = scoreCards.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Product Configuration -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditProductConfigurationForm
          initialValues={{
            productName: item.name,
            productCode: item.productCode,
            productCategory: item.productCategory,
            minimumAmount: item.minimumAmount?.toString(),
            maximumAmount: item.maximumAmount?.toString(),
            interestRate: item.interestRate?.toString(),
            tenure: item.tenure?.toString(),
            moratorium: item.moratorium,
            numberOfGuarantors: item.numberOfGuarantors,
            scoreCardId: item.scoreCardId,
            feeName: "",
            feeType: "",
            processingFee: "",
            minimum_amount: "",
            maximum_amount: "",
            minimum_tenure: "",
            maximum_tenure: "",
            interest: "",
          }}
          FormComponent={({
            fields: {
              productName,
              productCode,
              productCategory,
              maximumAmount,
              minimumAmount,
              interestRate,
              tenure,
              moratorium,
              numberOfGuarantors,
              scoreCardId,
              feeName,
              feeType,
              processingFee,
              minimum_amount,
              maximum_amount,
              minimum_tenure,
              maximum_tenure,
              interest,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Product Name"
                              value={productName}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Tenure In Days"
                              value={tenure}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Minimum Amount"
                              value={minimumAmount}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Interest Rate"
                              value={interestRate}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Score Card"
                              value={
                                <DetailItemSelectComponent
                                  id="scoreCardId"
                                  name="scoreCard"
                                  items={scoreCardInputData}
                                  value={scoreCardId}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            {/* {hasGuarantor ? (
                              <DetailItem
                                label="Number of Guarantors"
                                value={
                                  <DetailItemInputComponent
                                    id="numberOfGuarantors"
                                    type="number"
                                    name="numberOfGuarantors"
                                    placeholder=""
                                    value={numberOfGuarantors}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            ) : (
                              ""
                            )} */}
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Product Code"
                              value={productCode}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Moratorium"
                              value={moratorium}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Maximum Amount"
                              value={maximumAmount}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Product Category"
                              value={
                                <DetailItemSelectComponent
                                  id="productCategory"
                                  name="productCategory"
                                  items={productCategoryInputData}
                                  value={productCategory}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            {/* <DetailItem
                              label="Require Guarantor"
                              value={
                                <span className="material-switch ml-2 mt-2">
                                  <input
                                    id="hasGuarantor"
                                    name="hasGuarantor"
                                    type="checkbox"
                                    onChange={this.toggleRequireGuarantor}
                                    checked={hasGuarantor}
                                  />
                                  <label
                                    htmlFor="hasGuarantor"
                                    className="bg-primary"
                                  ></label>
                                </span>
                              }
                              labelSize={5}
                              valueSize={7}
                            /> */}
                          </dl>
                        )}
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-header clear-fix">
                        <h6
                          className="float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <b>Product Pricing</b>
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="form-row">
                          <FormInputComponent
                            label="Loan Pricing Name"
                            id="feeName"
                            name="feeName"
                            type="text"
                            placeholder=""
                            divClass={6}
                            value={feeName}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Minimum Amount"
                            id="minimum_amount"
                            name="minimum_amount"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={minimum_amount}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Maximum Amount"
                            id="maximum_amount"
                            name="maximum_amount"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={maximum_amount}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Minimum Tenure"
                            id="minimum_tenure"
                            name="minimum_tenure"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={minimum_tenure}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Maximum Tenure"
                            id="maximum_tenure"
                            name="maximum_tenure"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={maximum_tenure}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <SelectInputComponent
                            id="feeType"
                            name="feeType"
                            divClass={6}
                            value={feeType}
                            items={feeTypeInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Processing Fee"
                            id="processingFee"
                            name="processingFee"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={processingFee}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Interest"
                            id="interest"
                            name="interest"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={interest}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />

                          {/* Add Pricing */}
                          <div className="form-row">
                            <div className="my-1 mx-2">
                              <button
                                className="btn btn-sm btn-outline-primary my-4"
                                style={{ cursor: "pointer" }}
                                type="button"
                                onClick={() =>
                                  this.handleOnAddPricing(
                                    {
                                      feeName,
                                      feeType,
                                      processingFee,
                                      minimum_amount,
                                      maximum_amount,
                                      minimum_tenure,
                                      maximum_tenure,
                                      interest,
                                    }
                                  )
                                }
                              >
                                <React.Fragment>
                                  <i className="far fa-save fa-lg mr-2" />{" "}
                                  Add
                                </React.Fragment>
                              </button>
                            </div>
                          </div>
                          <div className=" form-row col-md-12">
                            <div
                              className="table-responsive text-nowrap"
                              style={{
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Fee Name</strong>
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Minimum Amount</strong>
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Maximum Amount</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Minimum Tenure</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Maximum Tenure</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Fee Type</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Processing Fee</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Interest</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {prices.map((item) => (
                                    <tr key={prices.indexOf(item)}>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {prices.indexOf(item) + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {textFormatService.formatCurrency(Number(item.minimumAmount), 2)}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {textFormatService.formatCurrency(Number(item.maximumAmount), 2)}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.minimumTenure}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.maximumTenure}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.feeType}
                                      </td>

                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.feeType === "Percentage" ? item.processingFee : textFormatService.formatCurrency(Number(item.processingFee), 2)}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.interest}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        <i
                                          className="far fa-trash-alt ml-5 red-text fa-lg"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.handleOnRemovePricing(
                                              item
                                            )
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          htmlFor="documents"
                          className="control-label textbox-label pl-2"
                        >
                          Pre-Approval Documents
                        </label>
                        <div
                          className="block-example border"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflowX: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {documentsData.length > 0 ? (
                            <React.Fragment>
                              {documentsData.map(
                                (item) => (
                                  <div key={generateGUID()}
                                    style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                    <div
                                      className="custom-control custom-checkbox"
                                      style={{ display: 'inline-block' }}
                                      key={documentsData.indexOf(item)}
                                    >
                                      <input
                                        id={item.name}
                                        name={item.name}
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() =>
                                          this.handleOnChangeDocuments(
                                            item
                                          )
                                        }
                                        checked={
                                          _.isEmpty(
                                            documents.filter(
                                              (x) => x.name === item.name
                                            )
                                          )
                                            ? false
                                            : true
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item.name}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            "No Document Collection has been assigned to institution"
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="documents"
                          className="control-label textbox-label pl-2"
                        >
                          Post-Approval Documents
                        </label>
                        <div
                          className="block-example border"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflowX: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {documentsData.length > 0 ? (
                            <React.Fragment>
                              {documentsData.map(
                                (item) => (
                                  <div key={generateGUID()}
                                    style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                    <div
                                      className="custom-control custom-checkbox"
                                      style={{ display: 'inline-block' }}
                                      key={documentsData.indexOf(item)}
                                    >
                                      <input
                                        id={item.name + "Pre"}
                                        name={item.name + "Pre"}
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() =>
                                          this.handleOnChangePostDocuments(
                                            item
                                          )
                                        }
                                        checked={
                                          _.isEmpty(
                                            this.state.documentsPost.filter(
                                              (x) => x.name === item.name
                                            )
                                          )
                                            ? false
                                            : true
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item.name + "Pre"}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            "No Document Collection has been assigned to institution"
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="indicatorIds"
                          className="control-label textbox-label pl-2"
                        >
                          Credit Policy Criteria
                        </label>
                        <div
                          className="block-example border"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflowX: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {creditPolicyParameters.length > 0 ? (
                            <React.Fragment>
                              {creditPolicyParameters.map(
                                (item) => (
                                  <Accordion key={creditPolicyParameters.indexOf(item)}>
                                    <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                      <ContextAwareToggle
                                        eventKey={`0`}
                                        isHeader={false}
                                        header={
                                          <div
                                            className="custom-control custom-checkbox"
                                            style={{ display: 'inline-block' }}
                                            key={creditPolicyParameters.indexOf(item)}
                                          >
                                            <input
                                              id={item.id}
                                              name={item.id}
                                              className="custom-control-input"
                                              type="checkbox"
                                              onChange={() =>
                                                this.handleOnChangeCreditPolicy(
                                                  item
                                                )
                                              }
                                              checked={
                                                _.isEmpty(
                                                  selectedCreditPolicies.filter(
                                                    (x) => x.creditPolicyParameterId === item.id
                                                  )
                                                )
                                                  ? false
                                                  : true
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={item.id}
                                            >
                                              {item.name}
                                            </label>
                                          </div>
                                        }
                                      ></ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={`0`}>
                                      <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                          <div className="row">
                                            <div className="col-md-1">
                                            </div>
                                            <div className="col-md-8">
                                              {!_.isEmpty(item.parameter) ? (
                                                <dl className="row" style={{ marginBottom: "0px" }} key={item.parameter.creditPolicyParameterId}>
                                                  {(() => {
                                                    switch (item.creditPolicyValueType) {
                                                      case CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]:
                                                        return (
                                                          <DetailItem
                                                            label={item.name}
                                                            value={item.parameter.maxSingleInputValue}
                                                            labelSize={5}
                                                            valueSize={7}
                                                          />
                                                        )
                                                      case CreditPolicyValueType[CreditPolicyValueType.MinSingleInput]:
                                                        return (
                                                          <DetailItem
                                                            label={item.name}
                                                            value={item.parameter.mixSingleInputValue}
                                                            labelSize={5}
                                                            valueSize={7}
                                                          />
                                                        )
                                                      case CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]:
                                                        return (
                                                          <div className="form-row">
                                                            {item.parameter.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                              (item.parameter.dropDownOptions).map((option) => (
                                                                <DetailItem
                                                                  key={option.identifier}
                                                                  label={option.name}
                                                                  value={option.score}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              ))
                                                            ) : (
                                                              (item.parameter.dropDownOptions).map((option) => (
                                                                <DetailItem
                                                                  key={option.identifier}
                                                                  label={option.name}
                                                                  value={(option.passCriteria).toString()}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              ))
                                                            )}
                                                          </div>
                                                        )
                                                      // case CreditPolicyValueType[CreditPolicyValueType.Range]:
                                                      //   return (
                                                      //     <div className="form-row">
                                                      //       {item.parameter.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                      //         (item.parameter.rangeOptions).map((option) => (
                                                      //           <DetailItem
                                                      //             key={option.minimum + " - " + option.maximum}
                                                      //             label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier}
                                                      //             value={option.score}
                                                      //             labelSize={5}
                                                      //             valueSize={7}
                                                      //           />
                                                      //         ))
                                                      //       ) : (
                                                      //         (item.parameter.rangeOptions).map((option) => (
                                                      //           <DetailItem
                                                      //             key={option.minimum + " - " + option.maximum}
                                                      //             label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier} value={(option.passCriteria).toString()}
                                                      //             labelSize={5}
                                                      //             valueSize={7}
                                                      //           />
                                                      //         ))
                                                      //       )}
                                                      //     </div>
                                                      //   )
                                                      default:
                                                        return null
                                                    }
                                                  })()}
                                                </dl>
                                              ) : ("")}
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Accordion>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            "No Credit Policy Parameter has been assigned to institution"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ModifyCreditPolicyParameters
                  showPatchModal={this.state.showSetCreditPolicyModal}
                  togglePatchModal={(toSave: boolean, payload: any) => {
                    this.handleOnIndicatorParamsSave(toSave, payload);
                  }}
                  item={parameterItem}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Product Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            productName,
                            productCode,
                            productCategory,
                            maximumAmount,
                            minimumAmount,
                            interestRate,
                            tenure,
                            moratorium,
                            numberOfGuarantors,
                            scoreCardId,
                            feeName,
                            feeType,
                            processingFee,
                            minimum_amount,
                            maximum_amount,
                            minimum_tenure,
                            maximum_tenure,
                            interest,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditProductConfigurationForm>
      </Modal>
    );
  }
}

export default EditProductConfiguration;
