import React, { useState } from "react";
import FAMNavigation from "./navigation";
import CollateralValuation from "./collateralValuation";
import FamSummaryInitiation from "./summaryInitiation";
import FinancialStatement from "./FinancialStatement";

export default function FAMComponent({ loan }: any) {
  const [navIndex, setNavIndex] = useState(0);
  let n = navIndex;

  const components = [
    <FinancialStatement loan={loan} />,
    <CollateralValuation loan={loan} />,
    <FamSummaryInitiation loan={loan} />,
  ];

  return (
    <div>
      <FAMNavigation
        index={navIndex}
        setIndex={setNavIndex}
        customOptions={[
          "Financial Statement",
          "Collateral Valuation",
          "FAM Summary",
        ]}
      />
      {components.map((component, index) => (
        <div
          key={index}
          style={{
            visibility: n === index ? "visible" : "hidden",
            height: n === index ? "auto" : "0px",
          }}
        >
          {component}
        </div>
      ))}
    </div>
  );
}
